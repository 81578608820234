import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import {
    TextCustom,
} from '../../Component/InputComponents'

import { FETCH_MAINTENANCE_DETAILS, CREATE_MAINTENANCE_INFO } from './api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddMaintenance({ updateValues }) {
    const { email, token } = useContext(EmailContext)

    const formClass = formStyle()

    const [outcome, setOutcome] = useState("")
    const [errors, setErrors] = useState("")


    const initialState = {
        vid: updateValues?.data?.vid,
        brand: updateValues?.data?. manufacturer,
        model: updateValues?.data?.model_name,
        variant: updateValues?.data?.model_variant,
        regoAndInsuranceMonthly: "",
        fuelMonthly: "",
        servicingMonthly: "",
        tyresMonthly: "",
        forKm: "",
    }

    const [values, setValues] = useState(initialState)

    console.log(values)

    /* <<<=========     useMutation   ===========>>>  */

    const [createMaintenanceInfo] = useMutation(CREATE_MAINTENANCE_INFO, {
        update(_, result) {
            setOutcome(result?.data?.inputNewMaintenance?.status)
        },
        onError(err) {
            setErrors(err)
        },
        refetchQueries: [
            {
                query: FETCH_MAINTENANCE_DETAILS,
                variables: { qId: values.vid },
            },
        ],
    })

 

    /* <<<=========      ONCHANGE FUNC   ===========>>>  */


  async function validator(){
    const parsedValuesAre = {

      vid: values.vid,
      userEmail: email,
      token : token,
      regoAndInsuranceMonthly : isNaN(parseFloat(values.regoAndInsuranceMonthly)) ? 0 : parseFloat(values.regoAndInsuranceMonthly),
      fuelMonthly : isNaN(parseFloat(values.fuelMonthly)) ? 0 : parseFloat(values.fuelMonthly),
      servicingMonthly : isNaN(parseFloat(values.servicingMonthly)) ? 0 : parseFloat(values.servicingMonthly),
      tyresMonthly : isNaN(parseFloat(values.tyresMonthly)) ? 0 : parseFloat(values.tyresMonthly),
      forKm: isNaN(parseFloat(values.forKm)) ? 0 : parseFloat(values.forKm),
      brand : values.brand,
      model : values.model,
      variant : values.variant,

    }

    return parsedValuesAre;

  }

  async function onSubmit() {
    setOutcome({})
    setErrors({})
    const parsedValuesAre = await validator()
    createMaintenanceInfo({ variables: parsedValuesAre })
}

    const onChange = (event) => {

        setValues({ ...values, [event.target.name]: event.target.value })

    }


    /* <<<=========      RESET   ===========>>>  */

    function resetFunc() {
        setOutcome('')
        setErrors('')
        setValues(initialState)
    }

    /* <<<=========    CONTENT   ===========>>>  */

    return (
        <div className={formClass.formDiv}>
            <div>
                <div className={formClass.formRow}>
                    {" "}
                   
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Vid</Typography>
                    <Typography className={formClass.leftText}>{values.vid}</Typography>
                  
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Brand</Typography>
                    <Typography className={formClass.leftText}>{values.brand}</Typography>
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Model</Typography>
                    <Typography className={formClass.leftText}>{values.model}</Typography>
                    
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Variant</Typography>
                    <Typography className={formClass.leftText}>{values.variant}</Typography>
                   
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                        Rego and Insurance Monthy
                    </Typography>
                    <TextCustom
                        id="en"
                        type="number"
                        placeholder="Enter Rego and Insurance Monthy"
                        name="regoAndInsuranceMonthly"
                        value={values.regoAndInsuranceMonthly}
                        onChange={onChange}
                    />
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>fuelMonthly</Typography>
                    <TextCustom
                        id="en"
                        type="number"
                        placeholder="Enter fuel Monthly"
                        name="fuelMonthly"
                        value={values.fuelMonthly}
                        onChange={onChange}
                    />
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                        Servicing Monthly
                    </Typography>
                    <TextCustom
                        id="en"
                        type="number"
                        placeholder="Enter Servicing Monthly"
                        name="servicingMonthly"
                        value={values.servicingMonthly}
                        onChange={onChange}
                    />
                </div>
                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                        Tyres Monthly
                    </Typography>
                    <TextCustom
                        id="en"
                        type="number"
                        placeholder="Enter Tyres Monthly"
                        name="tyresMonthly"
                        value={values.tyresMonthly}
                        onChange={onChange}
                    />
                </div>

                <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                        For Kilometers
                    </Typography>
                    <TextCustom
                        id="en"
                        type="number"
                        placeholder="Enter km details"
                        name="forKm"
                        value={values.forKm}
                        onChange={onChange}
                    />
                </div>
            


                {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                )}
                {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                        title={'Something Went Wrong Cannot Be Created'}
                        fColor={Colors.black}
                    />
                )}
                <div className={formClass.formBtnDiv}>
                    <ResetButton onClick={resetFunc} />
                    <CreateButton onClick={onSubmit} />
                </div>
            </div>
        </div>
    )
}

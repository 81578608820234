import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Divider, Typography } from "@material-ui/core";

import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  ModalCopyButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import {
  FETCH_PRICING_DETAILS,
  CREATE_PRICING_INFO,
  UPDATE_PRICING_INFO,
} from "./api";
import { FETCH_ALL_VARIANTS_DATA } from "../Variants/api";

import { EmailContext } from "../../Context/EmailContext";

export default function PricingDetails({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const lid = localStorage.getItem("vidis");
  const [updateStatus, setUpdateStatus] = useState(false);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token : token,
    basePrice: "",
    destCharges: "",
    taxCredit: "",
    effectPrice: "",
    deliveryFees: "",
  });

  const [callThePricingApi, { loading, data, error }] = useLazyQuery(
    FETCH_PRICING_DETAILS
  );

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callThePricingApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.getPricingDetails) {
      const { getPricingDetails } = data;
      setValues({
        ...values,
        basePrice: getPricingDetails?.base_price,
        destCharges: getPricingDetails?.dest_charge,
        taxCredit: getPricingDetails?.tax_credit,
        effectPrice: getPricingDetails?.effect_price,
        deliveryFees: getPricingDetails?.delivery_fee,
      });

      if (lid === getPricingDetails?.vid) {
        setUpdateStatus(false);
      }
      if (lid !== getPricingDetails?.vid) {
        setUpdateStatus(true);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const x1 = isNaN(parseFloat(values.basePrice))
      ? 0
      : parseFloat(values.basePrice);
    const x2 = isNaN(parseFloat(values.destCharges))
      ? 0
      : parseFloat(values.destCharges);
    const x3 = isNaN(parseFloat(values.taxCredit))
      ? 0
      : parseFloat(values.taxCredit);

    const computedValue = x1 + x2 + x3;

    setValues({ ...values, ["effectPrice"]: computedValue }); // eslint-disable-line no-useless-computed-key
  }, [values.basePrice, values.destCharges, values.taxCredit]); // eslint-disable-line react-hooks/exhaustive-deps

  /* <<<=========    useMutation   ===========>>>  */

  const [createPriceInfo] = useMutation(CREATE_PRICING_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputPriceForVehicle?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_PRICING_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [updatePriceInfo] = useMutation(UPDATE_PRICING_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updatePriceDetails);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_PRICING_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  async function validator() {
    const parsedValuesAre = {
      vid: updateValues ? updateValues?.vid : "",
      userEmail: email,
      token : token,
      basePrice: isNaN(parseFloat(values.basePrice))
        ? 0
        : parseFloat(values.basePrice),
      destCharges: isNaN(parseFloat(values.destCharges))
        ? 0
        : parseFloat(values.destCharges),
      taxCredit: isNaN(parseFloat(values.taxCredit))
        ? 0
        : parseFloat(values.taxCredit),
      effectPrice: isNaN(parseFloat(values.effectPrice))
        ? 0
        : parseFloat(values.effectPrice),
      deliveryFees: isNaN(parseFloat(values.deliveryFees))
        ? 0
        : parseFloat(values.deliveryFees),
    };
    return parsedValuesAre;
  }
  async function callCreateAPI() {
    setOutcome({});
    setErrors({});
    const parsedValuesAre = await validator();

    await createPriceInfo({
      variables: parsedValuesAre,
    });
  }

  async function callEditAPI() {
    setOutcome({});
    setErrors({});
    // updateData();

    const validatedValues = await validator();

    await updatePriceInfo({
      variables: validatedValues,
    });
  }

  function callTheMainApi() {
    callThePricingApi({ variables: { qId: lid } });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>loading ...</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={mStyles.floatRight}>
                    <ModalCopyButton onClick={() => callTheMainApi()} />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Base Price
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Base Price"
                      name="basePrice"
                      value={values.basePrice}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Dest Charge
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Dest Charge"
                      name="destCharges"
                      value={values.destCharges}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Tax</Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Tax"
                      name="taxCredit"
                      value={values.taxCredit}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Effective Price
                    </Typography>
                    <TextCustom
                      id="en"
                      placeholder="Enter Effective Price"
                      name="effectPrice"
                      value={values.effectPrice}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Delivery Fees
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Effective Price"
                      name="deliveryFees"
                      value={values.deliveryFees}
                      onChange={onChange}
                    />
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />
                  {lid === values.vid ? (
                    <>It Can Be Edited Only On The Main Table</>
                  ) : (
                    <>
                      {updateStatus ? (
                        <CommonEditButton
                          title={"Update"}
                          onClick={() => callEditAPI()}
                        />
                      ) : (
                        <CommonAddButton
                          title={"Create"}
                          onClick={() => callCreateAPI()}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vid</p>
                    <p className={mStyles.textRight}>{values.vid}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Base Price</p>
                    <p className={mStyles.textRight}>{values.basePrice}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Dest Charge</p>
                    <p className={mStyles.textRight}>{values.destCharges}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Tax</p>
                    <p className={mStyles.textRight}>{values.taxCredit}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Effective Price</p>
                    <p className={mStyles.textRight}>{values.effectPrice}</p>
                  </div>

                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Delivery Fees</p>
                    <p className={mStyles.textRight}>{values.deliveryFees}</p>
                  </div>

                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

import gql from "graphql-tag";

export const FETCH_WARRANTY_DETAILS = gql`
  query getWarrantytDetail($qId: String!) {
    getWarrantytDetail(qId: $qId) {
      vid
      car_warranty
      battery_warranty
    }
  }
`;

export const CREATE_WARRANTY_DETAIL = gql`
  mutation inputWarrantyDetail(
    $userEmail: String!
    $token : String!
    $vid: String!
    $carWarranty: String
    $batteryWarranty: String
  ) {
    inputWarrantyDetail(
      userEmail: $userEmail
      vid: $vid
      token : $token
      carWarranty: $carWarranty
      batteryWarranty: $batteryWarranty
    ) {
      status
      statusBool
    }
  }
`;

export const UPDATE_WARRANTY_DETAIL = gql`
  mutation updateWarrantyDetail(
    $userEmail: String!
    $vid: String!
    $token : String!
    $carWarranty: String
    $batteryWarranty: String
  ) {
    updateWarrantyDetail(
      userEmail: $userEmail
      vid: $vid
      token : $token
      carWarranty: $carWarranty
      batteryWarranty: $batteryWarranty
    )
  }
`;

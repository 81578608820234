import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Divider, Typography, MenuItem, Select } from "@material-ui/core";

import { formStyle, modalStyle, CStyles } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import {
  FETCH_ENERGY_CONSUMPTION_INFO,
  CREATE_ENERGY_INFO,
  UPDATE_ENERGY_INFO,
} from "./api";
import { FETCH_ALL_VEHICLE_DATA } from "../VehicleDetails/api";

import { EmailContext } from "../../Context/EmailContext";
export default function EnergyConsumptionInfo({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();
  const mStyles = modalStyle();
  const classes = CStyles();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token : token,
    vehicleConsumptionEVDB: "",
    vehicleConsumptionNEDC: "",
    vehicleConsumptionWLTPTEL: "",
    vehicleFuelEquivalentEVDB: "",
    vehicleFuelEquivalentNEDC: "",
    vehicleFuelEquivalentWLTPTEL: "",
    energyConsumptionHighway: "",
    energyConsumptionCombined: "",
    energyConsumptionCity: "",
    vehicleConsumptionEVDBBool: false,
    vehicleConsumptionNEDCBool: false,
    vehicleConsumptionWLTPTELBool: false,
  });

  /* <<<=========      API   ===========>>>  */
  const [callThePricingApi, { loading, data, error }] = useLazyQuery(
    FETCH_ENERGY_CONSUMPTION_INFO
  );

  // console.log(values)

  const [updateEnergyInfo] = useMutation(UPDATE_ENERGY_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updateEnergyConsumption);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_ENERGY_CONSUMPTION_INFO,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [createEnergyInfo] = useMutation(CREATE_ENERGY_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputEnergyConsumption?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_ENERGY_CONSUMPTION_INFO,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  /* <<<=========      FUNCTIONS   ===========>>>  */
  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callThePricingApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const { getEnergyConsumption } = data;
      setValues({
        ...values,
        vehicleConsumptionEVDB: getEnergyConsumption?.vehicle_consumption_evdb,
        vehicleConsumptionNEDC: getEnergyConsumption?.vehicle_consumption_nedc,
        vehicleConsumptionWLTPTEL:
          getEnergyConsumption?.vehicle_consumption_wltp_tel,
        vehicleFuelEquivalentEVDB:
          getEnergyConsumption?.vehicle_fuel_equivalent_evdb,
        vehicleFuelEquivalentNEDC:
          getEnergyConsumption?.vehicle_fuel_equivalent_nedc,
        vehicleFuelEquivalentWLTPTEL:
          getEnergyConsumption?.vehicle_fuel_equivalent_wltp_tel,
        energyConsumptionHighway:
          getEnergyConsumption?.energy_consumption_highway,
        energyConsumptionCombined:
          getEnergyConsumption?.energy_consumption_combined,
        energyConsumptionCity: getEnergyConsumption?.energy_consumption_city,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  async function validator() {
    const parsedValuesAre = {
      vid: updateValues ? updateValues?.vid : "",
      userEmail: email,
      token : token,
      vehicleConsumptionEVDB: values?.vehicleConsumptionEVDBBool,
      vehicleConsumptionNEDC: values?.vehicleConsumptionNEDCBool,
      vehicleConsumptionWLTPTEL: values?.vehicleConsumptionWLTPTELBool,
      vehicleFuelEquivalentEVDB: isNaN(
        parseFloat(values.vehicleFuelEquivalentEVDB)
      )
        ? 0
        : parseFloat(values.vehicleFuelEquivalentEVDB),
      vehicleFuelEquivalentNEDC: isNaN(
        parseFloat(values.vehicleFuelEquivalentNEDC)
      )
        ? 0
        : parseFloat(values.vehicleFuelEquivalentNEDC),
      vehicleFuelEquivalentWLTPTEL: isNaN(
        parseFloat(values.vehicleFuelEquivalentWLTPTEL)
      )
        ? 0
        : parseFloat(values.vehicleFuelEquivalentWLTPTEL),

      energyConsumptionHighway: isNaN(
        parseFloat(values.energyConsumptionHighway)
      )
        ? 0
        : parseFloat(values.energyConsumptionHighway),

      energyConsumptionCombined: isNaN(
        parseFloat(values.energyConsumptionCombined)
      )
        ? 0
        : parseFloat(values.energyConsumptionCombined),
      energyConsumptionCity: isNaN(parseFloat(values.energyConsumptionCity))
        ? 0
        : parseFloat(values.energyConsumptionCity),
    };
    return parsedValuesAre;
  }

  async function callCreateAPI() {
    setOutcome("");
    setErrors("");
    const parsedValuesAre = await validator();
    await createEnergyInfo({
      variables: parsedValuesAre,
    });
  }

  async function callEditAPI() {
    setOutcome2("");
    setErrors("");
    // updateData();

    const validatedValues = await validator();

    await updateEnergyInfo({
      variables: validatedValues,
    });
  }

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }
  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>Loading ..</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.warnText}>
                      VEHICLE CONSUMPTION RATE depends on BATTERY INFO and RANGE
                      CONSUMPTION VALUE. Any edit on those values need to be
                      done through RANGE CONSUMPTION and BATTERY INFO
                    </Typography>
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Consumption Zecar Range
                    </Typography>
                    {/* <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Consumption Zecar"
                      name="vehicleConsumptionEVDB"
                      value={values.vehicleConsumptionEVDB}
                      onChange={onChange}
                    /> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="vehicleConsumptionEVDBBool"
                      value={values.vehicleConsumptionEVDBBool}
                      className={classes.selectorStyle}
                      onChange={(text) => onChange(text)}
                      label="Select Search"
                    >
                      <MenuItem value={true}>Change</MenuItem>
                      <MenuItem value={false}>Dont Change</MenuItem>
                    </Select>
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Consumption NEDC
                    </Typography>
                    {/* <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Consumption NEDC"
                      name="vehicleConsumptionNEDC"
                      value={values.vehicleConsumptionNEDC}
                      onChange={onChange}
                    /> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="vehicleConsumptionNEDCBool"
                      value={values.vehicleConsumptionNEDCBool}
                      className={classes.selectorStyle}
                      onChange={(text) => onChange(text)}
                      label="Select Search"
                    >
                      <MenuItem value={true}>Change</MenuItem>
                      <MenuItem value={false}>Dont Change</MenuItem>
                    </Select>
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Consumption WLTP
                    </Typography>
                    {/* <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Consumption WLTP_TEL"
                      name="vehicleConsumptionWLTPTEL"
                      value={values.vehicleConsumptionWLTPTEL}
                      onChange={onChange}
                    /> */}
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="vehicleConsumptionWLTPTELBool"
                      value={values.vehicleConsumptionWLTPTELBool}
                      className={classes.selectorStyle}
                      onChange={(text) => onChange(text)}
                      label="Select Search"
                    >
                      <MenuItem value={true}>Change</MenuItem>
                      <MenuItem value={false}>Dont Change</MenuItem>
                    </Select>
                  </div>
                  

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Fuel Equivalent Zecar Range
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Fuel Equivalent Zecar"
                      name="vehicleFuelEquivalentEVDB"
                      value={values.vehicleFuelEquivalentEVDB}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Fuel Equivalent NEDC
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Fuel Equivalent NEDC"
                      name="vehicleFuelEquivalentNEDC"
                      value={values.vehicleFuelEquivalentNEDC}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vehicle Fuel Equivalent WLTP
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vehicle Fuel Equivalent WLTP_TEL"
                      name="vehicleFuelEquivalentWLTPTEL"
                      value={values.vehicleFuelEquivalentWLTPTEL}
                      onChange={onChange}
                    />
                  </div>
                 

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Real World Energy Consumption Combined
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Energy Consumption Combined"
                      name="energyConsumptionCombined"
                      value={values.energyConsumptionCombined}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Real World Energy Consumption City
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter  Energy Consumption City"
                      name="energyConsumptionCity"
                      value={values.energyConsumptionCity}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Real World Energy Consumption Highway
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Energy Consumption Highway"
                      name="energyConsumptionHighway"
                      value={values.energyConsumptionHighway}
                      onChange={onChange}
                    />
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />
                  {data?.getEnergyConsumption ? (
                    <CommonEditButton
                      title={"Update"}
                      onClick={() => callEditAPI()}
                    />
                  ) : (
                    <CommonAddButton
                      title={"Create"}
                      onClick={() => callCreateAPI()}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.warnText}>
                      VEHICLE CONSUMPTION RATE depends on BATTERY INFO and RANGE
                      CONSUMPTION VALUE. Any edit on those values need to be
                      done through RANGE CONSUMPTION and BATTERY INFO
                    </Typography>
                  </div>

                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vehicle Consumption Zecar Range</p>
                    <p className={mStyles.textRight}>
                      {values.vehicleConsumptionEVDB}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vehicle Consumption NEDC</p>
                    <p className={mStyles.textRight}>
                      {values.vehicleConsumptionNEDC}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Vehicle Consumption WLTP
                    </p>
                    <p className={mStyles.textRight}>
                      {values.vehicleConsumptionWLTPTEL}{" "}
                    </p>
                  </div>
                
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Vehicle Fuel Equivalent Zecar Range
                    </p>
                    <p className={mStyles.textRight}>
                      {values.vehicleFuelEquivalentEVDB}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Vehicle Fuel Equivalent NEDC
                    </p>
                    <p className={mStyles.textRight}>
                      {values.vehicleFuelEquivalentNEDC}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Vehicle Fuel Equivalent WLTP
                    </p>
                    <p className={mStyles.textRight}>
                      {values.vehicleFuelEquivalentWLTPTEL}{" "}
                    </p>
                  </div>

                 
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Real World Energy Consumption Combined
                    </p>
                    <p className={mStyles.textRight}>
                      {values.energyConsumptionCombined}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      {" "}
                      Real World Energy Consumption City
                    </p>
                    <p className={mStyles.textRight}>
                      {values.energyConsumptionCity}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>
                      Real World Energy Consumption Highway
                    </p>
                    <p className={mStyles.textRight}>
                      {values.energyConsumptionHighway}{" "}
                    </p>
                  </div>

                  <Divider className={mStyles.dividerLine} />
                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

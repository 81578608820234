import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Colors } from '../../Styles/Colors/Colors'

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from '../../Styles/CommonStyles'

import { Divider, Slide } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import { EmailContext } from '../../Context/EmailContext'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from '../../Component/Text'
import { CommonAddButton } from '../../Component/ButtonComponent'

import Loading from '../../Component/Loading'

import { FETCH_DUTIES_AND_OTHER, DELETE_DUTIES_AND_OTHER } from './api'

import AddDuty from './AddDuty'
import EditDuty from './EditDuty'

export default function DutiesAndOtherDetails() {
  const { email, token } = useContext(EmailContext)

  const classes = CStyles()
  const mStyles = modalStyle()

  const [modalInfo, setModalInfo] = useState(null)
  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [formModal, setFormModal] = useState({
    open: false,
    title: '',
    check: '',
  })
  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error } = useQuery(FETCH_DUTIES_AND_OTHER, {
    variables: { userEmail: email , token: token},
  })

  const [deleteDutyDetails] = useMutation(DELETE_DUTIES_AND_OTHER, {
    update(_, result) {
      setOutcome(result?.data?.deleteDutiesAndOther)
    },
    onError(err) {
      setErrors(true)
    },
    refetchQueries: [
      {
        query: FETCH_DUTIES_AND_OTHER,
        variables: { userEmail: email, token: token},
      },
    ],
  })

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const callDelete = (id) => {
   deleteDutyDetails({
      variables: {
        qId: parseInt(id),
        userEmail: email,
        token: token
      },
    })
  }

  const callEdit = (objIs) => {
    setModalInfo(objIs)
    handleOpenForm('Edit Duties/Other', 'editDuties')
  }

  const handleOpenForm = (title, check) => {
    setOutcome(false)
    setErrors(false)
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    })
  }

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Duties and Other Details</p>
        </div>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    )
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Duties and Other Details</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.noSearchRowDiv}>
          <CommonAddButton
            title="Add Duties/Other"
            onClick={() => handleOpenForm('Add Duties And Other Details', 'addDuty')}
          />
        </div>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      })
                    }}
                  />
                  <div className={mStyles.formModalInnerContent}>
                    {formModal?.check === 'addDuty' ? <AddDuty /> : null}

                    {formModal?.check === 'editDuties' ? (
                      <EditDuty updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (

          <div className={classes.bodyDiv}>
            {data && data?.getDutiesAndOtherData ? (
              <>
                <TableContainer
                  elevation={0}
                  className={classes.table}
                  component={Paper}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          id
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          State
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Stamp Duty Payable Ev
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Stamp Duty Payable Petrol
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Car Registration
                        </StyledTableCell>


                        <StyledTableCell align="center">
                          Incentive Duty Deduction
                        </StyledTableCell>


                        <StyledTableCell align="center">
                          Incentive other
                        </StyledTableCell>


                        <StyledTableCell align="center">
                          Incentive Rebate
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Emission Value
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Edit
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          Delete
                        </StyledTableCell>


                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getDutiesAndOtherData.map((obj, index) => {
                        return (
                          <StyledTableRow key={index}>
                            <StyledTableCell align="center">
                              {obj.id}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {obj.state}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.stamp_duty_payable_ev}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {obj.stamp_duty_payable_petrol}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.car_registration}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.incentive_duty_deduction}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.incentive_other}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.incentive_rebate}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.emission_value}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                                  <button
                                    onClick={() => {
                                      callEdit(obj)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <button
                                    onClick={() => {
                                      callDelete(obj.id)
                                    }}
                                  >
                                    Delete
                                  </button>
                                </StyledTableCell>
                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <StatusText title={'No Data'} fColor={Colors.black} />
            )}
          </div>

        )}

        {outcome && outcome === true && (
          <StatusText title={'Deleted Successfully'} fColor={Colors.black} />
        )}

        {errors && errors === true && (
          <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
        )}
      </>
    </div>
  )
}

export const Colors = {
  black: "#000000",
  white: "#ffffff",
  error: "#FF0000",
  wildSand1: "#f4f3f3",
  silverChalice: "#aaaaaa",
  orient: "#00587a",
  blueLagoon: "#008891",
  Orange: "#FBD630",
  lightOrange: "#998737",
  gray: "#8a8a8a",
  brown: "#792B00",
  themeBg: "#F0F3F4",
  sideNavBg: "#2e3345",
  blue: "#528FF0",
  darkBlue: "#252939",
  lightBlack: "#777",
  lightBlue: "#45495a",
  textHoverBlue: "#22527b",
  buttonBg: "#528FF0",
  buttonBgHover: "#2371ec",
  btnBorderBlue: "#3b80ee",
  lightGray: "#e2e8ea",
  tableHeadBlue: "#EAEFF0",
  tableHeadTextBlue: "58666e",
  tableBorderGray: "#EAEAEA",
  modalOuterBg: "rgba(0, 0, 0, 0.2)",
  dashBg: "#F7F7F7",
  whiteBlue: "#EAF2F5",
  grayWhite: "#F7F7F7",
  lightPink: "#F8F7FA",
  lightBlack2: "#282828",
  grayFont: "#D0D0D0"
};

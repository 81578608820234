import * as React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function Loading() {
  return (
    <div
      style={{
        // display: "flex",
        // flexDirection: "column",
        // alignItems: "center",
        padding: 20,
      }}
    >
      <Skeleton animation="wave" height={100} width="100%" />
      <Skeleton variant="rect" height={200} width={"100%"} />
    </div>
  );
}

import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import { Colors } from "../Styles/Colors/Colors";
import { Fonts } from "../Styles/Colors/Font";
import { RiCloseFill } from "react-icons/ri";

export function StatusText({ title, fColor }) {
  return (
    <div
      style={{
        fontSize: 14,
        fontWeight: "700",
        fontFamily: Fonts.Lato,
        color: fColor,
        textAlign: "center",
        padding: `12px 0`,
      }}
    >
      {title}
    </div>
  );
}

export function ModalHead({ title, onClick }) {
  const classes = textStyle();

  return (
    <div className={classes.headDiv}>
      <p className={classes.title}>{title}</p>
      <Button onClick={onClick} className={classes.closeBtn}>
        <RiCloseFill className={classes.closeIcon} />
      </Button>
    </div>
  );
}

const textStyle = makeStyles((theme) => ({
  headDiv: {
    height: 40,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.blue,
    padding: `0 0 0 18px`,
  },

  title: {
    fontSize: 14,
    margin: 0,
    paddingRight: 10,
    fontWeight: "bold",
    fontFamily: Fonts.Ubuntu,
    color: Colors.white,
  },

  closeBtn: {
    padding: `5px 0`,
    minWidth: 50,
    maxWidth: 40,
    borderRadius: 0,
    height: "100%",
    "&:hover": {
      backgroundColor: Colors.buttonBgHover,
    },
  },
  closeIcon: {
    fontWeight: "bold",
    fontSize: 26,
    color: Colors.white,
    padding: 0,
  },
}));

import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";

import { Divider, Slide } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import { EmailContext } from "../../Context/EmailContext";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from "../../Component/Text";
import {
  ModalUpdateButton,
} from "../../Component/ButtonComponent";

import Loading from "../../Component/Loading";

import { FETCH_ASSUMPTION_DATA } from "./api";


import EdiAssumptionConstants from "./EditAssumption";


export default function Assumptions() {
  const { email, token } = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);

  // const [edit, setEdit] = useState(false)

  const [modalInfo, setModalInfo] = useState(null);
  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formModal, setFormModal] = useState({
    open: false,
    title: "",
    check: "",
  });
  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error } = useQuery(FETCH_ASSUMPTION_DATA, {
    variables: { userEmail: email, token:token },
  });



  /* <<<=========      ONCHANGE FUNC   ===========>>>  */



  const callEdit = (objIs) => {
    setOpen(false);
    setModalInfo(objIs);
    handleOpenForm("Edit Assumption Constants", "editAssumptionConstant");
  };

  const handleOpenForm = (title, check) => {
    setOutcome(false);
    setErrors(false);
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    });
  };

  const handleOpen = async (obj) => {
    setModalInfo(obj);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Assumptions</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Assumptions</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      });
                    }}
                  />
                  <div className={mStyles.formModalInnerContent}>

                    {formModal?.check === "editAssumptionConstant" ? (
                      <EdiAssumptionConstants updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className={classes.bodyDiv}>
            {data && data?.getAssumptionConstantData ? (
              <>
                <TableContainer
                  elevation={0}
                  className={classes.table}
                  component={Paper}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Petrol Price
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Ev Maintenance
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Petrol Maintenance
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Ev Insurance
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Petrol Insurance
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Tyres Ev
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Tyres Petrol
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getAssumptionConstantData.map(
                        (obj, index) => {
                          return (
                            <StyledTableRow
                              key={index}
                              onClick={() => handleOpen(obj)}
                              hover={true}
                              className={classes.tableRow}
                            >
                              <StyledTableCell align="center">
                                {obj.petrol_price}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.ev_maintenance}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {obj.petrol_maintenance}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.ev_insurance}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {obj.petrol_insurance}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.tyres_ev}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.tyres_petrol}
                              </StyledTableCell>
                             
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <StatusText title={"No Data"} fColor={Colors.black} />
            )}

            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={mStyles.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={mStyles.paper}>
                    <div className={mStyles.formModalContent}>
                      <div className={mStyles.floatLeft}>Assumptions</div>
                    </div>

                    <div className={mStyles.formModalInnerContent}>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Id</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.id} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Petrol Price</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.petrol_price}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Ev Maintenance</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.ev_maintenance}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Petrol Maintenance</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.petrol_maintenance} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Ev Insurance</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.ev_insurance}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Petrol Insurance</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.petrol_insurance} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Tyres Ev</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.tyres_ev} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Tyres Petrol</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.tyres_petrol}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          Depreciation Period Ev
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.depreciation_period_ev}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          {" "}
                          Depreciation Period Petrol
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.depreciation_period_petrol}{" "}
                        </p>
                      </div>
      
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Scrap Value Ev</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.scrap_value_ev}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Scrap Value Petrol</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.scrap_value_petrol}{" "}
                        </p>
                      </div>

                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Emissions Ev</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.emissions_ev}{" "}
                        </p>
                      </div>

                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Emissions Petrol</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.emissions_petrol}{" "}
                        </p>
                      </div>

                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Depreciation Year</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.dep_year}{" "}
                        </p>
                      </div>

                      {outcome && outcome === true && (
                        <StatusText
                          title={"Deleted Successfully"}
                          fColor={Colors.black}
                        />
                      )}

                      <ModalUpdateButton
                        title={"Edit"}
                        onClick={() => callEdit(modalInfo)}
                      />

                   
                    </div>
                  </div>
                </Fade>
              </Modal>
            </div>
          </div>
        )}

        {errors && errors === true && (
          <StatusText title={"Something Went Wrong"} fColor={Colors.black} />
        )}
      </>
    </div>
  );
}

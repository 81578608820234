import React, { useContext, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { CreateButton, ResetButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { FETCH_DUTIES_AND_OTHER, CREATE_DUTIES_OTHER_EXEMPTIONS } from "./api";

import { EmailContext } from "../../Context/EmailContext";

export default function AddDuty() {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState("");
  const [errors, setErrors] = useState("");

  const [numValues, setnumValues] = useState({
    noRateValue: 0,
    noRateLimitValue: 0,
    noPetrolValue: 0,
    noPetrolLimitValue: 0,
  });

  const [evRate, setEvRate] = useState({});
  const [evThreshold, setEvThreshold] = useState({});
  const [petrolRate, setPetrolRate] = useState({});
  const [petrolThreshold, setPetrolThreshold] = useState({});

  const initialState = {
    state: "",
    emissionValue: "",
    incentiveDutyDeduction: "",
    incentiveRebate: "",
    incentiveOther: "",
    incentiveDutyLimit: 0,
    incentiveRebateLimit: 0,
    rebateFirstCondition: 0,
    quota: "",
    userEmail: email,
    token: token,
    registrationFee: "",
    vehicleTax: "",
    plateFee: "",
    ctp: "",
    evLuxuryTax: "",
    peLuxuryTax: "",
    evLtPerc: "",
    peLtPerc: "",
  };

  const [values, setValues] = useState(initialState);

  /* <<<=========     useMutation   ===========>>>  */

  const [createEmissionConst] = useMutation(CREATE_DUTIES_OTHER_EXEMPTIONS, {
    update(_, result) {
      setOutcome(result?.data?.inputDutiesAndExemptions?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_DUTIES_AND_OTHER,
        variables: { userEmail: email, token: token },
      },
    ],
  });

  function onSubmit() {
    setOutcome({});
    setErrors({});

    values["evRateData"] = JSON.stringify(evRate);
    values["evThresholdData"] = JSON.stringify(evThreshold);
    values["petrolRateData"] = JSON.stringify(petrolRate);
    values["petrolThresholdData"] = JSON.stringify(petrolThreshold);
    values["emissionValue"] = parseFloat(values.emissionValue);

    values["incentiveDutyDeduction"] =isNaN(
      parseFloat(values.incentiveDutyDeduction)
    )
      ? 0
      : parseFloat(values.incentiveDutyDeduction);
    values["incentiveRebate"] =isNaN(parseFloat(values.incentiveRebate))
      ? 0
      : parseFloat(values.incentiveRebate);
    values["registrationFee"] =isNaN(parseFloat(values.registrationFee))
      ? 0
      : parseFloat(values.registrationFee);
    values["vehicleTax"] =isNaN(parseFloat(values.vehicleTax))
      ? 0
      : parseFloat(values.vehicleTax);
    values["plateFee"] =isNaN(parseFloat(values.plateFee))
      ? 0
      : parseFloat(values.plateFee);
    values["ctp"] =isNaN(parseFloat(values.ctp)) ? 0 : parseFloat(values.ctp);
    values["evLuxuryTax"] =isNaN(parseFloat(values.evLuxuryTax))
      ? 0
      : parseFloat(values.evLuxuryTax);
    values["peLuxuryTax"] =isNaN(parseFloat(values.peLuxuryTax))
      ? 0
      : parseFloat(values.peLuxuryTax);
    values["evLtPerc"] =isNaN(parseFloat(values.evLtPerc))
      ? 0
      : parseFloat(values.evLtPerc);
    values["peLtPerc"] =isNaN(parseFloat(values.peLtPerc))
      ? 0
      : parseFloat(values.peLtPerc);


      createEmissionConst({ variables: values });
  }
  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    if (
      event.target.name === "state" ||
      event.target.name === "quota" ||
      event.target.name === "emissionValue" ||
      event.target.name === "incentiveOther"
    ) {
      setValues({ ...values, [event.target.name]: event.target.value });
    } else {
      setValues({ ...values, [event.target.name]: Number(event.target.value) });
    }
  };
  const onChangeNumber = (event) => {
    setnumValues({ ...numValues, [event.target.name]: event.target.value });
  };

  const onChangeEvRate = (event) => {
    setEvRate({ ...evRate, [event.target.name]: event.target.value });
  };
  const onChangeEvThreshold = (event) => {
    setEvThreshold({ ...evThreshold, [event.target.name]: event.target.value });
  };
  const onChangePetrolRate = (event) => {
    setPetrolRate({ ...petrolRate, [event.target.name]: event.target.value });
  };

  const onChangePetrolThreshold = (event) => {
    setPetrolThreshold({
      ...petrolThreshold,
      [event.target.name]: event.target.value,
    });
  };

  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({});
    setErrors({});
    setValues(initialState);
  }

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}> State</Typography>{" "}
          <TextInputField
            id="State"
            placeholder="State"
            name="state"
            value={values.state}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            EV Rate Value
          </Typography>{" "}
          <TextInputField
            id="rateValue"
            placeholder="No of Rate Value"
            name="noRateValue"
            value={numValues.noRateValue}
            onChange={onChangeNumber}
          />
        </div>

        {range(
          1,
          parseInt(numValues.noRateValue ? numValues.noRateValue : 0)
        ).map((obj, index) => (
          <div className={formClass.formRow} key={`${index}_ev_rate_${obj}`}>
            {" "}
            <Typography className={formClass.leftText}>
              {" "}
              Rate {obj} %
            </Typography>{" "}
            <TextInputField
              id="rateValue"
              placeholder="No of Rate Value in Percentage"
              name={`ev_rate_${obj}`}
              value={evRate[`ev_rate_${obj}`]}
              onChange={onChangeEvRate}
            />
          </div>
        ))}
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            EV Threshold Data
          </Typography>{" "}
          <TextInputField
            id="rateLimitValue"
            placeholder="No of EV Thresholds"
            name="noRateLimitValue"
            value={numValues.noRateLimitValue}
            onChange={onChangeNumber}
          />
        </div>

        {range(
          1,
          parseInt(numValues.noRateLimitValue ? numValues.noRateLimitValue : 0)
        ).map((obj, index) => (
          <div
            className={formClass.formRow}
            key={`${index}_ev_threshold_${obj}`}
          >
            {" "}
            <Typography className={formClass.leftText}>
              {" "}
              Ev Theshold {obj}
            </Typography>{" "}
            <TextInputField
              id="rateValue"
              placeholder="No of Theshold Value"
              name={`ev_threshold_${obj}`}
              value={evThreshold[`ev_threshold_${obj}`]}
              onChange={onChangeEvThreshold}
            />
          </div>
        ))}

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Petrol Rate Value
          </Typography>{" "}
          <TextInputField
            id="noPetrolValue"
            placeholder="Enter No of Petrol Value"
            name="noPetrolValue"
            value={numValues.noPetrolValue}
            onChange={onChangeNumber}
          />
        </div>

        {range(
          1,
          parseInt(numValues.noPetrolValue ? numValues.noPetrolValue : 0)
        ).map((obj, index) => (
          <div
            className={formClass.formRow}
            key={`${index}_petrol_rate_${obj}`}
          >
            {" "}
            <Typography className={formClass.leftText}>
              {" "}
              Petrol Rate {obj} %
            </Typography>{" "}
            <TextInputField
              id="petrolRateValue"
              placeholder="No of Petrol Rate Value in Percentage"
              name={`petrol_rate_${obj}`}
              value={petrolRate[`petrol_rate_${obj}`]}
              onChange={onChangePetrolRate}
            />
          </div>
        ))}

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Petrol Theshold
          </Typography>{" "}
          <TextInputField
            id="noPetrolLimitValue"
            placeholder="No of Petrol Threshold"
            name="noPetrolLimitValue"
            value={numValues.noPetrolLimitValue}
            onChange={onChangeNumber}
          />
        </div>

        {range(
          1,
          parseInt(
            numValues.noPetrolLimitValue ? numValues.noPetrolLimitValue : 0
          )
        ).map((obj, index) => (
          <div
            className={formClass.formRow}
            key={`${index}_petrol_rate_limit_${obj}`}
          >
            {" "}
            <Typography className={formClass.leftText}>
              {" "}
              Petrol Theshold {obj}
            </Typography>{" "}
            <TextInputField
              id="petrolThresholdValue"
              placeholder="No of Petrol Theshold"
              name={`petrol_threshold_${obj}`}
              value={petrolThreshold[`petrol_threshold_${obj}`]}
              onChange={onChangePetrolThreshold}
            />
          </div>
        ))}

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}> Quota</Typography>{" "}
          <TextInputField
            id="quota"
            placeholder="quota"
            name="quota"
            value={values.quota}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Emission Value
          </Typography>{" "}
          <TextInputField
            id="emissionValue"
            placeholder="Emission Value"
            name="emissionValue"
            value={values.emissionValue}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Incentive Duty Detection
          </Typography>{" "}
          <TextInputField
            id="Incentive Duty Detection"
            placeholder="Incentive Duty Detection"
            name="incentiveDutyDeduction"
            value={values.incentiveDutyDeduction}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Incentive Duty Limit Theshold
          </Typography>{" "}
          <TextInputField
            id="Incentive Duty Limit"
            placeholder="Incentive Duty Limit"
            name="incentiveDutyLimit"
            value={values.incentiveDutyLimit}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Incentive Rebate
          </Typography>{" "}
          <TextInputField
            id="Incentive Rebate"
            placeholder="Incentive Rebate"
            name="incentiveRebate"
            value={values.incentiveRebate}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Incentive Rebate Limit
          </Typography>{" "}
          <TextInputField
            id="Incentive Rebate Limit"
            placeholder="Incentive Rebate Limit"
            name="incentiveRebateLimit"
            value={values.incentiveRebateLimit}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Rebate Only For First __ Vehicles
          </Typography>{" "}
          <TextInputField
            id="rebateFirstCondition"
            placeholder="Rebate Only For First __ Vehicles"
            name="rebateFirstCondition"
            value={values.rebateFirstCondition}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Incentive Other
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="incentiveOther"
            value={values.incentiveOther}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Registration
          </Typography>{" "}
          <TextInputField
            id="Car Registration"
            placeholder="Car Registration"
            name="registrationFee"
            value={values.registrationFee}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Vehicle Tax
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="vehicleTax"
            value={values.vehicleTax}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Plate Fee
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="plateFee"
            value={values.plateFee}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}> CTP</Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="ctp"
            value={values.ctp}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            EV Luxury Tax (Threshold)
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="evLuxuryTax"
            value={values.evLuxuryTax}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Petrol Luxury Tax (Threshold)
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="peLuxuryTax"
            value={values.peLuxuryTax}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Luxury Tax % Ev
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="evLtPerc"
            value={values.evLtPerc}
            onChange={onChange}
          />
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            {" "}
            Luxury Tax % Petrol
          </Typography>{" "}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="peLtPerc"
            value={values.peLtPerc}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={"Something Went Wrong Cannot Be Created"}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";
import {
  FaCarBattery,
  FaImage,
  FaCogs,
  FaCertificate,
  FaRegBookmark,
} from "react-icons/fa";

import { AiOutlineCar, AiFillStar, AiFillDollarCircle } from "react-icons/ai";

import {
  MdPermDeviceInformation,
  MdFormatColorFill,
  MdLocalGasStation,
} from "react-icons/md";

import { RiToolsFill, RiFileCopyFill } from "react-icons/ri";

import { GiRollingEnergy, GiRoad } from "react-icons/gi";

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slide,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from "../../Component/Text";
import {
  CommonAddButton,
  ModalCommonTextButton,
  ModalTextButtonForComponent,
  ModalDeleteButton,
  ModalUpdateButton,
  ModalPublishButton,
  RefreshButton,
  SearchBox,
  SearchButton,
  PrevButton,
  NextButton,
} from "../../Component/ButtonComponent";

import Loading from "../../Component/Loading";

import BodyAndChasis from "../BodyAndChasis";
import PricingDetails from "../PricingInfo";
import BatteryInformation from "../BatteryInfo";
import EnergyConsumptionInfo from "../EnergyInfo";
import RangeConsumptionInfo from "../RangeInfo";
import PerformanceInfo from "../PerformanceInfo";
import MaintenanceDetails from "../Maintenance";
import RatingDetails from "../RatingInfo";
import BookDemo from "../BookDemo";
import PetrolEquivalent from "../PetrolEquivalent";
import WarrantyDetails from "../WarrantyInfo";
import TechSpecDetails from "../TechSpec";

import AddVehicle from "./AddVehicle";
import AddVariant from "./AddVariant";
import EditVehicle from "./EditVehicle";
import EditModelName from "./EditModelName";
import EditVariantName from "./EditVariantName";
import { EmailContext } from "../../Context/EmailContext";

import {
  FETCH_ALL_VEHICLE_DATA,
  DELETE_VEHICLE_DISPLAY_DATA,
  CHANGE_PUBLISH_STATUS,
} from "./api";

export default function VehicleDetails() {
  const { email, token } = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  const [outcome, setOutcome] = useState(false);
  const [outcome2, setOutcome2] = useState(false);
  const [errors, setErrors] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [searchColumnValue, setSearchColumnValue] = useState("None");

  const [formModal, setFormModal] = useState({
    open: false,
    title: "",
    check: "",
  });

  const NoPhoto = "Photo";

  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error, fetchMore } = useQuery(FETCH_ALL_VEHICLE_DATA, {
    variables: {
      userEmail: email,
      token,
    },
  });

  const [deleteVehicleData] = useMutation(DELETE_VEHICLE_DISPLAY_DATA, {
    update(_, result) {
      setOutcome(result?.data?.deleteVehicleDisplayData);
    },
    onError(err) {
      setErrors(true);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
    ],
  });

  const [publishData] = useMutation(CHANGE_PUBLISH_STATUS, {
    update(_, result) {
      setOutcome2(result?.data?.changePublishStatus);
    },
    onError(err) {
      setErrors(true);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
    ],
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onChange2 = (event) => {
    setSearchColumnValue(event.target.value);
  };

  /* <<<=========      MODAL CLOSE/OPEN FUNC   ===========>>>  */

  async function formatData(obj) {
    let variantsStatus;

    const variantsId = [];
    const variantsName = [];
    const variantsDetails = [];

    obj.variant_arr.map((obx) => {
      const newObj = JSON.parse(obx);

      if (obj.variant_arr.length === 1) {
        variantsStatus = false;
      } else if (obx.id !== obj.vid) {
        variantsStatus = true;
        variantsId.push(newObj.id);
        variantsName.push(newObj.name);
        variantsDetails.push(newObj.id + "-" + newObj.name);
      }
      return "exit";
    });

    obj["variantsStatus"] = variantsStatus;
    obj["variantsId"] = variantsId;
    obj["variantsName"] = variantsName;
    obj["variantsDetails"] = variantsDetails;

    return obj;
  }

  const handleOpen = async (obj) => {
    const getformattedData = await formatData(obj);
    setOutcome(false);
    setOutcome2(false);
    setErrors(false);
    setModalInfo(getformattedData);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    FORM MODAL FUNC   ===========>>>  */

  const handleOpenForm = (title, check) => {
    setOpen(false);
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    });
  };

  const deleteValue = (id) => {
    deleteVehicleData({
      variables: {
        qId: id,
        userEmail: email,
        token,
      },
    });
  };

  const changePublish = (id) => {
    publishData({
      variables: {
        qId: id,
        userEmail: email,
        token,
      },
    });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Vehicle Details</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Vehicle Details</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.searchRowDiv}>
          <div className={classes.searchRow}>
            <SearchBox
              value={searchValue}
              onChange={(text) => onChange(text)}
            />

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                className={classes.selectorLabel}
                id="demo-simple-select-outlined-label"
              >
                Select search
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="searchColumnValue"
                value={searchColumnValue}
                className={classes.selectorStyle}
                onChange={(text) => onChange2(text)}
                label="Select Search"
              >
                <MenuItem value={"None"}>General</MenuItem>
                <MenuItem value={"model_name"}>Model Name</MenuItem>
                <MenuItem value={"manufacturer"}>Manufacturer</MenuItem>
                <MenuItem value={"model_variant"}>Variant</MenuItem>
                <MenuItem value={"body_style"}>Body Style</MenuItem>
                <MenuItem value={"avail_in_australia"}>
                  Available In Aus
                </MenuItem>
                <MenuItem value={"price"}>Price '&gt;'=</MenuItem>
                <MenuItem value={"ancp_safety_rating"}>ANCP '&gt;'=</MenuItem>
                <MenuItem value={"battery"}>Battery '&gt;'=</MenuItem>
                <MenuItem value={"range"}>Range '&gt;'=</MenuItem>
                <MenuItem value={"top_speed"}>TopSpeed '&gt;'=</MenuItem>
                <MenuItem value={"efficiency"}>Efficiency '&gt;'=</MenuItem>
              </Select>
            </FormControl>

            <SearchButton
              onClick={(e) => {
                fetchMore({
                  variables: {
                    searchStatus: "True",
                    searchColumn: searchColumnValue,
                    searchValue: searchValue,
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    return fetchMoreResult;
                  },
                });
              }}
            />

            <RefreshButton
              onClick={() => {
                fetchMore({
                  variables: {
                    userEmail: email,
                    token: token,
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    setSearchColumnValue("None");
                    return fetchMoreResult;
                  },
                });
              }}
            />
          </div>
          <CommonAddButton
            title="Add Vehicle"
            onClick={() => handleOpenForm("Add New Vehicle", "addVehicle")}
          />
        </div>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      });
                    }}
                  />

                  <div className={mStyles.formModalInnerContent}>
                    {formModal?.check === "body_chase_info" ? (
                      <BodyAndChasis updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "pricing_info" ? (
                      <PricingDetails updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "battery_info" ? (
                      <BatteryInformation updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "energy_info" ? (
                      <EnergyConsumptionInfo updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "range_info" ? (
                      <RangeConsumptionInfo updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "performance_info" ? (
                      <PerformanceInfo updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "maintenance_info" ? (
                      <MaintenanceDetails updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "rating_info" ? (
                      <RatingDetails updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "book_trial" ? (
                      <BookDemo updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "addVehicle" ? <AddVehicle /> : null}
                    {formModal?.check === "editVehicle" ? (
                      <EditVehicle updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "editModelName" ? (
                      <EditModelName updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "editVariantName" ? (
                      <EditVariantName updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "add_variant" ? (
                      <AddVariant updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "petrol_equivalent" ? (
                      <PetrolEquivalent updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "warranty_info" ? (
                      <WarrantyDetails updateValues={modalInfo} />
                    ) : null}
                    {formModal?.check === "tech_specs" ? (
                      <TechSpecDetails updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
            {data?.getAllVehicleListing?.error ? (
              <StatusText
                title={data?.getAllVehicleListing?.error}
                fColor={Colors.black}
              />
            ) : (
              <div className={classes.bodyDiv}>
                {data && data?.getAllVehicleListing?.vehicleData?.length ? (
                  <>
                    <TableContainer
                      elevation={0}
                      className={classes.table}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Manufacturer
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Model Name
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Variant
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Price
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Body Style
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Available In Australia
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Publish Status
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getAllVehicleListing?.vehicleData.map(
                            (obj, index) => {
                              return (
                                <StyledTableRow
                                  key={index}
                                  onClick={() => handleOpen(obj)}
                                  hover={true}
                                  className={classes.tableRow}
                                >
                                  <StyledTableCell align="center">
                                    {obj.manufacturer}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.model_name}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {obj.model_variant}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.price}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.body_style}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.avail_in_australia}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.publish_status ? "True" : "False"}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className={classes.paginationDiv}>
                      {data &&
                      data?.getAllVehicleListing?.pageInfo?.prevPageStatus ===
                        "True" ? (
                        <PrevButton
                          onClick={() => {
                            const nextCursor =
                              data?.getAllVehicleListing?.pageInfo?.prevCursor;
                            fetchMore({
                              variables: {
                                cursor: nextCursor,
                                userName: email,
                                token: token,
                                prevPage: "True",
                              },
                              updateQuery: (
                                prevResult,
                                { fetchMoreResult }
                              ) => {
                                return fetchMoreResult;
                              },
                            });
                          }}
                        />
                      ) : null}

                      {data &&
                      data?.getAllVehicleListing?.pageInfo?.nextPageStatus ===
                        "True" ? (
                        <NextButton
                          onClick={() => {
                            const nextCursor =
                              data?.getAllVehicleListing?.pageInfo?.nextCursor;
                            fetchMore({
                              variables: {
                                cursor: nextCursor,
                                userName: email,
                                token: token,
                              },
                              updateQuery: (
                                prevResult,
                                { fetchMoreResult }
                              ) => {
                                return fetchMoreResult;
                              },
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  </>
                ) : (
                  <StatusText title={"No Data"} fColor={Colors.black} />
                )}

                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={mStyles.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={mStyles.paper}>
                        <div className={mStyles.formModalContent}>
                          <div className={mStyles.floatLeft}>
                            Vehicle Info -{modalInfo?.manufacturer}{" "}
                            {modalInfo?.model_name}
                          </div>
                          <div className={mStyles.floatRight}>
                            <ModalDeleteButton
                              onClick={() => deleteValue(modalInfo?.vid)}
                            />
                            <ModalUpdateButton
                              title={"Edit Model Name"}
                              onClick={() =>
                                handleOpenForm(
                                  "Edit Model Name",
                                  "editModelName"
                                )
                              }
                            />
                            <ModalUpdateButton
                              title={"Edit Variant Name"}
                              onClick={() =>
                                handleOpenForm(
                                  "Edit Variant Name",
                                  "editVariantName"
                                )
                              }
                            />
                            <ModalUpdateButton
                              onClick={() =>
                                handleOpenForm(
                                  "Edit Vehicle Data",
                                  "editVehicle"
                                )
                              }
                            />
                            {modalInfo?.publish_status ? (
                              <ModalPublishButton
                                onClick={() => changePublish(modalInfo.vid)}
                                title="unpublish"
                              />
                            ) : (
                              <ModalPublishButton
                                onClick={() => changePublish(modalInfo.vid)}
                                titile="publish"
                              />
                            )}
                          </div>
                        </div>
                        {/* <Divider className={mStyles.dividerLine} /> */}
                        {outcome && outcome === true && (
                          <StatusText
                            title={"Deleted Successfully"}
                            fColor={Colors.black}
                          />
                        )}

                        {outcome2 && outcome2 === true && (
                          <StatusText
                            title={"Changed Publish Status"}
                            fColor={Colors.black}
                          />
                        )}

                        {errors && errors === true && (
                          <StatusText
                            title={"Something Went Wrong"}
                            fColor={Colors.black}
                          />
                        )}

                        <div className={mStyles.formModalInnerContent}>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Vehicle Id :</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.vid}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Manufacturer :</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.manufacturer}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Model Name:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.model_name}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Model Variant :</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.model_variant}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              ANCAP Safety Rating :
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.ancp_safety_rating}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Price :</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.price}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Country of Manufacture :
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.country_of_manufacture}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Available Country :
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.available_country}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Available From :</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.available_from}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Available Now:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.available_now}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Battery Rating:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.battery_rating}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Efficiency Rating:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.efficiency_rating}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Range Rating:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.range_rating}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Acceleration 0 to 100:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.acceleration_0_100}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Seats:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.seats}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Battery:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.battery}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Range:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.range}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Efficiency:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.efficiency}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Top Speed:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.top_speed}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Year of Production:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.year_of_production}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Charge Port:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.charge_port}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              On Board Charger:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.on_board_charger}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Charging Efficiency:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.charging_efficiency}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Body Style:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.body_style}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              Avail In Australia:
                            </p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.avail_in_australia}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          {modalInfo?.variantsStatus ? (
                            <div className={mStyles.rowView}>
                              <p className={mStyles.textLeft}>
                                Variants Details
                              </p>
                              {modalInfo?.variantsName.map((obj) => (
                                <div className={mStyles.rowView} key={obj}>
                                  <p className={mStyles.textRight}>{obj}</p>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className={mStyles.rowView}>
                              <p className={mStyles.textLeft}>
                                Variants Details
                              </p>

                              <p className={mStyles.textRight}>
                                {"No Varaints"}
                              </p>
                            </div>
                          )}

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Demo Link:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.book_demo}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Created By:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.creator}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Created On:</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.created_on}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>Primary Image</p>
                            <p className={mStyles.textRight}>
                              {modalInfo?.primary_img_link ? (
                                <>
                                  <img
                                    src={modalInfo?.primary_img_link}
                                    alt={`${NoPhoto} is not available`}
                                    width="200"
                                    height="100"
                                  />{" "}
                                </>
                              ) : (
                                "No Image"
                              )}
                            </p>
                          </div>

                          <Divider className={mStyles.dividerLine} />

                          <div className={mStyles.modalBtnView}>
                            {modalInfo?.variantsStatus ? (
                              <ModalCommonTextButton
                                startIcon={<RiFileCopyFill />}
                                title="View Variants"
                                // onClick={() => handleOpenForm("Add Image")
                                to={{
                                  pathname: "/variantsdata",
                                  aboutProps: { data: modalInfo },
                                }}
                              />
                            ) : (
                              <ModalTextButtonForComponent
                                title="Add Variant"
                                startIcon={<RiFileCopyFill />}
                                onClick={() =>
                                  handleOpenForm(
                                    `Add Variant - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                    "add_variant"
                                  )
                                }
                              />
                            )}
                            <ModalTextButtonForComponent
                              title="View Body Chasis Info"
                              startIcon={<AiOutlineCar />}
                              onClick={() =>
                                handleOpenForm(
                                  `Body and Chasis - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "body_chase_info"
                                )
                              }
                            />
                            <ModalTextButtonForComponent
                              title="View Battery Info"
                              startIcon={<FaCarBattery />}
                              onClick={() =>
                                handleOpenForm(
                                  `Battery Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "battery_info"
                                )
                              }
                            />
                            <ModalTextButtonForComponent
                              title="View Pricing Info"
                              startIcon={<AiFillDollarCircle />}
                              onClick={() =>
                                handleOpenForm(
                                  `Pricing Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "pricing_info"
                                )
                              }
                            />
                            <ModalTextButtonForComponent
                              title="View Energy Consumption"
                              startIcon={<GiRollingEnergy />}
                              onClick={() =>
                                handleOpenForm(
                                  `Energy Consumption Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "energy_info"
                                )
                              }
                            />
                            <ModalTextButtonForComponent
                              title="View Range Consumption"
                              startIcon={<GiRoad />}
                              onClick={() =>
                                handleOpenForm(
                                  `Range Consumption Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "range_info"
                                )
                              }
                            />
                            <ModalTextButtonForComponent
                              title="View Performance Info"
                              startIcon={<FaCogs />}
                              onClick={() =>
                                handleOpenForm(
                                  `Performance Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "performance_info"
                                )
                              }
                            />

                            <ModalTextButtonForComponent
                              title="Add/Edit Book-Trial Link"
                              startIcon={<FaRegBookmark />}
                              onClick={() =>
                                handleOpenForm(
                                  `Book Trial - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "book_trial"
                                )
                              }
                            />

                            <ModalTextButtonForComponent
                              title="View Warranty Info"
                              startIcon={<FaCertificate />}
                              onClick={() =>
                                handleOpenForm(
                                  `Warranty Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "warranty_info"
                                )
                              }
                            />

                            <ModalTextButtonForComponent
                              title="Tech Specification Info"
                              startIcon={<MdPermDeviceInformation />}
                              onClick={() =>
                                handleOpenForm(
                                  `Tech Specification Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "tech_specs"
                                )
                              }
                            />

                            <ModalCommonTextButton
                              title="View Maintenance Info"
                              startIcon={<RiToolsFill />}
                              // onClick={() => handleOpenForm("Add Image")
                              to={{
                                pathname: "/maintenanceinfo",
                                aboutProps: { data: modalInfo },
                              }}
                            />

                            <ModalTextButtonForComponent
                              title="View Rating Info"
                              startIcon={<AiFillStar />}
                              onClick={() =>
                                handleOpenForm(
                                  `Rating Info - ${modalInfo?.manufacturer} ${modalInfo?.model_name}`,
                                  "rating_info"
                                )
                              }
                            />
                            <ModalCommonTextButton
                              title="View Images"
                              startIcon={<FaImage />}
                              to={{
                                pathname: "/viewimages",
                                aboutProps: { data: modalInfo },
                              }}
                            />

                            <ModalCommonTextButton
                              title="View Color Variants"
                              startIcon={<MdFormatColorFill />}
                              to={{
                                pathname: "/viewcolorvariant",
                                aboutProps: { data: modalInfo },
                              }}
                            />

                            <ModalTextButtonForComponent
                              title="View/Edit Petrol Equivalent"
                              startIcon={<MdLocalGasStation />}
                              onClick={() =>
                                handleOpenForm(
                                  `Petrol Equivalent`,
                                  "petrol_equivalent"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

import { Button, makeStyles, withStyles, IconButton } from '@material-ui/core'
import { Link } from 'react-router-dom'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import SearchIcon from '@material-ui/icons/Search'
import CachedIcon from '@material-ui/icons/Cached'
import UpdateIcon from '@material-ui/icons/Update'
import CloseIcon from '@material-ui/icons/Close'
import CreateIcon from '@material-ui/icons/Create'
import BackspaceIcon from '@material-ui/icons/Backspace'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SwapHorizontalCircleTwoToneIcon from '@material-ui/icons/SwapHorizontalCircleTwoTone'
import Publish from '@material-ui/icons/Publish'

import { Fab } from '@material-ui/core'

import { Colors } from '../Styles/Colors/Colors'
import { Fonts } from '../Styles/Colors/Font'

export const TableNonLinkTextButton = ({
  disabled,
  onClick,
  title,

  startIcon,
}) => {
  return (
    <TableCommonTextBtn2
      disabled={disabled}
      startIcon={startIcon}
      onClick={onClick}
      variant="text"
    >
      {title}
    </TableCommonTextBtn2>
  )
}

const TableCommonTextBtn2 = withStyles((theme) => ({
  root: {
    // margin: `0 10px`,
    color: Colors.blue,
    fontSize: 13,
    textTransform: 'capitalize',
    fontFamily: Fonts.Lato,
    fontWeight: 'bold',
    padding: `0 14px`,
    height: 28,
    borderRadius: 0,
    backgroundColor: Colors.white,

    // "&:hover": {
    //   color: Colors.black,
    //   // backgroundColor: Colors.buttonBgHover,
    // },
  },
}))(Button)

export const TableCommonTextButton = ({
  disabled,
  onClick,
  title,
  to,
  startIcon,
}) => {
  return (
    <TableCommonTextBtn
      disabled={disabled}
      component={Link}
      to={to}
      startIcon={startIcon}
      onClick={onClick}
      variant="text"
    >
      {title}
    </TableCommonTextBtn>
  )
}

const TableCommonTextBtn = withStyles((theme) => ({
  root: {
    margin: `0 10px`,
    color: Colors.white,
    fontSize: 12,
    textTransform: 'capitalize',
    fontFamily: Fonts.Lato,
    padding: `0 14px`,
    height: 28,
    borderRadius: 0,
    backgroundColor: Colors.blue,

    '&:hover': {
      color: Colors.white,
      backgroundColor: Colors.buttonBgHover,
    },
  },
}))(Button)

export const CommonAddButton = ({ onClick, title }) => {
  return (
    <CommonAddBtn
      onClick={onClick}
      startIcon={<AddIcon style={{ fontSize: 16, marginLeft: 10 }} />}
      variant="text"
    >
      {title}
    </CommonAddBtn>
  )
}

export const CommonEditButton = ({ title, onClick }) => {
  return (
    <CommonAddBtn
      onClick={onClick}
      startIcon={<EditIcon style={{ fontSize: 16, marginLeft: 10 }} />}
      variant="text"
    >
      {title}
    </CommonAddBtn>
  )
}

export const CommonAddButton2 = ({ onClick, title }) => {
  return (
    <CommonAddBtn onClick={onClick} variant="text">
      <IconButton
        style={{
          background: Colors.buttonBgHover,
          // padding: `8px 11px`,
          marginRight: 10,
          color: Colors.white,
          minWidth: 34,
          maxWidth: 34,
          height: 34,
          padding: 0,
          borderRadius: 50,
        }}
      >
        <AddIcon style={{ fontSize: 16 }} />
      </IconButton>
      {title}
    </CommonAddBtn>
  )
}

const CommonAddBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 34,
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Lato,
    alignItems: 'center',
    padding: `0 15px 0 1px`,
    borderRadius: 30,
    backgroundColor: Colors.blue,
    color: Colors.white,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      color: Colors.white,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const ModalDeleteButton = ({ onClick }) => {
  return (
    <DeleteBtn
      startIcon={
        <DeleteIcon style={{ fontSize: 14, marginRight: -4, marginTop: 2 }} />
      }
      variant="text"
      onClick={onClick}
    >
      Delete
    </DeleteBtn>
  )
}

const DeleteBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 30,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const ModalUpdateButton = ({ disabled, title, onClick, to }) => {
  return (
    <ModalUpdateBtn
      disabled={disabled}
      startIcon={
        <EditIcon style={{ fontSize: 14, marginRight: -4, marginTop: 1 }} />
      }
      onClick={onClick}
      variant="text"
    >
      {title ? title : 'Edit'}
    </ModalUpdateBtn>
  )
}

const ModalUpdateBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)


/* <<<===================== COM ===========>>>>  */

export const ModalPublishButton = ({ disabled, title, onClick, to }) => {
  return (
    <ModalPublishBtn
      disabled={disabled}
      startIcon={
        <Publish style={{ fontSize: 14, marginRight: -4, marginTop: 1 }} />
      }
      onClick={onClick}
      variant="text"
    >
      {title ? title : 'Publish'}
    </ModalPublishBtn>
  )
}

const ModalPublishBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)


/* <<<===================== COM ===========>>>>  */

export const ModalCopyButton = ({ disabled, title, onClick, to }) => {
  return (
    <ModalCopyBtn
      disabled={disabled}
      startIcon={
        <SwapHorizontalCircleTwoToneIcon style={{ fontSize: 14, marginRight: -4, marginTop: 1 }} />
      }
      onClick={onClick}
      variant="text"
    >
      {title ? title : 'Copy From Main Entry'}
    </ModalCopyBtn>
  )
}

const ModalCopyBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const ModalCommonTextButton = ({
  disabled,
  onClick,
  title,
  to,
  startIcon,
}) => {
  return (
    <ModalCommonTextBtn
      disabled={disabled}
      component={Link}
      to={to}
      startIcon={startIcon}
      onClick={onClick}
      variant="text"
    >
      {title}
    </ModalCommonTextBtn>
  )
}

const ModalCommonTextBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)

const ModalTextBtnForComponent = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Ubuntu,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)

export const ModalTextButtonForComponent = ({
  disabled,
  onClick,
  title,
  startIcon,
}) => {
  return (
    <ModalTextBtnForComponent
      disabled={disabled}
      startIcon={startIcon}
      onClick={onClick}
    >
      {title}
    </ModalTextBtnForComponent>
  )
}

export const NonLinkTextButton = ({
  disabled,
  onClick,
  title,

  startIcon,
}) => {
  return (
    <ModalCommonTextBtn
      disabled={disabled}
      startIcon={startIcon}
      onClick={onClick}
      variant="text"
    >
      {title}
    </ModalCommonTextBtn>
  )
}

/* <<<===================== COM ===========>>>>  */
export const CommonButton = ({ onClick, to, title, startIcon, disabled }) => {
  return (
    <CommonBtn
      component={Link}
      to={to}
      startIcon={startIcon}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
    >
      {title}
    </CommonBtn>
  )
}

const CommonBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 12px`,
    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const BackButton = ({ onClick, to }) => {
  return (
    <BackBtn
      component={Link}
      to={to}
      startIcon={
        <BackspaceIcon
          style={{ fontSize: 14, marginRight: -2, marginTop: 2 }}
        />
      }
      variant="contained"
      onClick={onClick}
    >
      Back
    </BackBtn>
  )
}

export const BackBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 12px`,
    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const ModalCommonButton = ({ onClick, to, title, startIcon }) => {
  return (
    <ModalCommonBtn
      component={Link}
      to={to}
      startIcon={startIcon}
      variant="contained"
      onClick={onClick}
    >
      {title}
    </ModalCommonBtn>
  )
}

const ModalCommonBtn = withStyles((theme) => ({
  root: {
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 12px`,
    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const AddButton = ({ onClick, title }) => {
  return (
    <AddBtn
      onClick={onClick}
      startIcon={<AddIcon style={{ fontSize: 18, marginRight: -4 }} />}
      variant="contained"
    >
      {title}
    </AddBtn>
  )
}

const AddBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 14px`,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const SearchButton = ({ onClick }) => {
  return (
    <SearchBtn
      onClick={onClick}
      startIcon={
        <SearchIcon style={{ fontSize: 14, marginRight: -6, marginTop: 2 }} />
      }
      variant="contained"
    >
      Search
    </SearchBtn>
  )
}

const SearchBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Lato,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 14px`,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const RefreshButton = ({ onClick, to }) => {
  return (
    <RefreshBtn
      onClick={onClick}
      startIcon={
        <CachedIcon style={{ fontSize: 14, marginRight: -4, marginTop: 2 }} />
      }
      variant="text"
    >
      Refresh
    </RefreshBtn>
  )
}

const RefreshBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Lato,
    padding: `0 14px`,
    height: 34,
    borderRadius: 0,

    '&:hover': {
      color: Colors.darkBlue,
      backgroundColor: Colors.white,
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const CreateButton = ({ onClick, disabled }) => {
  return (
    <CreateBtn
      disabled={disabled}
      startIcon={
        <CreateIcon
          style={{
            fontSize: 14,
            marginRight: -4,
            marginTop: 2,
          }}
        />
      }
      variant="contained"
      onClick={onClick}
    >
      Create
    </CreateBtn>
  )
}

const CreateBtn = withStyles((theme) => ({
  root: {
    height: 32,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    padding: `0 15px 0 15px`,
    borderRadius: 30,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const UpdateButton = ({ onClick, disabled, title }) => {
  return (
    <UpdateBtn
      disabled={disabled}
      startIcon={<UpdateIcon style={{ fontSize: 14, marginRight: 0 }} />}
      variant="contained"
      onClick={onClick}
    >
      {title ? title : 'Update'}
    </UpdateBtn>
  )
}

const UpdateBtn = withStyles((theme) => ({
  root: {
    height: 32,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 30,
    padding: `0 15px`,
    marginTop: 20,
    marginBottom: 20,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const CloseButton = ({ onClick, to }) => {
  return (
    <CloseBtn
      component={Link}
      to={to}
      startIcon={<CloseIcon style={{ fontSize: 14, marginRight: 0 }} />}
      variant="contained"
      onClick={onClick}
    >
      Close
    </CloseBtn>
  )
}

const CloseBtn = withStyles((theme) => ({
  root: {
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 14px`,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const ResetButton = ({ onClick }) => {
  return (
    <ResetBtn
      startIcon={
        <CachedIcon style={{ fontSize: 14, marginRight: -4, marginTop: 2 }} />
      }
      variant="text"
      onClick={onClick}
    >
      Reset
    </ResetBtn>
  )
}

const ResetBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    color: Colors.blue,
    fontSize: 14,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontFamily: Fonts.Lato,
    padding: `0 14px`,
    height: 34,
    borderRadius: 30,

    '&:hover': {
      color: Colors.darkBlue,
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const PrevButton = ({ onClick }) => {
  return (
    <PrevBtn
   
      onClick={onClick}
      startIcon={<ArrowLeftIcon style={{ fontSize: 28, marginRight: -12 }} />}
      variant="text"
    >
      Previous
    </PrevBtn>
  )
}

const PrevBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    // height: 34,
    color: Colors.blue,
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: 0,
    padding: `0 12px`,
    fontWeight: 'bold',
    height: 34,
    alignItems: 'center',
    fontFamily: Fonts.Lato,
    '&:hover': {
      // backgroundColor: Colors.white,
      color: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const NextButton = ({ onClick}) => {
  return (
    <NextBtn
      onClick={onClick}
      endIcon={<ArrowRightIcon style={{ fontSize: 28, marginLeft: -12 }} />}
      variant="text"
    >
      Next
    </NextBtn>
  )
}

const NextBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    // height: 34,
    color: Colors.blue,
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'capitalize',
    borderRadius: 0,
    padding: `0 12px`,
    fontWeight: 'bold',
    height: 34,
    alignItems: 'center',
    fontFamily: Fonts.Lato,
    '&:hover': {
      // backgroundColor: Colors.white,
      color: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const CommonTextButton = ({ onClick, to, title, startIcon }) => {
  return (
    <TextBtn
      startIcon={startIcon}
      component={Link}
      to={to}
      variant="text"
      onClick={onClick}
    >
      {title}
    </TextBtn>
  )
}

const TextBtn = withStyles((theme) => ({
  root: {
    // height: 34,
    color: Colors.blue,
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    borderRadius: 0,
    fontWeight: 'bold',
    // backgroundColor: Colors.white,
    height: 34,
    padding: 0,
    margin: `0 10px`,
    '&:hover': {
      backgroundColor: Colors.white,
      color: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const UploadButton = ({ onClick, title, disabled }) => {
  return (
    <UploadBtn
      disabled={disabled}
      startIcon={<CloudUploadIcon />}
      onClick={onClick}
      variant="contained"
    >
      {title}
    </UploadBtn>
  )
}

const UploadBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 34,
    color: Colors.white,
    fontSize: 14,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 12px`,
    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const SearchBox = ({ onChange, value }) => {
  const styles = searchStyle()
  return (
    <div className={styles.search}>
      <SearchIcon className={styles.icon} />
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder="Search Value"
        className={styles.searchInputDiv}
      />
      {/* <Box borderBottom={1} /> */}
    </div>
  )
}

export const searchStyle = makeStyles((theme) => ({
  searchInput: {
    width: '100%',
    padding: theme.spacing(1, 1, 1, 0),
  },

  noBorder: {
    outline: 'none',
    '&hover': {
      border: 'none',
    },
  },

  resize: {
    fontSize: 13,
    paddingBottom: 10,
    paddingTop: 10,
  },

  search: {
    width: 180,
    marginRight: 5,
    borderBottom: `1px solid ${Colors.gray}`,
    backgroundColor: Colors.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
  },
  icon: {
    fontSize: 14,
    position: 'absolute',
    paddingLeft: 4,
  },
  searchInputDiv: {
    border: 'none',
    color: Colors.black,
    fontSize: 13,
    height: 34,
    paddingLeft: 20,
    width: '100%',
    boxSizing: 'border-box',
    fontFamily: Fonts.Lato,
  },
}))

/* <<<===================== COM ===========>>>>  */

export const SelectButton = ({ onClick, title }) => {
  return (
    <SelectBtn variant="contained" onClick={onClick}>
      {title}
    </SelectBtn>
  )
}

const SelectBtn = withStyles((theme) => ({
  root: {
    marginLeft: 15,
    height: 30,
    minWidth: 52,
    maxWidth: 60,
    color: Colors.white,
    fontSize: 12,
    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,
    borderRadius: 0,
    alignItems: 'center',
    padding: `0 4px`,
    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
  },
}))(Button)

/* <<<===================== COM ===========>>>>  */

export const CommonFilledButton = ({
  disabled,
  onClick,
  title,
  startIcon,
}) => {
  return (
    <CommonFilledBtn
      disabled={disabled}
      startIcon={startIcon}
      variant="contained"
      onClick={onClick}
    >
      {title}
    </CommonFilledBtn>
  )
}

const CommonFilledBtn = withStyles((theme) => ({
  root: {
    marginRight: 15,
    // height: 34,
    color: Colors.white,
    fontSize: 14,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    borderRadius: 0,
    padding: `0 12px`,
    backgroundColor: Colors.blue,
    height: 32,
    border: `1px solid ${Colors.btnBorderBlue}`,

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
    '&:disabled': {
      border: 'none',
    },
  },
}))(Button)

export const FabButton = ({ onClick, icon }) => {
  return (
    <FabBtn onClick={onClick} size="small" color="secondary" aria-label="add">
      {icon}
    </FabBtn>
  )
}

const FabBtn = withStyles((theme) => ({
  root: {
    width: 26,
    minHeight: 20,
    maxHeight: 26,
    borderRadius: 50,
    marginLeft: 15,
    marginTop: 6,

    color: Colors.white,

    backgroundColor: Colors.buttonBg,
    boxShadow: 'none',
    textTransform: 'capitalize',
    fontFamily: Fonts.Ubuntu,
    border: `1px solid ${Colors.btnBorderBlue}`,

    alignItems: 'center',

    '&:hover': {
      backgroundColor: Colors.buttonBgHover,
      boxShadow: 'none',
    },
    alignSelf: 'center',
  },
}))(Fab)

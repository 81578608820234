import gql from "graphql-tag";


export const FETCH_MAINTENANCE_DETAILS = gql`
  query  getMaintenanceGeneral($userEmail: String!, $token : String!){
    getMaintenanceGeneral(userEmail: $userEmail, token : $token){
        id
        manufacturer
        ev_service
        ev_delivery
        petrol_service
        petrol_delivery
    }
  }
`;


export const CREATE_MAINTENANCE_INFO = gql`
  mutation inputNewMaintenanceGeneral(
    $petrolDelivery: Float
    $petrolService: Float
    $evDelivery: Float
    $evService: Float
    $manufacturer: String!
    $userEmail: String!
    $token : String!
  ) {
    inputNewMaintenanceGeneral(
      petrolDelivery : $petrolDelivery
      petrolService : $petrolService
      evDelivery : $evDelivery
      evService : $evService
      manufacturer : $manufacturer
      userEmail : $userEmail
      token : $token
    ) {
      status
      statusBool
      error
    }
  }
`;

export const UPDATE_MAINTENANCE_INFO = gql`
  mutation updateMaintenanceGeneral(
    $petrolDelivery: Float
    $petrolService: Float
    $evDelivery: Float
    $evService: Float
    $userEmail: String!
    $token : String!
    $qId: Float!
    ) {
      updateMaintenanceGeneral(
        petrolDelivery : $petrolDelivery
        petrolService : $petrolService
        evDelivery : $evDelivery
        evService : $evService
        userEmail : $userEmail
        token : $token
        qId : $qId
    )
  }
`;

export const DELETE_MAINTENANCE_INFO = gql`
  mutation deleteMaintenanceGeneral( 
    $qId: Float!
    $userEmail: String!
    $token : String!
    ) {
      deleteMaintenanceGeneral(
      qId:$qId
      userEmail:$userEmail
      token : $token
    )
  }
`;
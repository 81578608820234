import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import { CStyles, formStyle } from "../../Styles/CommonStyles";

import { StatusText } from "../../Component/Text";
import {
  ModalCopyButton,
  CommonFilledButton,
  UploadButton,
} from "../../Component/ButtonComponent";
// import Loading from "../../Component/Loading";

import {
  FETCH_ALL_IMAGES,
  GET_SIGNED_URL,
  INSERT_URL,
  DELETE_COPY_IMAGES,
  UPDATE_IMG_STATUS,
  COPY_IMAGES_FROM_MAIN,
} from "./api";

import axios from "axios";
import { MdDelete } from "react-icons/md";

import { FiEye, FiEyeOff } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import { EmailContext } from "../../Context/EmailContext";
import { MenuItem, Select } from "@material-ui/core";
export default function ViewVarImages(props) {
  const { email, token } = useContext(EmailContext);

  const lid = localStorage.getItem("vidis");

  const updateValues = props.location.aboutProps
    ? props.location.aboutProps
    : null;

  const [upLoading, setUpLoading] = useState(false);
  const [upLoaded, setUpLoaded] = useState(false);

  const [uploadImageType, setUploadImageType] = useState("secondary");

  const NoPhoto = "Photo";

  const imageTypes = ["primary", "secondary", "technology", "interior"];

  const [callTheImagesApi, { loading, data, error }] =
    useLazyQuery(FETCH_ALL_IMAGES);

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.data?.vid;
      callTheImagesApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const formClass = formStyle();
  const classes = CStyles();

  const [values, setValues] = useState({
    fileName: "",
    fileType: "",
    userEmail: email,
    token : token,
    modelVariant: updateValues ? updateValues.data.model_variant : "",
    modelName: updateValues ? updateValues.data.model_name : "",
  });

  const [fileDetail, setFileDetail] = useState(null);

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  /* =====================      Get Event Images     =====================*/

  /* <<<=========     PRODUCT IMAGE UPLOAD  useMutation   ===========>>>  */

  const [insertImageDetails] = useMutation(INSERT_URL, {
    update(_, result) {
      setUpLoaded(true);
      setUpLoading(false);
      setOutcome(result?.data?.inputImageForVehicle?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
  });

  const [copyImagesFromMain] = useMutation(COPY_IMAGES_FROM_MAIN, {
    update(_, result) {
      setUpLoading(false);
      setOutcome2(result?.data?.copyImages);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
  });

  const [deleteImageStatus] = useMutation(DELETE_COPY_IMAGES, {
    update(_, result) {
      setUpLoaded(true);
      setUpLoading(false);
      if (result?.data?.deleteCopyImages) {
        setOutcome("Successfully Deleted");
      }
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
  });

  const [imgStatusChange] = useMutation(UPDATE_IMG_STATUS, {
    update(_, result) {
      setUpLoading(false);
      if (result?.data?.updateImageLink) {
        setOutcome("Successfully Updated");
      }
    },
    onError(err) {
      setErrors("Something went wrong!");
    },
    variables: values,
    refetchQueries: [
      {
        query: FETCH_ALL_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
    awaitRefetchQueries: true,
  });
  /* =====================      Update Event Image Status    =====================*/

  useEffect(() => {
    setOutcome("");
    setErrors("");
    setUpLoaded(false);
    setFileDetail(null);
  }, [data]);

  const uploadToS3 = async (signedRequest, url) => {
    const options = {
      headers: {
        "Content-Type": values.fileType,
      },
    };
    const returned = await axios.put(signedRequest, fileDetail.file, options);
    return returned;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      setValues({
        ...values,
        fileName: acceptedFiles[0].name,
        fileType: acceptedFiles[0].type,
      });

      setFileDetail({
        file: acceptedFiles[0],
      });
    },
    [values]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [getUrl] = useMutation(GET_SIGNED_URL, {
    update(_, result) {
      setUpLoading(result);
    },
    onError(err) {
      setErrors(err);
    },
    variables: values,
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setUpLoaded(false);

    const response = await getUrl();

    const { signedRequest, url } = response?.data?.signedUrlForImage;

    const uploatStatus = await uploadToS3(signedRequest, url);

    if (uploatStatus.status === 200) {
      await insertImageDetails({
        variables: {
          vid: updateValues?.data?.vid,
          userEmail: email,
          token : token,
          imageLink: url,
          imageStatus: uploadImageType,
        },
      });
    }
  };

  /* =====================      Update Event Image Status    =====================*/

  const statuChangeFunc = async (
    id,
    vId,
    imgstatus,
    existingImageStatus,
    imglink
  ) => {
    setUpLoading(false);
    if (imgstatus !== existingImageStatus) {
      imgStatusChange({
        variables: {
          imageStatus: imgstatus,
          imageLink: imglink,
          vid: vId,
          userEmail: email,
          token : token,
          qId: id,
        },
      });
    }
  };

  const deleteFunc = async (id) => {
    deleteImageStatus({
      variables: {
        userEmail: email,
        token : token,
        qId: id,
      },
    });
  };

  const onCopy = async () => {
    copyImagesFromMain({
      variables: {
        userEmail: email,
        token : token,
        qId: lid,
        insertId: updateValues?.data?.vid,
      },
    });
  };

  const onChange2 = (event) => {
    setUploadImageType(event.target.value);
  };

  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Image Details</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  /* <<<=========    CONTENT   ===========>>>  */
  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <div>
          <div className={formClass.imgCardDiv}>
            <div className={formClass.imgDiv}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {fileDetail ? (
                  <p className={formClass.imgInputText}>
                    File {values.filename} Selected
                  </p>
                ) : (
                  <p className={formClass.imgInputText}>
                    click to select files
                  </p>
                )}
              </div>
            </div>

            {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
            {outcome2 && (
              <StatusText title={"Copied Successfully"} fColor={Colors.black} />
            )}
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText
                title={"Something Went Wrong Cannot Be Created"}
                fColor={Colors.black}
              />
            )}

            {upLoading ? (
              <StatusText title={"Uploading..."} fColor={Colors.black} />
            ) : null}

            <UploadButton
              title={upLoaded ? "Uploaded" : "Upload"}
              disabled={fileDetail ? false : true}
              onClick={onSubmit}
            />
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              name="uploadImageType"
              value={uploadImageType}
              style={{ paddingTop: "30px", borderWidth: "2px" }}
              onChange={(text) => onChange2(text)}
              label="Select Search"
            >
              <MenuItem value={"secondary"}>Secondary</MenuItem>
              <MenuItem value={"interior"}>Interior</MenuItem>
              <MenuItem value={"technology"}>Technology</MenuItem>
            </Select>

            <ModalCopyButton
              title="Click Link To Copy Images From Main Variant"
              onClick={onCopy}
            />
          </div>

          <div className={classes.bodyDiv}>
            {loading ? (
              <StatusText title={"Loading "} fColor={Colors.black} />
            ) : (
              <>
                {data && data?.getAllImagesOnId?.length ? (
                  <div className={classes.proImgBlock}>
                    {data?.getAllImagesOnId.map((obj, index) => {
                      return (
                        <div className={classes.proImageDiv} key={index}>
                          <img
                            src={obj.image_link}
                            alt={`${NoPhoto} is not available`}
                            className={classes.proImg}
                          />
                          <div className={classes.proImageTextDivx}>
                            {imageTypes.map((obj2) => (
                              <div
                                style={{
                                  paddingTop: "3px",
                                  paddingBottom: "3px",
                                }}
                              >
                                <CommonFilledButton
                                  title={obj2}
                                  startIcon={
                                    obj.image_status === obj2 ? (
                                      <FiEye
                                        style={{
                                          marginBottom: -3,
                                          marginLeft: 6,
                                        }}
                                      />
                                    ) : (
                                      <FiEyeOff
                                        style={{
                                          marginBottom: -3,
                                          marginLeft: 6,
                                        }}
                                      />
                                    )
                                  }
                                  onClick={() =>
                                    statuChangeFunc(
                                      obj.id,
                                      obj.vid,
                                      obj2,
                                      obj.image_status,
                                      obj.image_link
                                    )
                                  }
                                />
                              </div>
                            ))}
                            <div
                              style={{
                                paddingTop: "3px",
                                paddingBottom: "3px",
                              }}
                            >
                              <CommonFilledButton
                                title={"Del"}
                                startIcon={
                                  <MdDelete
                                    style={{ marginBottom: -3, marginLeft: 6 }}
                                  />
                                }
                                onClick={() =>
                                  deleteFunc(
                                    obj.id,
                                    obj.image_status,
                                    obj.image_link
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <StatusText title={"No Data Exist"} fColor={Colors.black} />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Divider, Typography } from "@material-ui/core";

import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  ModalCopyButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import {
  FETCH_BATTERY_DETAILS,
  CREATE_BATTERY_INFO,
  UPDATE_BATTERY_INFO,
} from "./api";
import { FETCH_ALL_VARIANTS_DATA } from "../Variants/api";

import { EmailContext } from "../../Context/EmailContext";

export default function BatteryInformation({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const lid = localStorage.getItem("vidis");
  const [updateStatus, setUpdateStatus] = useState(false);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token : token,
    batteryCapacity: "",
    batteryUseable: "",
    chargingEfficiency: "",
    maxChargingPower: "",
    maxChargingTime: "",
    maxChargingSpeed: "",
    chargePort: "",
    chargePortLocation: "",
    fastChargePort: "",
    fastChargePortLocation: "",
    maxFastChargingPower: "",
    maxFastChargingTime: "",
    maxFastChargingSpeed: "",
    bidirectionalCharging: "",
    bidirectionalPower: "",
    batteryChemistry: "",
  });

  /* <<<=========      useMutation   ===========>>>  */

  const [calltheBatteryInfoAPI, { loading, data, error }] = useLazyQuery(
    FETCH_BATTERY_DETAILS
  );

  const [createBatteryInfo] = useMutation(CREATE_BATTERY_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputBatteryAndChargingData?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_BATTERY_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [updateBatteryInfo] = useMutation(UPDATE_BATTERY_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updateBatteryAndChargingData);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_BATTERY_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  /* <<<=========   ===========>>>  */

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      calltheBatteryInfoAPI({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.getBatteryAndCharging) {
      const { getBatteryAndCharging } = data;
      setValues({
        ...values,

        batteryCapacity: getBatteryAndCharging?.battery_capacity,
        batteryUseable: getBatteryAndCharging?.battery_useable,
        chargingEfficiency: getBatteryAndCharging?.charging_efficiency,
        maxChargingPower: getBatteryAndCharging?.max_charging_power,
        maxChargingTime: getBatteryAndCharging?.max_charging_time,
        maxChargingSpeed: getBatteryAndCharging?.max_charging_speed,
        chargePort: getBatteryAndCharging?.charge_port,
        chargePortLocation: getBatteryAndCharging?.charge_port_location,
        fastChargePort: getBatteryAndCharging?.fast_charge_port,
        fastChargePortLocation:
          getBatteryAndCharging?.fast_charge_port_location,
        maxFastChargingPower: getBatteryAndCharging?.max_fast_charging_power,
        maxFastChargingTime: getBatteryAndCharging?.max_fast_charging_time,
        maxFastChargingSpeed: getBatteryAndCharging?.max_fast_charging_speed,
        country: getBatteryAndCharging?.country,
        bidirectionalCharging: getBatteryAndCharging?.bi_directional_charging,
        bidirectionalPower: getBatteryAndCharging?.bi_directional_power,
        batteryChemistry: getBatteryAndCharging?.battery_chemistry,
      });

      if (lid === getBatteryAndCharging?.vid) {
        setUpdateStatus(false);
      }
      if (lid !== getBatteryAndCharging?.vid) {
        setUpdateStatus(true);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  async function validator() {
    const parsedValuesAre = {
      vid: updateValues ? updateValues?.vid : "",
      userEmail: email,
      token : token,
      batteryCapacity: isNaN(parseFloat(values.batteryCapacity))
        ? 0
        : parseFloat(values.batteryCapacity),
      batteryUseable: isNaN(parseFloat(values.batteryUseable))
        ? 0
        : parseFloat(values.batteryUseable),
      chargingEfficiency: isNaN(parseFloat(values.chargingEfficiency))
        ? 0
        : parseFloat(values.chargingEfficiency),
      maxChargingPower: isNaN(parseFloat(values.maxChargingPower))
        ? 0
        : parseFloat(values.maxChargingPower),
      maxChargingTime: isNaN(parseFloat(values.maxChargingTime))
        ? 0
        : parseFloat(values.maxChargingTime),
      maxChargingSpeed: isNaN(parseFloat(values.maxChargingSpeed))
        ? 0
        : parseFloat(values.maxChargingSpeed),
      maxFastChargingPower: isNaN(parseFloat(values.maxFastChargingPower))
        ? 0
        : parseFloat(values.maxFastChargingPower),
      maxFastChargingTime: isNaN(parseFloat(values.maxFastChargingTime))
        ? 0
        : parseFloat(values.maxFastChargingTime),
      maxFastChargingSpeed: isNaN(parseFloat(values.maxFastChargingSpeed))
        ? 0
        : parseFloat(values.maxFastChargingSpeed),
      chargePort: values.chargePort,
      chargePortLocation: values.chargePortLocation,
      fastChargePort: values.fastChargePort,
      fastChargePortLocation: values.fastChargePortLocation,
      bidirectionalCharging: values.bidirectionalCharging,
      bidirectionalPower: isNaN(parseFloat(values.bidirectionalPower))
        ? 0
        : parseFloat(values.bidirectionalPower),
      batteryChemistry: values.batteryChemistry,
    };
    return parsedValuesAre;
  }
  async function callCreateAPI() {
    setOutcome("");
    setErrors("");
    const parsedValuesAre = await validator();

    await createBatteryInfo({
      variables: parsedValuesAre,
    });
  }

  async function callEditAPI() {
    setOutcome({});
    setErrors({});
    // updateData();

    const validatedValues = await validator();

    await updateBatteryInfo({
      variables: validatedValues,
    });
  }

  function callTheMainApi() {
    calltheBatteryInfoAPI({ variables: { qId: lid } });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>loading...</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={mStyles.floatRight}>
                    <ModalCopyButton onClick={() => callTheMainApi()} />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Battery Capacity :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Battery Capacity :"
                      name="batteryCapacity"
                      value={values.batteryCapacity}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Battery Useable :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Battery Useable :"
                      name="batteryUseable"
                      value={values.batteryUseable}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Charging Efficiency :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Charging Efficiency :"
                      name="chargingEfficiency"
                      value={values.chargingEfficiency}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Charging Power :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max  Charging Power :"
                      name="maxChargingPower"
                      value={values.maxChargingPower}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Charging Time :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max  Charging Time :"
                      name="maxChargingTime"
                      value={values.maxChargingTime}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Charging Speed :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max  Charging Speed :"
                      name="maxChargingSpeed"
                      value={values.maxChargingSpeed}
                      onChange={onChange}
                    />
                  </div>
                  
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Charge Port :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Charge Port :"
                      name="chargePort"
                      value={values.chargePort}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Charge Port Location :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Charge Port Location :"
                      name="chargePortLocation"
                      value={values.chargePortLocation}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Fast Charge Port :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Fast Charge Port :"
                      name="fastChargePort"
                      value={values.fastChargePort}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Fast Charge Port Location :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Fast Charge Port Location :"
                      name="fastChargePortLocation"
                      value={values.fastChargePortLocation}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Fast Charging Power :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max Fast Charging Power :"
                      name="maxFastChargingPower"
                      value={values.maxFastChargingPower}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Fast Charging Time :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max Fast Charging Time :"
                      name="maxFastChargingTime"
                      value={values.maxFastChargingTime}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Fast Charging Speed :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max Fast Charging Speed :"
                      name="maxFastChargingSpeed"
                      value={values.maxFastChargingSpeed}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Bi Directional Charging :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Bi Directional Charging :"
                      name="bidirectionalCharging"
                      value={values.bidirectionalCharging}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Bi Directional Power :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Bi Directional Power :"
                      name="bidirectionalPower"
                      value={values.bidirectionalPower}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Battery Chemistry :
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Battery Chemistry :"
                      name="batteryChemistry"
                      value={values.batteryChemistry}
                      onChange={onChange}
                    />
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />
                  {lid === values.vid ? (
                    <>It Can Be Edited Only On The Main Table</>
                  ) : (
                    <>
                      {updateStatus ? (
                        <CommonEditButton
                          title={"Update"}
                          onClick={() => callEditAPI()}
                        />
                      ) : (
                        <CommonAddButton
                          title={"Create"}
                          onClick={() => callCreateAPI()}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Vid : </p>
                    <p className={mStyles.textRight}>{values.vid} </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Battery Capacity : </p>
                    <p className={mStyles.textRight}>
                      {values.batteryCapacity}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Battery Useable : </p>
                    <p className={mStyles.textRight}>
                      {values.batteryUseable}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Charging Efficiency : </p>
                    <p className={mStyles.textRight}>
                      {values.chargingEfficiency}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Max Charging Power : </p>
                    <p className={mStyles.textRight}>
                      {values.maxChargingPower}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Max Charging Time : </p>
                    <p className={mStyles.textRight}>
                      {values.maxChargingTime}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Max Charging Speed : </p>
                    <p className={mStyles.textRight}>
                      {values.maxChargingSpeed}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                 
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Charge Port : </p>
                    <p className={mStyles.textRight}>{values.chargePort} </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Charge Port Location : </p>
                    <p className={mStyles.textRight}>
                      {values.chargePortLocation}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Fast Charge Port : </p>
                    <p className={mStyles.textRight}>
                      {values.fastChargePort}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      {" "}
                      Fast Charge Port Location :{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {values.fastChargePortLocation}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      {" "}
                      Max Fast Charging Power :{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {values.maxFastChargingPower}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      {" "}
                      Max Fast Charging Time :{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {values.maxFastChargingTime}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      {" "}
                      Max Fast Charging Speed :{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {values.maxFastChargingSpeed}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      {" "}
                      Bi Directional Charging :{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {values.bidirectionalCharging}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />

                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Bi Directional Power: </p>
                    <p className={mStyles.textRight}>
                      {values.bidirectionalPower}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />

                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}> Battery Chemistry : </p>
                    <p className={mStyles.textRight}>
                      {values.batteryChemistry}{" "}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />

                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Colors } from '../../Styles/Colors/Colors'

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from '../../Styles/CommonStyles'

import { Divider, Slide } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import { EmailContext } from '../../Context/EmailContext'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from '../../Component/Text'
import { ModalUpdateButton, CommonAddButton, ModalDeleteButton } from '../../Component/ButtonComponent'

import Loading from '../../Component/Loading'

import { FETCH_SCORE_DATA, DELETE_SCORE_DATA } from './api'

import AddScore from './AddScore'
import EditScore from './EditScore'

export default function ScoreCard() {
  const { email, token  } = useContext(EmailContext)

  const classes = CStyles()
  const mStyles = modalStyle()

  const [open, setOpen] = useState(false);

  // const [edit, setEdit] = useState(false)

  const [modalInfo, setModalInfo] = useState(null)
  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [formModal, setFormModal] = useState({
    open: false,
    title: '',
    check: '',
  })
  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error } = useQuery(FETCH_SCORE_DATA, {
    variables: { userEmail: email, token:token  },
  })

  const [deleteScoreData] = useMutation(DELETE_SCORE_DATA, {
    update(_, result) {
      setOutcome(result?.data?.deleteScoreTableDetails)
    },
    onError(err) {
      setErrors(true)
    },
    refetchQueries: [
      {
        query: FETCH_SCORE_DATA,
        variables: { userEmail: email, token:token  },
      },
    ],
  })

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const callDelete = (id) => {
    deleteScoreData({
      variables: {
        qId: parseInt(id),
        userEmail: email,
        token:token,
      },
    })
  }

  const callEdit = (objIs) => {
    setOpen(false)
    setModalInfo(objIs)
    handleOpenForm('Edit Score Card', 'editScore')
  }

  const handleOpenForm = (title, check) => {
    setOutcome(false)
    setErrors(false)
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    })
  }


  // const formatData = async (obj) => {

  //   const evRateValue = JSON.parse(obj.ev_rate_data)
  //   const evThresholdData = (obj.ev_threshold_data) ? JSON.parse(obj.ev_threshold_data) : {}
  //   const petrolRateData = JSON.parse(obj.petrol_rate_data)
  //   const petrolThresholdData = (obj.petrol_threshold_data) ? JSON.parse(obj.petrol_threshold_data) : {}


  //   obj['evRate'] = evRateValue
  //   obj['evThreshold'] = evThresholdData
  //   obj['petrolRate'] = petrolRateData
  //   obj['petrolThreshold'] = petrolThresholdData

  //   obj['noRateValue'] = (evRateValue!==null) ? Object.keys(evRateValue).length : 0;
  //   obj['noEvThreshold'] = (evThresholdData!==null) ? Object.keys(evThresholdData).length : 0;
  //   obj['noPetrolValue'] = (petrolRateData !== null) ? Object.keys(petrolRateData).length : 0;
  //   obj['noPetrolThreshold'] = (petrolThresholdData !== null) ? Object.keys(petrolThresholdData).length : 0;


  //   return obj
  // }

  const handleOpen = async (obj) => {
    // const getformattedData = await formatData(obj);
    setModalInfo(obj);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Score Data</p>
        </div>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    )
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Score Data</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.noSearchRowDiv}>
          <CommonAddButton
            title="Add New Score Card"
            onClick={() => handleOpenForm('Add Score Details', 'addScore')}
          />
        </div>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      })
                    }}
                  />
                  <div className={mStyles.formModalInnerContent}>
                    {formModal?.check === 'addScore' ? <AddScore /> : null}

                    {formModal?.check === 'editScore' ? (
                      <EditScore updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (

          <div className={classes.bodyDiv}>
            {data && data?.getAllScoreData?.scoreTable ? (
              <>
                <TableContainer
                  elevation={0}
                  className={classes.table}
                  component={Paper}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Name
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          0
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          0.5
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          1
                        </StyledTableCell>


                        <StyledTableCell align="center">
                          1.5
                        </StyledTableCell>


                        <StyledTableCell align="center">
                          2
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          2.5
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          3
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          3.5
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          4
                        </StyledTableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getAllScoreData?.scoreTable.map((obj, index) => {
                        return (
                          <StyledTableRow
                            key={index}
                            onClick={() => handleOpen(obj)}
                            hover={true}
                            className={classes.tableRow}
                          >
                            <StyledTableCell align="center">
                              {obj.name_score}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {obj.zero}
                            </StyledTableCell>
                            
                            <StyledTableCell align="center">
                              {obj.zero_five}
                            </StyledTableCell>
                        
                            <StyledTableCell align="center">
                              {obj.one}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.one_five}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.two}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.two_five}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {obj.three}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {obj.three_five}
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              {obj.four}
                            </StyledTableCell>

                          </StyledTableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <StatusText title={'No Data'} fColor={Colors.black} />
            )}

            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={mStyles.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={mStyles.paper}>
                    <div className={mStyles.formModalContent}>
                      <div className={mStyles.floatLeft}>
                        Score Details
                      </div>
                    </div>

                    <div className={mStyles.formModalInnerContent}>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>id</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.id}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Score Name</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.name_score}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>0</p>{" "}
                        <p className={mStyles.textRight}>

                          {modalInfo?.zero}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>0.5</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.zero_five}{" "}
                        </p>
                      </div>
                      
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>1</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.one}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>1.5</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.one_five}{" "}
                        </p>
                      </div>
                      
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>2</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.two}{" "}
                        </p>
                      </div>
                    
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          2.5
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.two_five}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          3
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.three}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>3.5</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.three_five}{" "}
                        </p>
                      </div>

                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>4</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.four}{" "}
                        </p>
                      </div>

                      <Divider className={mStyles.dividerLine} />

                      {outcome && outcome === true && (
                        <StatusText title={'Deleted Successfully'} fColor={Colors.black} />
                      )}

                      <ModalUpdateButton title={'Edit'} onClick={() => callEdit(modalInfo)} />

                      <ModalDeleteButton onClick={() => callDelete(modalInfo?.id)} />
                    </div>

                  </div>
                </Fade>
              </Modal>
            </div>
          </div>

        )}



        {errors && errors === true && (
          <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
        )}
      </>
    </div>
  )
}

import gql from "graphql-tag";

export const FETCH_DUTIES_AND_OTHER = gql`
  query getDutiesAndExceptions($userEmail: String!, $token : String!) {
    getDutiesAndExceptions(userEmail: $userEmail, token : $token) {
      id
      state
      ev_rate_data
      ev_threshold_data
      petrol_rate_data
      petrol_threshold_data
      emission_value
      incentive_duty_deduction
      incentive_rebate
      incentive_other
      incentive_duty_limit
      incentive_rebate_limit
      rebate_first_condition
      quota
      registration_fee
      vehicle_tax
      plate_fee
      ctp
      ev_luxury_tax
      pe_luxury_tax
      ev_lt_perc
      pe_lt_perc
    }
  }
`;

export const DELETE_DUTIES_AND_OTHER = gql`
  mutation deleteDutiesAndExemptions($qId: Float!, $userEmail: String!, $token : String!) {
    deleteDutiesAndExemptions(qId: $qId, userEmail: $userEmail, token : $token)
  }
`;

export const UPDATE_DUTIES = gql`
  mutation updateDutiesAndExeptions(
    $userEmail: String!
    $state: String!
    $token : String!
    $evRateData: String
    $evThresholdData:String
    $petrolRateData: String
    $petrolThresholdData: String
    $emissionValue: Float
    $incentiveDutyDeduction: Float
    $incentiveRebate: Float
    $incentiveDutyLimit : Float
    $incentiveRebateLimit : Float
    $rebateFirstCondition : Float
    $incentiveOther: String
    $quota: String
    $qId: Float!
    $registrationFee: Float
    $vehicleTax: Float
    $plateFee: Float
    $ctp: Float
    $evLuxuryTax: Float
    $peLuxuryTax: Float
    $evLtPerc: Float
    $peLtPerc: Float
  ) {
    updateDutiesAndExeptions(
      userEmail: $userEmail
      state: $state
      token : $token
      evRateData: $evRateData
      evThresholdData: $evThresholdData
      petrolRateData: $petrolRateData
      petrolThresholdData: $petrolThresholdData
      emissionValue: $emissionValue
      incentiveDutyDeduction: $incentiveDutyDeduction
      incentiveRebate: $incentiveRebate
      incentiveDutyLimit: $incentiveDutyLimit
      incentiveRebateLimit: $incentiveRebateLimit
      rebateFirstCondition: $rebateFirstCondition
      incentiveOther: $incentiveOther
      quota: $quota
      qId: $qId
      registrationFee:$registrationFee
      vehicleTax: $vehicleTax
      plateFee: $plateFee
      ctp: $ctp
      evLuxuryTax: $evLuxuryTax
      peLuxuryTax: $peLuxuryTax
      evLtPerc:$evLtPerc
      peLtPerc:$peLtPerc
    )
  }
`;

export const CREATE_DUTIES_OTHER_EXEMPTIONS = gql`
  mutation inputDutiesAndExemptions(
    $userEmail: String!
    $state: String!
    $token : String!
    $evRateData: String
    $evThresholdData: String
    $petrolRateData: String
    $petrolThresholdData: String
    $emissionValue: Float
    $incentiveDutyDeduction: Float
    $incentiveRebate: Float
    $incentiveDutyLimit : Float
    $incentiveRebateLimit : Float
    $rebateFirstCondition : Float
    $incentiveOther: String
    $quota: String
    $registrationFee: Float
    $vehicleTax: Float
    $plateFee: Float
    $ctp: Float
    $evLuxuryTax: Float
    $peLuxuryTax: Float
    $evLtPerc: Float
    $peLtPerc: Float
  ) {
    inputDutiesAndExemptions(
      userEmail: $userEmail
      state: $state
      token : $token
      evRateData: $evRateData
      evThresholdData: $evThresholdData
      petrolRateData: $petrolRateData
      petrolThresholdData: $petrolThresholdData
      emissionValue: $emissionValue
      incentiveDutyDeduction: $incentiveDutyDeduction
      incentiveRebate: $incentiveRebate
      incentiveOther: $incentiveOther
      incentiveDutyLimit: $incentiveDutyLimit
      incentiveRebateLimit: $incentiveRebateLimit
      rebateFirstCondition: $rebateFirstCondition
      quota: $quota
      registrationFee:$registrationFee
      vehicleTax: $vehicleTax
      plateFee: $plateFee
      ctp: $ctp
      evLuxuryTax: $evLuxuryTax
      peLuxuryTax: $peLuxuryTax
      evLtPerc:$evLtPerc
      peLtPerc:$peLtPerc
    ) {
      status
      statusBool
      error
    }
  }
`;

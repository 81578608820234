import gql from "graphql-tag";

export const LOGIN_USER = gql`
  mutation tryLogin($UserEmail: String!, $PassCode: String!) {
    tryLogin( UserEmail:$UserEmail, PassCode:$PassCode) {
          message
          emailError
          passError
          genError
          token
          name
          email
          error
    }
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from "react";
import { useMutation, useLazyQuery, useQuery } from "@apollo/react-hooks";
import { Typography, Select, MenuItem } from "@material-ui/core";

import { EmailContext } from "../../Context/EmailContext";

import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton, CommonAddButton } from "../../Component/ButtonComponent";

import {
  ADD_PETROL_EQUIVALENT,
  FETCH_PETROL_EQUIVALENT,
  FETCH_PETROL_MENU,
} from "./api";
import { FETCH_ALL_VEHICLE_DATA } from "../VehicleDetails/api";

export default function BookDemo({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    userEmail: email,
    token : token,
    evId: "",
    evName: "",
    petrolId: "",
    petrolName: "",
  });
  // const [searchColumnValue, setSearchColumnValue] = useState("None");

  const [callTheApi, { loading, data, error }] = useLazyQuery(
    FETCH_PETROL_EQUIVALENT
  );

  const {
    loading: menuLoading,
    data: menuData,
    error: menuError,
  } = useQuery(FETCH_PETROL_MENU);

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callTheApi({ variables: { qId: idIs, userEmail: email, token } });
    }
  }, [updateValues]);

  useEffect(() => {
    if (data) {
      const { getPetrolEquivalentVehilce } = data;
      setValues({
        ...values,
        evId: getPetrolEquivalentVehilce?.ev_id,
        evName: getPetrolEquivalentVehilce?.ev_name,
        petrolId: getPetrolEquivalentVehilce?.petrol_id,
        petrolName: getPetrolEquivalentVehilce?.petrol_name,
      });
    }
  }, [data]);

  /* <<<=========  useMutation   ===========>>>  */

  const [updateDemoLink] = useMutation(ADD_PETROL_EQUIVALENT, {
    update(_, result) {
      setOutcome2(result?.data?.inputPetrolEquivalent);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
    ],
  });

  /* <<<=========      API CALL   ===========>>>  */

  async function callEditAPI() {
    setOutcome2("");
    setErrors("");
    // updateData();
    console.log(updateValues);

    const dataAre = {
      evId: updateValues?.vid,
      evName: `${updateValues.manufacturer} ${updateValues.model_name} ${updateValues.model_variant}`,
      petrolId: values.petrolId,
      petrolName: values.petrolName,
      userEmail: email,
      token : token
    };
    await updateDemoLink({
      variables: dataAre,
    });
  }
  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange2 = (event) => {
    const { label, value } = event.target.value;
    setValues({
      ...values,
      petrolName: label,
      petrolId: value,
    });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (!updateValues) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  if (error && menuError) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <>
      {loading && menuLoading ? (
        <>Loading ... </>
      ) : (
        <div className={formClass.formDiv}>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={formClass.formRow}>
                    <Typography className={formClass.leftText}>
                      Petrol Equivalent
                    </Typography>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="searchColumnValue"
                      label="Select Search"
                      value={values.petrolId}
                      onChange={(text) => onChange2(text)}
                    >
                      {menuData.getPetrolEquivalentMenu.map((obj) => (
                        <MenuItem value={obj}>{obj.label}</MenuItem>
                      ))}
                    </Select>
                  </div>

                  {/* {outcome2 && Object.keys(outcome2).length !== 0 && (
                  <StatusText title={outcome2} fColor={Colors.black} />
                )} */}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />

                  <CommonAddButton
                    title={"Update"}
                    onClick={() => callEditAPI()}
                  />
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    {" "}
                    <p className={mStyles.textLeft}>
                      Petrol Equivalent Vehicle:{" "}
                    </p>
                    <p className={mStyles.textRight}>
                      {data?.getPetrolEquivalentVehilce
                        ? data.getPetrolEquivalentVehilce.petrol_name
                        : "Not Available"}
                    </p>
                  </div>
                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </div>
      )}
    </>
  );
}


import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { UpdateButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { EmailContext } from '../../Context/EmailContext'

import { UPDATE_EMISSION_CONSTANT, FETCH_EMISSION_CONSTANT } from './api'

export default function EditEmissionConstant({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [values, setValues] = useState({
    state:  updateValues ? updateValues.state: '',
    emissionValue: updateValues ? updateValues.emission_value: '',
    qId: updateValues ? updateValues.id: '',
    userEmail: email,
    token: token
  })

  const [updateEmissionDetailInfo] = useMutation(UPDATE_EMISSION_CONSTANT, {
    update(_, result) {
      setOutcome(result?.data?.updateEmissionDetails)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_EMISSION_CONSTANT,
        variables: { userEmail: email, token },
      },
    ],
  })

  function onSubmit() {
    setOutcome(false)
    setErrors(false)
    updateEmissionDetailInfo({
        variables: values
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>

       
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>State</Typography>
              <TextInputField
                id="en"
                placeholder="Enter State"
                name="state"
                value={values.state}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Emission Value</Typography>
              <TextInputField
                id="en"
                placeholder="Enter Emission Value"
                name="emissionValue"
                value={values.emissionValue}
                onChange={onChange}
              />
            </div>

            <UpdateButton title={'Update'} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={'Updated Successfully'} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
      )}
    </div>
  )
}

import gql from "graphql-tag";

export const FETCH_ALL_IMAGES = gql`
  query getAllImagesOnId($qId: String!) {
    getAllImagesOnId(qId: $qId) {
      id
      vid
      image_link
      image_status
    }
  }
`;


export const GET_SIGNED_URL = gql`
 mutation signedUrlForImage(
  $fileType: String!
  $fileName: String!
  $modelVariant: String!
  $modelName: String!
  $userEmail: String!

) {
  signedUrlForImage(
    fileType: $fileType
    fileName: $fileName
    modelVariant: $modelVariant
    modelName: $modelName
    userEmail: $userEmail
  ) {
    signedRequest
    url
    error
  }
}
`;


export const INSERT_URL = gql`
  mutation inputImageForVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $imageLink: String
    $imageStatus: String
 ) {
  inputImageForVehicle(
    userEmail: $userEmail
    token : $token
    vid: $vid
    imageLink: $imageLink
    imageStatus: $imageStatus
   ) {
    status
    statusBool
    error
    
   }
 }
 `;



export const COPY_IMAGES_FROM_MAIN = gql`
mutation copyImages(
  $userEmail: String!
  $token : String!
  $insertId: String!
  $qId: String!

) {
  copyImages(
  userEmail: $userEmail
  token : $token
  insertId: $insertId
  qId: $qId
 )
}
`;


export const DELETE_COPY_IMAGES = gql`
mutation deleteCopyImages(
  $userEmail: String!
  $token : String!
  $qId: Float!

) {
  deleteCopyImages(
    userEmail: $userEmail
    token : $token
    qId: $qId
 )
}
`;


export const UPDATE_IMG_STATUS = gql`
  mutation updateImageLink(
    $imageStatus: String
    $imageLink: String
    $vid: String!
    $userEmail: String!
    $token : String!
    $qId: Float!
 
 ) {
  updateImageLink(
    imageStatus : $imageStatus
    imageLink : $imageLink
    vid : $vid
    userEmail : $userEmail
    token : $token
    qId : $qId
   ) 
 }
 `;

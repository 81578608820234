import React, { useContext, useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";

import { Divider, Slide } from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import { EmailContext } from "../../Context/EmailContext";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from "../../Component/Text";
import {
  ModalUpdateButton,
  CommonAddButton,
  ModalDeleteButton,
} from "../../Component/ButtonComponent";

import Loading from "../../Component/Loading";

import { FETCH_PETROL_VARIANTS, DELETE_PETROL_VARIANT } from "./api";

import AddPetrolVariant from "./AddPetrolVariant";
import EditPetrolVariant from "./EditPetrolVariant";

export default function PetrolVariants() {
  const { email, token } = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);

  // const [edit, setEdit] = useState(false)

  const [modalInfo, setModalInfo] = useState(null);
  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  const [formModal, setFormModal] = useState({
    open: false,
    title: "",
    check: "",
  });
  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error } = useQuery(FETCH_PETROL_VARIANTS, {
    variables: { userEmail: email, token : token },
  });

  const [deleteDutyDetails] = useMutation(DELETE_PETROL_VARIANT, {
    update(_, result) {
      setOutcome(result?.data?.deletePetrolVariantDetails);
    },
    onError(err) {
      setErrors(true);
    },
    refetchQueries: [
      {
        query: FETCH_PETROL_VARIANTS,
        variables: { userEmail: email, token : token },
      },
    ],
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const callDelete = (id) => {
    deleteDutyDetails({
      variables: {
        qId: id,
        userEmail: email,
        token : token
      },
    });
  };

  const callEdit = (objIs) => {
    setOpen(false);
    setModalInfo(objIs);
    handleOpenForm("Edit Petrol Variant", "editPetrolVariant");
  };

  const handleOpenForm = (title, check) => {
    setOutcome(false);
    setErrors(false);
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    });
  };

  const handleOpen = async (obj) => {
    setModalInfo(obj);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Petrol Variants</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Petrol Variants</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.noSearchRowDiv}>
          <CommonAddButton
            title="Add Petrol Variants"
            onClick={() =>
              handleOpenForm("Add Petrol Variants", "addPetrolVariant")
            }
          />
        </div>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      });
                    }}
                  />
                  <div className={mStyles.formModalInnerContent}>
                    {formModal?.check === "addPetrolVariant" ? (
                      <AddPetrolVariant />
                    ) : null}

                    {formModal?.check === "editPetrolVariant" ? (
                      <EditPetrolVariant updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <div className={classes.bodyDiv}>
            {data && data?.getAllPetrolVariantDetails?.PetrolVariantDetails ? (
              <>
                <TableContainer
                  elevation={0}
                  className={classes.table}
                  component={Paper}
                >
                  <Table aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">
                          Manufacturer
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Car Name
                        </StyledTableCell>
                        <StyledTableCell align="center">Price</StyledTableCell>

                        <StyledTableCell align="center">
                          L/100km
                        </StyledTableCell>

                        <StyledTableCell align="center">Tank</StyledTableCell>

                        <StyledTableCell align="center">Range</StyledTableCell>

                        <StyledTableCell align="center">
                          Acceleration
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          FC NEDC
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          FC WLTP
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          RangeNEDC
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          RangeWLTP
                        </StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.getAllPetrolVariantDetails?.PetrolVariantDetails.map(
                        (obj, index) => {
                          return (
                            <StyledTableRow
                              key={index}
                              onClick={() => handleOpen(obj)}
                              hover={true}
                              className={classes.tableRow}
                            >
                              <StyledTableCell align="center">
                                {obj.manuf}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.car_name}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {obj.price}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.lperkm}
                              </StyledTableCell>

                              <StyledTableCell align="center">
                                {obj.tank}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.range}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.acceleration}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.fc_nedc}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.fc_wltp}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.range_nedc}
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                {obj.range_wltp}
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <StatusText title={"No Data"} fColor={Colors.black} />
            )}

            <div>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={mStyles.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div className={mStyles.paper}>
                    <div className={mStyles.formModalContent}>
                      <div className={mStyles.floatLeft}>Petrol Variants</div>
                    </div>

                    <div className={mStyles.formModalInnerContent}>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Id</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.pid} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Manufacturer</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.manuf}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Car Name</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.car_name}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Price</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.price} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>L/100 Km</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.lperkm}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Tank</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.tank} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Range</p>{" "}
                        <p className={mStyles.textRight}>{modalInfo?.range} </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Acceleration</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.acceleration}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          FactorConversion NEDC
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.fc_nedc}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>
                          {" "}
                          FactorConversion WLTP
                        </p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.fc_wltp}{" "}
                        </p>
                      </div>
      
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Range NEDC</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.range_nedc}{" "}
                        </p>
                      </div>
                      <Divider className={mStyles.dividerLine} />
                      <div className={mStyles.rowView}>
                        <p className={mStyles.textLeft}>Range WLTP</p>{" "}
                        <p className={mStyles.textRight}>
                          {modalInfo?.range_wltp}{" "}
                        </p>
                      </div>

                      {outcome && outcome === true && (
                        <StatusText
                          title={"Deleted Successfully"}
                          fColor={Colors.black}
                        />
                      )}

                      <ModalUpdateButton
                        title={"Edit"}
                        onClick={() => callEdit(modalInfo)}
                      />

                      <ModalDeleteButton
                        onClick={() => callDelete(modalInfo?.pid)}
                      />
                    </div>
                  </div>
                </Fade>
              </Modal>
            </div>
          </div>
        )}

        {errors && errors === true && (
          <StatusText title={"Something Went Wrong"} fColor={Colors.black} />
        )}
      </>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Divider, Typography } from "@material-ui/core";

import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  ModalCopyButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import {
  FETCH_BODY_AND_CHASIS,
  CREATE_BODY_CHASE_DATA,
  UPDATE_BODY_CHASE_DATA,
} from "./api";
import { FETCH_ALL_VARIANTS_DATA } from "../Variants/api";
import { EmailContext } from "../../Context/EmailContext";

export default function BodyAndChasis({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const lid = localStorage.getItem("vidis");
  const [updateStatus, setUpdateStatus] = useState(false);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token : token,
    seats: "",
    isofixNum: "",
    isofix: "",
    bodyStyle: "",
    segment: "",
    evPlatform: "",
    evDedicatedPlatform: "",
    roofRails: "",
    turningCircle: "",
    boot: "",
    bootMax: "",
    groundClearance: "",
    dimensionL: "",
    dimensionH: "",
    dimensionW: "",
    cargoCapacity: "",
    cargoCapacityMax: "",
    cargoCapacityFrunk: "",
    roofLoad: "",
    towHitchPossible: "",
    towWeightUnbraked: "",
    towWeightBraked: "",
    wheelBase: "",
    curbWeight: "",
    grossWeight: "",
    maxPayload: "",
    verticalLoadMax: "",
  });

  const [calltheBodyChaseAPI, { loading, data, error }] = useLazyQuery(
    FETCH_BODY_AND_CHASIS
  );

  const [updateBodyChase] = useMutation(UPDATE_BODY_CHASE_DATA, {
    update(_, result) {
      setOutcome2(result?.data?.updateBodyAndChaseDetail);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_BODY_AND_CHASIS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [createBodyChase] = useMutation(CREATE_BODY_CHASE_DATA, {
    update(_, result) {
      setOutcome(result?.data?.inputBodyAndChaseDetail?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_BODY_AND_CHASIS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      calltheBodyChaseAPI({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.getBodyChasis) {
      const { getBodyChasis } = data;
      setValues({
        ...values,

        seats: getBodyChasis.seats,
        isofixNum: getBodyChasis.isofix_num,
        isofix: getBodyChasis.isofix,
        bodyStyle: getBodyChasis.body_style,
        segment: getBodyChasis.segment,
        evPlatform: getBodyChasis.ev_platform,
        evDedicatedPlatform: getBodyChasis.ev_dedicated_platform,
        roofRails: getBodyChasis.roof_rails,
        turningCircle: getBodyChasis.turning_circle,
        boot: getBodyChasis.boot,
        bootMax: getBodyChasis.boot_max,
        groundClearance: getBodyChasis.ground_clearance,
        dimensionL: getBodyChasis.dimension_l,
        dimensionH: getBodyChasis.dimension_h,
        dimensionW: getBodyChasis.dimension_w,
        cargoCapacity: getBodyChasis.cargo_capacity,
        cargoCapacityMax: getBodyChasis.cargo_capacity_max,
        cargoCapacityFrunk: getBodyChasis.cargo_capacity_frunk,
        roofLoad: getBodyChasis.roof_load,
        towHitchPossible: getBodyChasis.tow_hitch_possible,
        towWeightUnbraked: getBodyChasis.tow_weight_unbraked,
        towWeightBraked: getBodyChasis.tow_weight_braked,
        wheelBase: getBodyChasis.wheel_base,
        curbWeight: getBodyChasis.curb_weight,
        grossWeight: getBodyChasis.gross_weight,
        maxPayload: getBodyChasis.max_payload,
        verticalLoadMax: getBodyChasis.vertical_load_max,
      });

      if (lid === getBodyChasis?.vid) {
        setUpdateStatus(false);
      }
      if (lid !== getBodyChasis?.vid) {
        setUpdateStatus(true);
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  async function validator() {
    const parsedValuesAre = {
      seats: isNaN(parseFloat(values.seats)) ? 0 : parseFloat(values.seats),
      isofixNum: isNaN(parseFloat(values.isofixNum))
        ? 0
        : parseFloat(values.isofixNum),
      isofix: values.isofix,
      bodyStyle: values.bodyStyle,
      segment: values.segment,
      evPlatform: values.evPlatform,
      evDedicatedPlatform: values.evDedicatedPlatform,
    
      roofRails: values.roofRails,
      turningCircle: isNaN(parseFloat(values.turningCircle))
        ? 0
        : parseFloat(values.turningCircle),
      boot: isNaN(parseFloat(values.boot)) ? 0 : parseFloat(values.boot),
      dimensionL: isNaN(parseFloat(values.dimensionL))
        ? 0
        : parseFloat(values.dimensionL),
      dimensionH: isNaN(parseFloat(values.dimensionH))
        ? 0
        : parseFloat(values.dimensionH),
      dimensionW: isNaN(parseFloat(values.dimensionW))
        ? 0
        : parseFloat(values.dimensionW),
     
      cargoCapacity: isNaN(parseFloat(values.cargoCapacity))
        ? 0
        : parseFloat(values.cargoCapacity),
      cargoCapacityMax: isNaN(parseFloat(values.cargoCapacityMax))
        ? 0
        : parseFloat(values.cargoCapacityMax),
      cargoCapacityFrunk: isNaN(parseFloat(values.cargoCapacityFrunk))
        ? 0
        : parseFloat(values.cargoCapacityFrunk),
      roofLoad: isNaN(parseFloat(values.roofLoad))
        ? 0
        : parseFloat(values.roofLoad),
    
      towHitchPossible: values.towHitchPossible,
      towWeightUnbraked: isNaN(parseFloat(values.towWeightUnbraked))
        ? 0
        : parseFloat(values.towWeightUnbraked),
      towWeightBraked: isNaN(parseFloat(values.towWeightBraked))
        ? 0
        : parseFloat(values.towWeightBraked),
      wheelBase: isNaN(parseFloat(values.wheelBase))
        ? 0
        : parseFloat(values.wheelBase),
      curbWeight: isNaN(parseFloat(values.curbWeight))
        ? 0
        : parseFloat(values.curbWeight),
      grossWeight: isNaN(parseFloat(values.grossWeight))
        ? 0
        : parseFloat(values.grossWeight),
      maxPayload: isNaN(parseFloat(values.maxPayload))
        ? 0
        : parseFloat(values.maxPayload),
      verticalLoadMax: isNaN(parseFloat(values.verticalLoadMax))
        ? 0
        : parseFloat(values.verticalLoadMax),
      bootMax: isNaN(parseFloat(values.bootMax))
        ? 0
        : parseFloat(values.bootMax),

      groundClearance: isNaN(parseFloat(values.groundClearance))
        ? 0
        : parseFloat(values.groundClearance),

      vid: updateValues ? updateValues?.vid : "",
      userEmail: email,
      token : token,
    };
    return parsedValuesAre;
  }

  async function callCreateAPI() {
    setOutcome("");
    setErrors("");
    const parsedValuesAre = await validator();
    await createBodyChase({
      variables: parsedValuesAre,
    });
  }

  async function callEditAPI() {
    setOutcome2("");
    setErrors("");
    // updateData();

    const validatedValues = await validator();

    await updateBodyChase({
      variables: validatedValues,
    });
  }

  function callTheMainApi() {
    calltheBodyChaseAPI({ variables: { qId: lid } });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };
  //   const onChangeNum = (event) => {
  //     setValues({ ...values, [event.target.name]: Number(event.target.value) })   <CommonAddButton title={'Create'} onClick={() => callCreateAPI()} />
  //   }

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>loading ...</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={mStyles.floatRight}>
                    <ModalCopyButton onClick={() => callTheMainApi()} />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Seats
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Seats"
                      name="seats"
                      value={values.seats}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      IsoFix Number
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Isofix Num"
                      name="isofixNum"
                      value={values.isofixNum}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      IsoFix
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Isofix"
                      name="isofix"
                      value={values.isofix}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Body Style
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Body Style"
                      name="bodyStyle"
                      value={values.bodyStyle}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Segment
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Segment"
                      name="segment"
                      value={values.segment}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Ev Platform
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Ev Platformm"
                      name="evPlatform"
                      value={values.evPlatform}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Ev Dedicated Platform
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Ev Dedicated Platform"
                      name="evDedicatedPlatform"
                      value={values.evDedicatedPlatform}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Driver Support System
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Driver Support System"
                      name="driverSupportSystems"
                      value={values.driverSupportSystems}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Roof Rails
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Roof Rails"
                      name="roofRails"
                      value={values.roofRails}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Turning Circle
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Turning Circle"
                      name="turningCircle"
                      value={values.turningCircle}
                      onChange={onChange}
                    />
                  </div>
                  {/* <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>Boot Std</Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Boot"
                      name="boot"
                      value={values.boot}
                      onChange={onChange}
                    />
                  </div>

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Boot Max
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter boot max"
                      name="bootMax"
                      value={values.bootMax}
                      onChange={onChange}
                    />
                  </div> */}

                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Ground Clearance
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter ground clearance"
                      name="groundClearance"
                      value={values.groundClearance}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Length
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Length"
                      name="dimensionL"
                      value={values.dimensionL}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Height
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Height"
                      name="dimensionH"
                      value={values.dimensionH}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Width
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Width"
                      name="dimensionW"
                      value={values.dimensionW}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      WidthWithMirror
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter WidthWithMirror"
                      name="dimensionWidthwithMirror"
                      value={values.dimensionWidthwithMirror}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Cargo Capacity
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Cargo Capacity"
                      name="cargoCapacity"
                      value={values.cargoCapacity}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Cargo Capacity Max{" "}
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Cargo Capacity "
                      name="cargoCapacityMax"
                      value={values.cargoCapacityMax}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Cargo Capacity Fruck{" "}
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Cargo Capacity "
                      name="cargoCapacityFrunk"
                      value={values.cargoCapacityFrunk}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Roof Load
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Roof Load"
                      name="roofLoad"
                      value={values.roofLoad}
                      onChange={onChange}
                    />
                  </div>
                  
              
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Tow Hitch Possible
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Tow Hitch Possible"
                      name="towHitchPossible"
                      value={values.towHitchPossible}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Tow Weight Unbraked
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Tow Weight Unbraked"
                      name="towWeightUnbraked"
                      value={values.towWeightUnbraked}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Tow Weight Braked
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Tow Weight Braked"
                      name="towWeightBraked"
                      value={values.towWeightBraked}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Wheel Base
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Wheel Base"
                      name="wheelBase"
                      value={values.wheelBase}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Curb Weight
                    </Typography>
                    <TextCustom
                      id="en"
                      placeholder="Enter Curb Weight"
                      type="number"
                      name="curbWeight"
                      value={values.curbWeight}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Gross Weight
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Gross Weight"
                      name="grossWeight"
                      value={values.grossWeight}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Max Payload
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Max Payload"
                      name="maxPayload"
                      value={values.maxPayload}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Vertical Load Max
                    </Typography>
                    <TextCustom
                      id="en"
                      type="number"
                      placeholder="Enter Vertical Load Max"
                      name="verticalLoadMax"
                      value={values.verticalLoadMax}
                      onChange={onChange}
                    />
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />

                  {lid === values.vid ? (
                    <>It Can Be Edited Only On The Main Table</>
                  ) : (
                    <>
                      {updateStatus ? (
                        <CommonEditButton
                          title={"Update"}
                          onClick={() => callEditAPI()}
                        />
                      ) : (
                        <CommonAddButton
                          title={"Create"}
                          onClick={() => callCreateAPI()}
                        />
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>vid:</p>
                    <p className={mStyles.textRight}>{values.vid}</p>
                  </div>

                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>seats:</p>
                    <p className={mStyles.textRight}>{values.seats}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>IsoFix Number:</p>
                    <p className={mStyles.textRight}>{values.isofixNum}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>IsoFix:</p>
                    <p className={mStyles.textRight}>{values.isofix}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Body Style:</p>
                    <p className={mStyles.textRight}>{values.bodyStyle}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Segment:</p>
                    <p className={mStyles.textRight}>{values.segment}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Ev Platform:</p>
                    <p className={mStyles.textRight}>{values.evPlatform}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Ev Dedicated Platform:</p>
                    <p className={mStyles.textRight}>
                      {values.evDedicatedPlatform}
                    </p>
                  </div>
                 
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Roof Rails:</p>
                    <p className={mStyles.textRight}>{values.roofRails}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Turning Circle:</p>
                    <p className={mStyles.textRight}>{values.turningCircle}</p>
                  </div>
                  {/* <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Boot:</p>
                    <p className={mStyles.textRight}>{values.boot}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Boot Max:</p>
                    <p className={mStyles.textRight}>{values.bootMax}</p>
                  </div> */}
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Ground Clearance:</p>
                    <p className={mStyles.textRight}>
                      {values.groundClearance}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Length:</p>
                    <p className={mStyles.textRight}>{values.dimensionL}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Height:</p>
                    <p className={mStyles.textRight}>{values.dimensionH}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Width:</p>
                    <p className={mStyles.textRight}>{values.dimensionW}</p>
                  </div>
                 
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Cargo Capacity:</p>
                    <p className={mStyles.textRight}>{values.cargoCapacity}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Cargo Capacity Max:</p>
                    <p className={mStyles.textRight}>
                      {values.cargoCapacityMax}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Cargo Capacity Frunk:</p>
                    <p className={mStyles.textRight}>
                      {values.cargoCapacityFrunk}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Roof Load:</p>
                    <p className={mStyles.textRight}>{values.roofLoad}</p>
                  </div>
                 
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Tow Hitch Possible:</p>
                    <p className={mStyles.textRight}>
                      {values.towHitchPossible}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Tow Weight Unbraked:</p>
                    <p className={mStyles.textRight}>
                      {values.towWeightUnbraked}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Tow Weight Braked:</p>
                    <p className={mStyles.textRight}>
                      {values.towWeightBraked}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Wheel Base:</p>
                    <p className={mStyles.textRight}>{values.wheelBase}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Curb Weight:</p>
                    <p className={mStyles.textRight}>{values.curbWeight}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Gross Weight:</p>
                    <p className={mStyles.textRight}>{values.grossWeight}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Max Payload:</p>
                    <p className={mStyles.textRight}>{values.maxPayload}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vertical Load Max:</p>
                    <p className={mStyles.textRight}>
                      {values.verticalLoadMax}
                    </p>
                  </div>

                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

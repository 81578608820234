import React, { useState } from "react";
import { Link } from "react-router-dom";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { Colors } from "../Styles/Colors/Colors";
import { headStyle } from "../Styles/headerStyle";
import { Fonts } from "../Styles/Colors/Font";

export default function Header() {
  const classes = headStyle();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  // const toggleDrawer = () => {
  //   setShowNav((prev) => !prev);
  // };

  function logyOuty() {
    localStorage.removeItem("name");
    localStorage.removeItem("myTok");
  }

  function userNameFnct() {
    const uName = localStorage.getItem("name");

    const nameSplit = uName?.split("");
    const fLetter = nameSplit[0]?.toUpperCase();
    const userName = fLetter + uName?.slice(1);

    return userName;
  }

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      style={{ marginTop: "5vh" }}
    >
      {/* <MenuItem onClick={handleMenuClose}>Profile</MenuItem> */}
      <MenuItem onClick={logyOuty}>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            color: Colors.black,
            width: 100,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ExitToAppIcon style={{ marginRight: 6, fontSize: 16 }} />{" "}
          <span style={{ fontSize: 15, fontFamily: Fonts.Ubuntu }}>Logout</span>
        </Link>{" "}
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar
        elevation={0}
        style={{
          backgroundColor: Colors.white,
          height: "8vh",
          boxSizing: "border-box",
          justifyContent: "center",
          position: "sticky",
          boxShadow: `0px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05)`,
          margin: 0,
          padding: 0,
        }}
        position="sticky"
      >
        <Toolbar>
          {/* <Button
            className={classes.menuButton}
            color="inherit"
            style={{ marginLeft: -14 }}
            onClick={toggleDrawer}
          >
            <MenuIcon className={classes.navBtn} />
          </Button> */}
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton
              edge="start"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <span
                style={{
                  fontSize: 14,
                  fontWeight: "bold",
                  color: Colors.lightBlack,
                  fontFamily: "ubuntu",
                }}
              >
                Hi {userNameFnct()}
              </span>
              <AccountCircle
                style={{
                  marginLeft: 10,
                  width: 22,
                  height: 22,
                  color: Colors.lightBlack,
                }}
              />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>

      {renderMenu}
    </div>
  );
}

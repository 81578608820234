import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_DUTIES, FETCH_DUTIES_AND_OTHER } from "./api";

export default function EditDuty({ updateValues }) {
  const { email ,token} = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  // console.log(updateValues)

  const [values, setValues] = useState({
    state: updateValues ? updateValues.state : "",
    emissionValue: updateValues ? updateValues.emission_value : "",
    incentiveDutyDeduction: updateValues
      ? updateValues.incentive_duty_deduction
      : "",
    incentiveRebate: updateValues ? updateValues.incentive_rebate : "",
    incentiveOther: updateValues ? updateValues.incentive_other : "",
    incentiveDutyLimit: updateValues ? updateValues.incentive_duty_limit : "",
    incentiveRebateLimit: updateValues
      ? updateValues.incentive_rebate_limit
      : "",
    rebateFirstCondition: updateValues
      ? updateValues.rebate_first_condition
      : "",
    quota: updateValues ? updateValues.quota : "",
    qId: updateValues ? updateValues.id : "",
    registrationFee: updateValues ? updateValues.registration_fee : "",
    vehicleTax: updateValues ? updateValues.vehicle_tax : "",
    plateFee: updateValues ? updateValues.plate_fee : "",
    ctp: updateValues ? updateValues.ctp : "",
    evLuxuryTax: updateValues ? updateValues.ev_luxury_tax : "",
    peLuxuryTax: updateValues ? updateValues.pe_luxury_tax : "",
    evLtPerc: updateValues ? updateValues.ev_lt_perc : "",
    peLtPerc: updateValues ? updateValues.pe_lt_perc : "",
    userEmail: email,
    token : token,
  });

  const [evRate, setEvRate] = useState(
    updateValues && updateValues.evRate !== null ? updateValues.evRate : {}
  );
  const [evThreshold, setEvThreshold] = useState(
    updateValues && updateValues.evThreshold !== null ? updateValues.evThreshold : {}
  );
  const [petrolRate, setPetrolRate] = useState(
    updateValues && updateValues.petrolRate !== null
      ? updateValues.petrolRate
      : {}
  );
  const [petrolThreshold, setPetrolThreshold] = useState(
    updateValues && updateValues.petrolThreshold !== null
      ? updateValues.petrolThreshold
      : {}
  );

  const [numValues, setNumValues] = useState({
    noRateValue:
      updateValues && updateValues.noRateValue !== null
        ? updateValues.noRateValue
        : 0,
    noEvThreshold:
      updateValues && updateValues.noEvThreshold !== null
        ? updateValues.noEvThreshold
        : 0,
    noPetrolValue:
      updateValues && updateValues.noPetrolValue !== null
        ? updateValues.noPetrolValue
        : 0,
    noPetrolThreshold:
      updateValues && updateValues.noPetrolThreshold !== null
        ? updateValues.noPetrolThreshold
        : 0,
  });

  // console.log(numValues);

  const [updateDutiesAndExceptions] = useMutation(UPDATE_DUTIES, {
    update(_, result) {
      setOutcome(result?.data?.updateDutiesAndExeptions);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_DUTIES_AND_OTHER,
        variables: { userEmail: email, token },
      },
    ],
  });

  function onSubmit() {
    setOutcome(false);
    setErrors(false);

    values["evRateData"] = JSON.stringify(evRate);
    values["evThresholdData"] = JSON.stringify(evThreshold);
    values["petrolRateData"] = JSON.stringify(petrolRate);
    values["petrolThresholdData"] = JSON.stringify(petrolThreshold);
    values["emissionValue"] = parseFloat(values.emissionValue);

    values["incentiveDutyDeduction"] =isNaN(
      parseFloat(values.incentiveDutyDeduction)
    )
      ? 0
      : parseFloat(values.incentiveDutyDeduction);
    values["incentiveRebate"] =isNaN(parseFloat(values.incentiveRebate))
      ? 0
      : parseFloat(values.incentiveRebate);
    values["registrationFee"] =isNaN(parseFloat(values.registrationFee))
      ? 0
      : parseFloat(values.registrationFee);
    values["vehicleTax"] =isNaN(parseFloat(values.vehicleTax))
      ? 0
      : parseFloat(values.vehicleTax);
    values["plateFee"] =isNaN(parseFloat(values.plateFee))
      ? 0
      : parseFloat(values.plateFee);
    values["ctp"] =isNaN(parseFloat(values.ctp)) ? 0 : parseFloat(values.ctp);
    values["evLuxuryTax"] =isNaN(parseFloat(values.evLuxuryTax))
      ? 0
      : parseFloat(values.evLuxuryTax);
    values["peLuxuryTax"] =isNaN(parseFloat(values.peLuxuryTax))
      ? 0
      : parseFloat(values.peLuxuryTax);
    values["evLtPerc"] =isNaN(parseFloat(values.evLtPerc))
      ? 0
      : parseFloat(values.evLtPerc);
    values["peLtPerc"] =isNaN(parseFloat(values.peLtPerc))
      ? 0
      : parseFloat(values.peLtPerc);

    updateDutiesAndExceptions({
      variables: values,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  function range(start, end) {
    return Array(end - start + 1)
      .fill()
      .map((_, idx) => start + idx);
  }

  const onChange = (event) => {
    if (
      event.target.name === "state" ||
      event.target.name === "quota" ||
      event.target.name === "emissionValue" ||
      event.target.name === "incentiveOther"
    ) {
      setValues({ ...values, [event.target.name]: event.target.value });
    } else {
      setValues({ ...values, [event.target.name]: Number(event.target.value) });
    }
  };
  const onChangeNumber = (event) => {
    setNumValues({ ...numValues, [event.target.name]: event.target.value });
  };

  const onChangeEvRate = (event) => {
    setEvRate({ ...evRate, [event.target.name]: event.target.value });
  };
  const onChangeevThreshold = (event) => {
    setEvThreshold({ ...evThreshold, [event.target.name]: event.target.value });
  };
  const onChangePetrolRate = (event) => {
    setPetrolRate({ ...petrolRate, [event.target.name]: event.target.value });
  };

  const onChangepetrolThreshold = (event) => {
    setPetrolThreshold({
      ...petrolThreshold,
      [event.target.name]: event.target.value,
    });
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                State
              </Typography>{" "}
              <TextInputField
                id="State"
                placeholder="State"
                name="state"
                value={values.state}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                EV Rate Value
              </Typography>{" "}
              <TextInputField
                id="rateValue"
                placeholder="No of Rate Value"
                name="noRateValue"
                value={numValues.noRateValue}
                onChange={onChangeNumber}
              />
            </div>

            {range(
              1,
              parseInt(numValues.noRateValue ? numValues.noRateValue : 0)
            ).map((obj, index) => (
              <div
                className={formClass.formRow}
                key={`${index}_ev_rate_${obj}`}
              >
                {" "}
                <Typography className={formClass.leftText}>
                  {" "}
                  Rate {obj} %
                </Typography>{" "}
                <TextInputField
                  id="rateValue"
                  placeholder="No of Rate Value in Percentage"
                  name={`ev_rate_${obj}`}
                  value={evRate[`ev_rate_${obj}`]}
                  onChange={onChangeEvRate}
                />
              </div>
            ))}
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                EV Threshold Data
              </Typography>{" "}
              <TextInputField
                id="rateLimitValue"
                placeholder="No of EV Thresholds"
                name="noEvThreshold"
                value={numValues.noEvThreshold}
                onChange={onChangeNumber}
              />
            </div>

            {range(
              1,
              parseInt(numValues.noEvThreshold ? numValues.noEvThreshold : 0)
            ).map((obj, index) => (
              <div
                className={formClass.formRow}
                key={`${index}_ev_rate_limit_${obj}`}
              >
                {" "}
                <Typography className={formClass.leftText}>
                  {" "}
                  Ev Theshold {obj}
                </Typography>{" "}
                <TextInputField
                  id="rateValue"
                  placeholder="No of Theshold Value"
                  name={`ev_threshold_${obj}`}
                  value={evThreshold[`ev_threshold_${obj}`]}
                  onChange={onChangeevThreshold}
                />
              </div>
            ))}

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Petrol Rate Value
              </Typography>{" "}
              <TextInputField
                id="noPetrolValue"
                placeholder="Enter No of Petrol Value"
                name="noPetrolValue"
                value={numValues.noPetrolValue}
                onChange={onChangeNumber}
              />
            </div>

            {range(
              1,
              parseInt(numValues.noPetrolValue ? numValues.noPetrolValue : 0)
            ).map((obj, index) => (
              <div
                className={formClass.formRow}
                key={`${index}_petrol_rate_${obj}`}
              >
                {" "}
                <Typography className={formClass.leftText}>
                  {" "}
                  Petrol Rate {obj} %
                </Typography>{" "}
                <TextInputField
                  id="petrolRateValue"
                  placeholder="No of Petrol Rate Value in Percentage"
                  name={`petrol_rate_${obj}`}
                  value={petrolRate[`petrol_rate_${obj}`]}
                  onChange={onChangePetrolRate}
                />
              </div>
            ))}

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Petrol Threshold
              </Typography>{" "}
              <TextInputField
                id="noPetrolThreshold"
                placeholder="No of Petrol Threshold"
                name="noPetrolThreshold"
                value={numValues.noPetrolThreshold}
                onChange={onChangeNumber}
              />
            </div>

            {range(
              1,
              parseInt(
                numValues.noPetrolThreshold ? numValues.noPetrolThreshold : 0
              )
            ).map((obj, index) => (
              <div
                className={formClass.formRow}
                key={`${index}_petrol_rate_limit_${obj}`}
              >
                {" "}
                <Typography className={formClass.leftText}>
                  {" "}
                  Petrol Theshold {obj}
                </Typography>{" "}
                <TextInputField
                  id="petrolThresholdValue"
                  placeholder="No of Petrol Theshold"
                  name={`petrol_threshold_${obj}`}
                  value={petrolThreshold[`petrol_threshold_${obj}`]}
                  onChange={onChangepetrolThreshold}
                />
              </div>
            ))}

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Quota
              </Typography>{" "}
              <TextInputField
                id="quota"
                placeholder="quota"
                name="quota"
                value={values.quota}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Emission Value
              </Typography>{" "}
              <TextInputField
                id="emissionValue"
                placeholder="Emission Value"
                name="emissionValue"
                value={values.emissionValue}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Incentive Duty Detection
              </Typography>{" "}
              <TextInputField
                id="Incentive Duty Detection"
                placeholder="Incentive Duty Detection"
                name="incentiveDutyDeduction"
                value={values.incentiveDutyDeduction}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Incentive Duty Limit Theshold
              </Typography>{" "}
              <TextInputField
                id="Incentive Duty Limit"
                placeholder="Incentive Duty Limit"
                name="incentiveDutyLimit"
                value={values.incentiveDutyLimit}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Incentive Rebate
              </Typography>{" "}
              <TextInputField
                id="Incentive Rebate"
                placeholder="Incentive Rebate"
                name="incentiveRebate"
                value={values.incentiveRebate}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Incentive Rebate Limit
              </Typography>{" "}
              <TextInputField
                id="Incentive Rebate Limit"
                placeholder="Incentive Rebate Limit"
                name="incentiveRebateLimit"
                value={values.incentiveRebateLimit}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Rebate Only For First __ Vehicles
              </Typography>{" "}
              <TextInputField
                id="rebateFirstCondition"
                placeholder="Rebate Only For First __ Vehicles"
                name="rebateFirstCondition"
                value={values.rebateFirstCondition}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Incentive Other
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="incentiveOther"
                value={values.incentiveOther}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Registration
              </Typography>{" "}
              <TextInputField
                id="Car Registration"
                placeholder="Car Registration"
                name="registrationFee"
                value={values.registrationFee}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Vehicle Tax
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="vehicleTax"
                value={values.vehicleTax}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Plate Fee
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="plateFee"
                value={values.plateFee}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> CTP</Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="ctp"
                value={values.ctp}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                EV Luxury Tax (Threshold)
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="evLuxuryTax"
                value={values.evLuxuryTax}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Petrol Luxury Tax (Threshold)
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="peLuxuryTax"
                value={values.peLuxuryTax}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Luxury Tax % Ev
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="evLtPerc"
                value={values.evLtPerc}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Luxury Tax % Petrol
              </Typography>{" "}
              <TextInputField
                id="Incentive Other"
                placeholder="Incentive Other"
                name="peLtPerc"
                value={values.peLtPerc}
                onChange={onChange}
              />
            </div>

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={"Updated Successfully"} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={"Something Went Wrong"} fColor={Colors.black} />
      )}
    </div>
  );
}

import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { UpdateButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { EmailContext } from '../../Context/EmailContext'

import { UPDATE_IMAGE_COLOR ,  FETCH_ALL_COLOR_IMAGES } from './api'

export default function EditVariantColor({ updateValues }) {
  const { email } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [values, setValues] = useState({

    colorName : updateValues ? updateValues.color_name : '',
    colorCode : updateValues ? updateValues.color_code : '',
    vid : updateValues ? updateValues.vid : '',
    qId : updateValues ? parseFloat(updateValues.id) : '',
    userEmail: email,
  })


  const [imgStatusChange] = useMutation(UPDATE_IMAGE_COLOR, {
    update(_, result) {
      if (result?.data?.updateImageColorName) {
        setOutcome(result?.data?.updateImageColorName);
      }
    },
    onError(err) {
      setErrors("Something went wrong!");
    },
    variables: values,
    refetchQueries: [
      {
        query: FETCH_ALL_COLOR_IMAGES,
        variables: { qId: values.vid },
      },
    ],
    awaitRefetchQueries: true,
  });




  const onSubmit = async () => {
  
    setOutcome(false);
    setErrors(false);
    imgStatusChange({
      variables: {
        values
      },
    });
  };

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Color Name </Typography>
              <TextInputField
                id="en"
                placeholder="Enter Color Name  "
                name="colorName"
                value={values.colorName}
                onChange={onChange}
              />
            </div>
       
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Color Code</Typography>
              <TextInputField
                id="en"
                placeholder="Enter Color Code"
                name="colorCode"
                value={values.colorCode}
                onChange={onChange}
              />
            </div>


            <UpdateButton title={'Update'} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={'Updated Successfully'} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
      )}
    </div>
  )
}

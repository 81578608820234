import React, { useState, useEffect, useContext } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Divider, Typography } from "@material-ui/core";



import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";


import { StatusText } from "../../Component/Text";

import { UpdateButton,
  ModalCopyButton,
  CommonAddButton,
  CommonEditButton, } from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import { FETCH_RANGE_CONSUMPTION, CREATE_RANGE_INFO, UPDATE_RANGE_INFO } from "./api";
import { FETCH_ALL_VARIANTS_DATA } from '../Variants/api';
import { EmailContext } from "../../Context/EmailContext";

export default function RangeConsumptionInfo({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const lid = localStorage.getItem('vidis')

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome, setOutcome] = useState('');
  const [outcome2, setOutcome2] = useState('');
  const [errors, setErrors] = useState('');

  const [edit, setEdit] = useState(false);

  const [updateStatus, setUpdateStatus] = useState(false)

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : '',
    userEmail: email,
    token : token,
    rangeEVDB: "",
    rangeNEDC: "",
    rangeWLTPTEL: "",
    realWorldCity: "",
    realWorldCombined: "",
    realWorldHighway: "",
  });
 /* <<<=========      API   ===========>>>  */
  const [callTheRangeApi, { loading, data, error }] = useLazyQuery(
    FETCH_RANGE_CONSUMPTION
  );

  const [updateRangeInfo] = useMutation(UPDATE_RANGE_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updateRangeConsumption)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query:FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query:  FETCH_RANGE_CONSUMPTION,
        variables: {qId: updateValues?.vid },
      },
    ],
  })


  const [createRangeInfo] = useMutation(CREATE_RANGE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputRangeConsumption?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query:  FETCH_RANGE_CONSUMPTION,
        variables: {qId: updateValues?.vid },
      },
    ],
  })


 /* <<<=========     FUNC   ===========>>>  */
  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callTheRangeApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (data?.getRangeConsumption) {
      const { getRangeConsumption } = data;
      setValues({
        ...values,
        rangeEVDB: getRangeConsumption?.range_evdb,
        rangeNEDC: getRangeConsumption?.range_nedc,
        rangeWLTPTEL: getRangeConsumption?.range_wltp_tel,
        realWorldCity: getRangeConsumption?.real_world_city,
        realWorldCombined: getRangeConsumption?.real_world_combined,
        realWorldHighway: getRangeConsumption?.real_world_highway
      });

      if (lid === getRangeConsumption?.vid) {
        setUpdateStatus(false)
      }
      if (lid !== getRangeConsumption?.vid) {
        setUpdateStatus(true)
      }
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


  /* <<<=========      CALL FUNCTIONS   ===========>>>  */

  async function validator(){
    const parsedValuesAre = {
      vid: updateValues ? updateValues?.vid : '',
      userEmail: email,
      token : token,
      rangeEVDB : isNaN(parseFloat(values.rangeEVDB)) ? 0 : parseFloat(values.rangeEVDB), 
      rangeNEDC : isNaN(parseFloat(values.rangeNEDC)) ? 0 : parseFloat(values.rangeNEDC), 
      rangeWLTPTEL : isNaN(parseFloat(values.rangeWLTPTEL)) ? 0 : parseFloat(values.rangeWLTPTEL),
      realWorldCity : isNaN(parseFloat(values.realWorldCity)) ? 0 : parseFloat(values.realWorldCity), 
      realWorldCombined : isNaN(parseFloat(values.realWorldCombined)) ? 0 : parseFloat(values.realWorldCombined), 
      realWorldHighway : isNaN(parseFloat(values.realWorldHighway)) ? 0 : parseFloat(values.realWorldHighway), 
    }
    return parsedValuesAre;
  }

  async function callCreateAPI() {
    setOutcome('')
    setErrors('')
    const parsedValuesAre = await validator()
    await createRangeInfo({
      variables: parsedValuesAre ,
    })
  }

  async function callEditAPI() {
    setOutcome2('')
    setErrors('')
    // updateData();

    const validatedValues = await validator()

    await updateRangeInfo({
      variables: validatedValues,
    })
  }

  function callTheMainApi() {
    callTheRangeApi({ variables: { qId: lid } })
 }
  

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
      </div>
    )
  }
  return (
    <div className={formClass.formDiv}>
      {(loading)?(<>loading ...</>):(<>
      {updateValues ? (
        <>
          {edit ? (
            <div>
              <div className={mStyles.floatRight}>
              
              <ModalCopyButton
                onClick={() => callTheMainApi()}
              />
            </div>
             
              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                  Range Zecar
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Range EVDB"
                  name="rangeEVDB"
                  value={values.rangeEVDB}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                  Range NEDC
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Range NEDC"
                  name="rangeNEDC"
                  value={values.rangeNEDC}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                  Range WLTP TEL
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Range WLTP"
                  name="rangeWLTPTEL"
                  value={values.rangeWLTPTEL}
                  onChange={onChange}
                />
              </div>

              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                 Real World City
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Real Worl City"
                  name="realWorldCity"
                  value={values.realWorldCity}
                  onChange={onChange}
                />
              </div>

              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                  Real World Combined
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Real World Combined"
                  name="realWorldCombined"
                  value={values.realWorldCombined}
                  onChange={onChange}
                />
              </div>

              <div className={formClass.formRow}>
                {" "}
                <Typography className={formClass.leftText}>
                  Real World Highway
                </Typography>
                <TextCustom
                  id="en"
                  type='number'
                  placeholder="Enter Real World Highway"
                  name="realWorldHighway"
                  value={values.realWorldHighway}
                  onChange={onChange}
                />
              </div>

              {outcome && Object.keys(outcome).length !== 0 && (
                <StatusText title={outcome} fColor={Colors.black} />
              )}
                {outcome2 && (
                <StatusText title={'Updated Successfully'} fColor={Colors.black} />
              )}
              {errors && Object.keys(errors).length !== 0 && (
                <StatusText title={ 'Something Went Wrong Cannot Be Created'} fColor={Colors.black} />
              )}

              <UpdateButton title={'cancel'} onClick={() => setEdit(false)} />
              {(lid === values.vid) ? (<>
                It Can Be Edited Only On The Main Table</>) : (
                <>
                  {updateStatus ? (
                    <CommonEditButton
                      title={'Update'}
                      onClick={() => callEditAPI()}
                    />
                  ) : (
                    <CommonAddButton
                      title={'Create'}
                      onClick={() => callCreateAPI()}
                    />
                  )}
                </>

              )}
            </div>
          ) : (
            <div>
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>VId </p>
                <p className={mStyles.textRight}>{values.vid}</p>
              </div>
             
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range Zecar </p>
                <p className={mStyles.textRight}>{values.rangeEVDB}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range NEDC </p>
                <p className={mStyles.textRight}>{values.rangeNEDC}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range WLTP </p>
                <p className={mStyles.textRight}>{values.rangeWLTPTEL}</p>
              </div>
             
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range World City </p>
                <p className={mStyles.textRight}>{values.realWorldCity}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range World Combined </p>
                <p className={mStyles.textRight}>{values.realWorldCombined}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range World Highway </p>
                <p className={mStyles.textRight}>{values.realWorldHighway}</p>
              </div>
              <Divider className={mStyles.dividerLine} />

              <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
            </div>
          )}
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
      </>)}
    </div>
  );
}

import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'

import { Colors } from '../../Styles/Colors/Colors'

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from '../../Styles/CommonStyles'

import { Divider, Slide } from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'

import { EmailContext } from '../../Context/EmailContext'

import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from '../../Component/Text'
import { CommonAddButton } from '../../Component/ButtonComponent'

import Loading from '../../Component/Loading'

import { FETCH_MAINTENANCE_DETAILS, DELETE_MAINTENANCE_INFO } from "./api";

import AddMaintenance from './AddMaintenance';
import EditMaintenance from './EditMaintenance'

export default function MaintenanceDetails(props) {

    const { email, token } = useContext(EmailContext)

      
  const updateValues = props.location.aboutProps
  ? props.location.aboutProps
  : null;



  const classes = CStyles()
  const mStyles = modalStyle()

  const [modalInfo, setModalInfo] = useState(null)
  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [formModal, setFormModal] = useState({
    open: false,
    title: '',
    check: '',
  })
 /* <<<=========      create staff  useMutation   ===========>>>  */

  const { loading, data, error } = useQuery(
    FETCH_MAINTENANCE_DETAILS, {
        variables:{
          // qId: "da86e320-2023-11ec-b7a9-ab1bc67e826d"
            qId: updateValues?.data?.vid

        }
    }
  );


  const [deleteUserData] = useMutation(DELETE_MAINTENANCE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.deleteMaintenanceDetails)
    },
    onError(err) {
      setErrors(true)
    },
    refetchQueries: [
      {
        query: FETCH_MAINTENANCE_DETAILS,
        variables: {   qId:updateValues?.data?.vid },
      },
    ],
  })

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const callDelete = (wowId) => {
    setOutcome('')
    setErrors('')
    deleteUserData({
      variables: {
        qId: parseFloat(wowId),
        userEmail: email,
        token : token
      },
    })
  }

  const callEdit = (objIs) => {
    setModalInfo(objIs)
    handleOpenForm('Edit Maintenance Details', 'editMaintenance')
  }

  const handleOpenForm = (title, check) => {
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    })
  }
  /* <<<=========    CONTENT   ===========>>>  */

/* <<<=========    CONTENT   ===========>>>  */
if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Maintenance Details</p>
        </div>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    )
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Maintenance Details</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.noSearchRowDiv}>
          <CommonAddButton
            title="Add Maintenance"
            onClick={() => handleOpenForm('Add Maintenance Info', 'addMaintenance')}
          />
        </div>

        {/* ========  ADD FORM MODAL   ======== */}

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={formModal?.open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className={mStyles.formModalContainer}
          >
            <Fade in={formModal?.open}>
              <Slide
                direction="down"
                in={formModal?.open}
                mountOnEnter
                unmountOnExit
              >
                <div className={mStyles.formModalContent}>
                  <ModalHead
                    title={formModal?.title}
                    onClick={() => {
                      setFormModal({
                        ...formModal,
                        open: false,
                      })
                    }}
                  />
                  <div className={mStyles.formModalInnerContent}>
                    {formModal?.check === 'addMaintenance' ? <AddMaintenance updateValues={updateValues}/> : null}

                    {formModal?.check === 'editMaintenance' ? (
                      <EditMaintenance updateValues={modalInfo} />
                    ) : null}
                  </div>
                </div>
              </Slide>
            </Fade>
          </Modal>
        </div>

        {loading ? (
          <Loading />
        ) : (
          <>
           
              <div className={classes.bodyDiv}>
                {data && data?.getVehicleMaintenance?.length > 0 ? (
                
                  <>
                    <TableContainer
                      elevation={0}
                      className={classes.table}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              Brand
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Model
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Variant
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Rego Insurance Monthly
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              fuel
                            </StyledTableCell>
                            <StyledTableCell align="center">
                             Service Monthly
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Tyres Monthly
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Rego and Insurance Anually
                            </StyledTableCell>

                            <StyledTableCell align="center">
                             Service Annually
                            </StyledTableCell>
                            <StyledTableCell align="center">
                             Tyres Annually
                            </StyledTableCell>
                            <StyledTableCell align="center">
                             fuel Annually
                            </StyledTableCell>
                            <StyledTableCell align="center">
                             For Km
                            </StyledTableCell>

                            <StyledTableCell align="center">
                             Edit
                            </StyledTableCell>
                            <StyledTableCell align="center">
                          Delete
                            </StyledTableCell>


                            


                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getVehicleMaintenance?.map((obj, index) => {
                            return (
                              <StyledTableRow key={index}>
                                <StyledTableCell align="center">
                                  {obj.brand}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {obj.model}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.variant}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.rego_and_insurance_monthy}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {obj.fuel}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {obj.servicing_monthly}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {obj.tyres_monthly}
                                </StyledTableCell>

                                <StyledTableCell align="center">
                                  {obj.rego_insurance_annualy}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.servicing_annually}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.tyres_annually}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.fuel_annually}
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  {obj.for_km}
                                </StyledTableCell>


                                <StyledTableCell align="center">
                                  <button
                                    onClick={() => {
                                      callEdit(obj)
                                    }}
                                  >
                                    Edit
                                  </button>
                                </StyledTableCell>
                                <StyledTableCell align="center">
                                  <button
                                    onClick={() => {
                                      callDelete(obj.id)
                                    }}
                                  >
                                    Delete
                                  </button>
                                </StyledTableCell>
                              </StyledTableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ) : (
                  <StatusText title={'No Data'} fColor={Colors.black} />
                )}
              </div>
            
          </>
        )}

        {outcome && outcome === true && (
          <StatusText title={'Deleted Successfully'} fColor={Colors.black} />
        )}

        {errors && errors === true && (
          <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
        )}
      </>
    </div>
  )
}

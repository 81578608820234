import gql from 'graphql-tag'

export const FETCH_CHARGE_DETAILS = gql`
  query getChargingDetails($userEmail: String! $token: String!) {
    getChargingDetails(userEmail: $userEmail token : $token) {
      status
      statusBool
      ChargingDetails {
        id
        current_flow
        phase
        type
        kw
      }
    }
  }
`;

export const CREATE_CHARGING_DETAILS = gql`
  mutation inputNewChargingDetails(
    $kw: Float!
    $type: String!
    $phase: String!
    $currentFlow: String!
    $userEmail: String!
    $token: String!
  ) {
    inputNewChargingDetails(
      kw: $kw
      type: $type
      phase: $phase
      currentFlow: $currentFlow
      userEmail: $userEmail
      token : $token
    ) {
      status
    }
  }
`;

export const DELETE_CHARGING_DETAILS = gql`
  mutation deleteChargeDetails($qId: Float!, $userEmail: String! $token: String!) {
    deleteChargeDetails(
      qId: $qId
      userEmail: $userEmail
      token : $token
    )
  }
`;

export const UPDATE_CHARGING_DETAILS = gql`
  mutation updateChargeDetails(
    $qId: Float!,
    $kw: Float!
    $type: String!
    $phase: String!
    $currentFlow: String!
    $userEmail: String!
    $token: String!
    ) {
    updateChargeDetails(
      qId: $qId
      kw: $kw
      type: $type
      phase: $phase
      currentFlow: $currentFlow
      userEmail: $userEmail
      token : $token
    )
  }
`;
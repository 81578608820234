import gql from "graphql-tag";

export const FETCH_ENERGY_CONSUMPTION_INFO = gql`
  query getEnergyConsumption($qId: String!) {
    getEnergyConsumption(qId: $qId) {
      vehicle_consumption_evdb
      vehicle_consumption_nedc
      vehicle_consumption_wltp_tel
      vehicle_fuel_equivalent_evdb
      vehicle_fuel_equivalent_nedc
      vehicle_fuel_equivalent_wltp_tel
      energy_consumption_combined
      energy_consumption_city
      energy_consumption_highway
    }
  }
`;

export const CREATE_ENERGY_INFO = gql`
  mutation inputEnergyConsumption(
    $userEmail: String!
    $token : String!
    $vid: String!
    $vehicleConsumptionNEDC: Boolean
    $vehicleConsumptionEVDB: Boolean
    $vehicleConsumptionWLTPTEL: Boolean
    $vehicleFuelEquivalentEVDB: Float
    $vehicleFuelEquivalentNEDC: Float
    $vehicleFuelEquivalentWLTPTEL: Float
    $energyConsumptionHighway: Float
    $energyConsumptionCombined: Float
    $energyConsumptionCity: Float
  ) {
    inputEnergyConsumption(
      userEmail: $userEmail
      token : $token
      vid: $vid
      vehicleConsumptionNEDC: $vehicleConsumptionNEDC
      vehicleConsumptionEVDB: $vehicleConsumptionEVDB
      vehicleConsumptionWLTPTEL: $vehicleConsumptionWLTPTEL
      vehicleFuelEquivalentEVDB: $vehicleFuelEquivalentEVDB
      vehicleFuelEquivalentNEDC: $vehicleFuelEquivalentNEDC
      vehicleFuelEquivalentWLTPTEL: $vehicleFuelEquivalentWLTPTEL
      energyConsumptionHighway: $energyConsumptionHighway
      energyConsumptionCombined: $energyConsumptionCombined
      energyConsumptionCity: $energyConsumptionCity
    ) {
      status
      statusBool
    }
  }
`;

export const UPDATE_ENERGY_INFO = gql`
  mutation updateEnergyConsumption(
    $userEmail: String!
    $token : String!
    $vid: String!
    $vehicleConsumptionNEDC: Boolean
    $vehicleConsumptionEVDB: Boolean
    $vehicleConsumptionWLTPTEL: Boolean
    $vehicleFuelEquivalentEVDB: Float
    $vehicleFuelEquivalentNEDC: Float
    $vehicleFuelEquivalentWLTPTEL: Float
    $energyConsumptionHighway: Float
    $energyConsumptionCombined: Float
    $energyConsumptionCity: Float
  ) {
    updateEnergyConsumption(
      userEmail: $userEmail
      token : $token
      vid: $vid
      vehicleConsumptionNEDC: $vehicleConsumptionNEDC
      vehicleConsumptionEVDB: $vehicleConsumptionEVDB
      vehicleConsumptionWLTPTEL: $vehicleConsumptionWLTPTEL
      vehicleFuelEquivalentEVDB: $vehicleFuelEquivalentEVDB
      vehicleFuelEquivalentNEDC: $vehicleFuelEquivalentNEDC
      vehicleFuelEquivalentWLTPTEL: $vehicleFuelEquivalentWLTPTEL
      energyConsumptionHighway: $energyConsumptionHighway
      energyConsumptionCombined: $energyConsumptionCombined
      energyConsumptionCity: $energyConsumptionCity
    )
  }
`;

import gql from "graphql-tag";

export const FETCH_FIND_EV = gql`
  query getFindEvData( 
    $limit: String
    $prevPage: String
    $userEmail: String!
    $token : String!
    $cursor: String
    $searchStatus : String
    $searchColumn : String
    $searchValue : String
    ) {
    getFindEvData( limit: $limit
      prevPage: $prevPage
      userEmail: $userEmail
      token : $token
      cursor: $cursor
      searchStatus : $searchStatus
      searchColumn : $searchColumn
      searchValue : $searchValue
      ) {
      status
      statusBool
      FindMyEvRecommendations {
        id
        user_email
        address
        address_lat
        address_lng
        body_style
        model_availability
        budget_min
        budget_max
        weekday_range
        weekday_range_trip
        weekend_range
        weekend_range_trip
        furthest_travel
        furthest_travel_location
        furthest_travel_lat
        furthest_travel_lng
        parking_availability
        recommendations
      }
      pageInfo{
        prevPageStatus
        nextPageStatus
        prevCursor
        nextCursor
      }
    }
  }
`;

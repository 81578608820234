import React, { useState, useEffect, useContext } from 'react'
import { useMutation, useLazyQuery } from '@apollo/react-hooks'
import { Divider, Typography } from '@material-ui/core'

import { formStyle, modalStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import {
  UpdateButton,
  CommonAddButton,
  CommonEditButton,
} from '../../Component/ButtonComponent'
import { TextCustom } from '../../Component/InputComponents'

import {
  FETCH_VEHICLE_RATINGS,
  CREATE_RATING_INFO,
  UPDATE_RANGE_INFO,
} from './api'
import { FETCH_ALL_VEHICLE_DATA } from '../VehicleDetails/api'

import { EmailContext } from '../../Context/EmailContext'

export default function RatingDetails({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()
  const mStyles = modalStyle()

  const [outcome, setOutcome] = useState('')
  const [outcome2, setOutcome2] = useState('')
  const [errors, setErrors] = useState('')

  const [edit, setEdit] = useState(false)

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : '',
    userEmail: email,
    token : token,
    ancapSafetyRating: '',
    adultOccupant: '',
    childOccupant: '',
    ratingYear: '',
    vulnerableRoadUsers: '',
    safetyAssist: '',
    batteryRating: '',
    efficiencyRating: '',
    rangeRating: '',
    performanceRating: '',
    technologyRating: '',
    costPerKm: '',
  })

  const [callThePricingApi, { loading, data, error }] = useLazyQuery(
    FETCH_VEHICLE_RATINGS,
  )

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid
      callThePricingApi({ variables: { qId: idIs } })
    }
  }, [updateValues]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    if (data) {
      const { getVehicleRatings } = data
      setValues({
        ...values,
        vid: getVehicleRatings?.vid,
        ancapSafetyRating: getVehicleRatings?.ancap_safety_rating,
        adultOccupant: getVehicleRatings?.adult_occupant,
        childOccupant: getVehicleRatings?.child_occupant,
        ratingYear: getVehicleRatings?.rating_year,
        vulnerableRoadUsers : getVehicleRatings?.vulnerable_road_users,
        safetyAssist : getVehicleRatings?.safety_assist,
        batteryRating : getVehicleRatings?.battery_rating,
        efficiencyRating : getVehicleRatings?.efficiency_rating,
        rangeRating : getVehicleRatings?.range_rating,
        performanceRating : getVehicleRatings?.performance_rating,
        technologyRating : getVehicleRatings?.technology_rating,
        costPerKm : getVehicleRatings?.cost_per_km
      })
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps


  /* <<<=========    useMutation   ===========>>>  */

  const [createRatingInfo] = useMutation(CREATE_RATING_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputRatingsForVehicle?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: {userEmail: email, token}
      },
      {
        query: FETCH_VEHICLE_RATINGS,
        variables: { qId: updateValues?.vid },
      },
    ],
  })

  const [updateRatingInfo] = useMutation(UPDATE_RANGE_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updateRatingsForVehicle)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: {userEmail: email, token}
      },
      {
        query: FETCH_VEHICLE_RATINGS,
        variables: { qId: updateValues?.vid },
      },
    ],
  })

  async function validator() {
    const parsedValuesAre = {
      vid: updateValues ? updateValues?.vid : '',
      userEmail: email,
      token : token,
      ancapSafetyRating: isNaN(parseFloat(values.ancapSafetyRating))
        ? 0
        : parseFloat(values.ancapSafetyRating),
      adultOccupant: isNaN(parseFloat(values.adultOccupant))
        ? 0
        : parseFloat(values.adultOccupant),
      childOccupant: isNaN(parseFloat(values.childOccupant))
        ? 0
        : parseFloat(values.childOccupant),
      ratingYear: values.ratingYear,
      vulnerableRoadUsers : isNaN(parseFloat(values.vulnerableRoadUsers)) ? 0 : parseFloat(values.vulnerableRoadUsers),
      safetyAssist : isNaN(parseFloat(values.safetyAssist)) ? 0 : parseFloat(values.safetyAssist),
      batteryRating : isNaN(parseFloat(values.batteryRating)) ? 0 : parseFloat(values.batteryRating),
      efficiencyRating : isNaN(parseFloat(values.efficiencyRating)) ? 0 : parseFloat(values.efficiencyRating),
      rangeRating : isNaN(parseFloat(values.rangeRating)) ? 0 : parseFloat(values.rangeRating),
      performanceRating: isNaN(parseFloat(values.performanceRating)) ? 0 : parseFloat(values.performanceRating),
      technologyRating: isNaN(parseFloat(values.technologyRating)) ? 0 : parseFloat(values.technologyRating),
      costPerKm: isNaN(parseFloat(values.costPerKm)) ? 0 : parseFloat(values.costPerKm),
    }
    return parsedValuesAre
  }
  async function callCreateAPI() {
    setOutcome({})
    setErrors({})
    const parsedValuesAre = await validator()
    console.log(parsedValuesAre)
    await createRatingInfo({
      variables: parsedValuesAre,
    })
  }

  async function callEditAPI() {
    setOutcome({})
    setErrors({})
    // updateData();

    const validatedValues = await validator()

    await updateRatingInfo({
      variables: validatedValues,
    })
  }


  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
      </div>
    )
  }

  return (
    <div className={formClass.formDiv}>
      {(loading)? (<>loading ...</>):(<>
      {updateValues  ? (
        <>
          {edit ? (
            <div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                  ANCAP Safety Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter ANCAP Safety Rating"
                  name="ancapSafetyRating"
                  value={values.ancapSafetyRating}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                  Adult Occupant
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Adult Occupant"
                  name="adultOccupant"
                  value={values.adultOccupant}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>Child Occupant</Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Child Occupant"
                  name="childOccupant"
                  value={values.childOccupant}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                  Rating Year
                </Typography>
                <TextCustom
                  id="en"
                  placeholder="Enter Rating Year"
                  name="ratingYear"
                  value={values.ratingYear}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Vulnerable Road Users
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Vulnerable Road Users"
                  name="vulnerableRoadUsers"
                  value={values.vulnerableRoadUsers}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Safety Assist
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Safety Assist"
                  name="safetyAssist"
                  value={values.safetyAssist}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Battery Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Battery Rating"
                  name="batteryRating"
                  value={values.batteryRating}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Efficiency Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Efficiency Rating"
                  name="efficiencyRating"
                  value={values.efficiencyRating}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Range Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Range Rating"
                  name="rangeRating"
                  value={values.rangeRating}
                  onChange={onChange}
                />
              </div>

              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Performance Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Performance Rating"
                  name="performanceRating"
                  value={values.performanceRating}
                  onChange={onChange}
                />
              </div>


              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Technology Rating
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Technology Rating"
                  name="technologyRating"
                  value={values.technologyRating}
                  onChange={onChange}
                />
              </div>


              <div className={formClass.formRow}>
                {' '}
                <Typography className={formClass.leftText}>
                Cost Per Km
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Cost Per KM"
                  name="costPerKm"
                  value={values.costPerKm}
                  onChange={onChange}
                />
              </div>


              {outcome && Object.keys(outcome).length !== 0 && (
                <StatusText title={outcome} fColor={Colors.black} />
              )}
              {outcome2 && (
                <StatusText
                  title={'Updated Successfully'}
                  fColor={Colors.black}
                />
              )}
              {errors && Object.keys(errors).length !== 0 && (
                <StatusText
                  title={'Something Went Wrong Cannot Be Created'}
                  fColor={Colors.black}
                />
              )}

              <UpdateButton title={'cancel'} onClick={() => setEdit(false)} />
              {data?.getVehicleRatings ? (
                <CommonEditButton
                  title={'Update'}
                  onClick={() => callEditAPI()}
                />
              ) : (
                <CommonAddButton
                  title={'Create'}
                  onClick={() => callCreateAPI()}
                />
              )}
            </div>
          ) : (
            <div>
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Vid</p>
                <p className={mStyles.textRight}>{values.vid}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>ANCAP Safety Rating</p>
                <p className={mStyles.textRight}>{values.ancapSafetyRating}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Adult Occupant</p>
                <p className={mStyles.textRight}>{values.adultOccupant}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Child Occupant</p>
                <p className={mStyles.textRight}>{values.childOccupant}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Rating Year</p>
                <p className={mStyles.textRight}>{values.ratingYear}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Vulnerable Road Users</p>
                <p className={mStyles.textRight}>{values.vulnerableRoadUsers}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Safety Assist</p>
                <p className={mStyles.textRight}>{values.safetyAssist}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Battery Rating</p>
                <p className={mStyles.textRight}>{values.batteryRating}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Efficiency Rating</p>
                <p className={mStyles.textRight}>{values.efficiencyRating}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Range Rating</p>
                <p className={mStyles.textRight}>{values.rangeRating}</p>
              </div>

              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Performance Rating</p>
                <p className={mStyles.textRight}>{values.performanceRating}</p>
              </div>

              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Technology Rating</p>
                <p className={mStyles.textRight}>{values.technologyRating}</p>
              </div>
              
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                <p className={mStyles.textLeft}>Cost Per Km</p>
                <p className={mStyles.textRight}>{values.costPerKm}</p>
              </div>

              <UpdateButton title={'Edit'} onClick={() => setEdit(true)} />
            </div>
          )}
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}
      </>)}
    </div>
  )
}

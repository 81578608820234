import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "./Colors/Colors";
import { Fonts } from "./Colors/Font";

export const sideNavStyle = makeStyles((theme) => ({
  logoDiv: {
    boxSizing: "border-box",
    borderBottom: `2px solid ${Colors.lightBlue}`,
    margin: `20px 20px 10px 16px`,
    paddingBottom: 18,
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // width: `min-content`,
  },
  logo: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  title: {
    fontSize: 14,
    fontFamily: Fonts.Lato,
    color: Colors.white,
  },

  list: {
    minWidth: 80,
    boxSizing: "border-box",
    height: "80vh",
    overflowY: "scroll",
    // scrollbarWidth: "none",
  },
  icon: {
    marginRight: 10,
    fontSize: 16,
    marginTop: 4,
    fontFamily: Fonts.Lato,
    color: Colors.white,
  },
  navText: {
    letterSpacing: 0.6,
    boxSizing: "border-box",
    margin: 0,
    minWidth: 128,
    textTransform: "capitalize",
    fontSize: 13,
    fontFamily: Fonts.Lato,
  },
  footText: {
    color: Colors.white,
    fontSize: 12,
    textAlign: "center",
    fontFamily: Fonts.Lato,
    position: "absolute",
    left: 20,
    bottom: 20,
  },
  sideNavIcon: {
    color: Colors.white,
  },

  menuButton: {
    color: Colors.white,
    padding: `5px 0`,
    minWidth: 50,
    maxWidth: 40,
    borderRadius: 0,
    height: "100%",
    marginBottom: 10,
    marginLeft: 3,
    "&:hover": {
      // backgroundColor: Colors.buttonBgHover,
    },
  },

  sideTexRow: {
    padding: 4,
    "&:hover": {
      paddingLeft: 8,
    },
  },
}));

import { makeStyles, withStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import { Colors } from "./Colors/Colors";
import { Fonts } from "./Colors/Font";

export const CStyles = makeStyles((theme) => ({
  page: {
    backgroundColor: Colors.white,
    margin: `0 0 30px 0`,
    padding: 0,
    width: "100%",
    minHeight: "100%",
    boxSizing: "border-box",
    boxShadow: `0px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05)`,
    border: `1px solid ${Colors.lightGray}`,
  },
  pageDiv: {
    margin: 0,
    padding: 0,
    width: "100%",
    height: "100%",
  },

  pageHead: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: `10px 20px 10px 20px`,
  },

  headTitle: {
    margin: 0,
    fontSize: 16,
    textTransform: "capitalize",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontFamily: Fonts.Lato,
    marginTop: 10,
  },

  dividerLine: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginTop: 0,
  },

  bodyDiv: {
    padding: 20,
    display: "flex",
    justifyContent: "center",
  },
  table: {
    minWidth: 700,
    borderRadius: 0,
    maxWidth: "100%",

    [theme.breakpoints.down("xs")]: {
      minWidth: 300,
      maxWidth: 440,
    },
    [theme.breakpoints.up("sm")]: {
      minWidth: 460,
      maxWidth: "100%",
    },
    // [theme.breakpoints.up("md")]: {
    //   minWidth: 660,
    //   maxWidth: "100%",
    // },
    // "@media (min-width: 1280px)": {
    //   minWidth: 940,
    //   maxWidth: "100%",
    // },
  },

  tableRow: {
    cursor: "pointer",
  },

  tableBlock: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  // search
  searchRowDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    justifyContent: "space-between",
    paddingRight: 21,
    marginBottom: 20,
  },
  noSearchRowDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: 30,
    justifyContent: "flex-end",
    paddingRight: 21,
    marginBottom: 20,
  },
  searchRow: {
    display: "flex",
    alignItems: "center",
    marginLeft: 20,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Colors.silverChalice,
    width: 250,
    color: Colors.black,
    marginRight: 20,
    borderBottomWidth: 2,
    borderBottomColor: "black",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: Colors.black,
  },
  inputRoot: {
    color: "inherit",
    backgroundColor: Colors.white,
  },
  inputInput: {
    color: Colors.black,
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    // transition: theme.transitions.create("width"),
    width: "100%",
    // backgroundColor: Colors.error,
    borderBottomWidth: 2,
    borderBottomColor: "black",
    fontFamily: Fonts.Lato,
  },

  searchBtn: {
    textTransform: "capitalize",
    padding: 10,
    height: 40,
    marginLeft: 14,
    marginTop: 16,
  },

  formControl: {
    width: 200,
    padding: 0,
    marginRight: 20,
  },

  selectorStyle: {
    height: 36,
    paddingBottom: 8,
    fontSize: 14,
    backgroundColor: Colors.white,
    fontFamily: Fonts.Lato,

    "&:hover": {
      backgroundColor: Colors.white,
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },
  selectPropsStyle: {
    backgroundColor: Colors.white,
    "&:hover": {
      backgroundColor: Colors.white,
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },
  selectorLabel: {
    marginTop: -10,
    color: Colors.gray,
    fontSize: 14,
    fontFamily: Fonts.Lato,
  },

  titleText: {
    fontSize: 18,
    marginRight: 10,
    fontFamily: Fonts.Lato,
  },
  inputStyle: {
    minWidth: 160,
    padding: 0,
    marginRight: 10,
    fontFamily: Fonts.Lato,
  },

  inputStyle2: {
    minWidth: 160,
    padding: 0,
    marginRight: 10,
    fontFamily: Fonts.Lato,

    "&:hover": {
      backgroundColor: Colors.white,
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },

  inputStyleProps: {
    backgroundColor: Colors.white,

    "&:hover": {
      backgroundColor: Colors.white,
    },
    "&:focus": {
      backgroundColor: Colors.white,
    },
  },

  pageContainer: {
    padding: 20,
  },

  settingsRowDiv: {
    padding: `6px 10px`,
    display: "flex",
    marginBottom: 6,
    alignItems: "center",
    transitionDuration: 600,
    width: 400,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: Colors.blue,
      color: Colors.white,
    },
  },
  settingsText: {
    fontSize: 16,
    fontFamily: Fonts.Lato,
  },

  paginationDiv: {
    display: "flex",
    margin: `20px 0`,
    justifyContent: "flex-end",
  },
  //  =========================    top tab bar ================================

  topBarDevider: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginTop: 0,
  },
  topBarHeadDiv: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: -2,
    padding: `0 10px 0 10px`,
  },

  topNavDivLeft: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: 16,
  },
  topNavDivRight: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },
  navText: {
    fontSize: 14,
    height: 40,
    padding: 1,
    boxSizing: "border-box",

    margin: `0 10px`,
    cursor: "pointer",
    color: Colors.lightBlack,
    fontWeight: "bold",
    fontFamily: Fonts.Lato,
    "&:hover": {
      // borderBottom: `4px solid ${Colors.tabBarBorder}`,
      color: Colors.textHoverBlue,
      fontWeight: "bold",
    },
  },

  // pro image
  storeHeadBtnDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
  },

  proImgBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 30,
  },
  proImageDiv: {
    display: "flex",
    flexDirection: "column",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.19) 0px 3px 3px 0px`,
    width: 300,
    boxSizing: "border-box",
    margin: `10px 15px 50px 15px`,
    backgroundColor: Colors.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  proImg: {
    width: "100%",
    height: 150,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  proImageTextDiv: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    padding: `20px 10px`,
    justifyContent: "center",
  },

  proImageTextDivx: {
    display: "flex",
    flexDirection: "row",
    padding: `20px 10px`,
    justifyContent: "space-evenly",
    flexWrap: "wrap",
  },

  proImageTextOnlyDiv: {
    display: "flex",
    flexDirection: "row",
    boxSizing: "border-box",
    padding: `5px 5px`,
    justifyContent: "center",
  },

  proImgLeftText: {
    fontSize: 14,
    margin: 0,
    marginRight: 20,
    fontFamily: Fonts.Ubuntu,
    color: Colors.black,
  },
  proImgRightText: {
    fontSize: 14,
    margin: 0,
    fontFamily: Fonts.Lato,
    color: Colors.black,
  },

  storeImageDiv: {
    display: "flex",
    flexDirection: "column",
    boxShadow: `rgba(0, 0, 0, 0.2) 0px 1px 1px 0px, rgba(0, 0, 0, 0.19) 0px 3px 3px 0px`,
    width: 300,
    boxSizing: "border-box",
    margin: `10px 15px 50px 15px`,
    backgroundColor: Colors.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  storeImg: {
    width: "100%",
    height: 250,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },

  storeImgTextDiv: {
    boxSizing: "border-box",
    padding: `20px 10px`,
  },

  proName: {
    fontSize: 16,
    fontFamily: Fonts.Lato,
    textTransform: "capitalize",
    margin: 0,
    marginBottom: 4,
  },
  proPrice: {
    fontSize: 16,
    fontFamily: Fonts.Lato,
    textTransform: "capitalize",
    margin: 0,
  },
  qtyDiv: {
    marginTop: 2,
    height: 40,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  qtyTextLeft: {
    fontSize: 14,
    fontFamily: Fonts.Lato,
    textTransform: "capitalize",
    marginBottom: 6,
    marginTop: 0,
  },
  proQtyInputStyle: {
    width: 35,
    marginLeft: 10,
  },

  storeCardBtnDiv: {
    display: "flex",
    marginTop: 30,
    justifyContent: "center",
  },

  // tab bar

  tabbarNormalNav: {
    margin: `0 10px`,
    fontSize: 14,
    cursor: "pointer",
    textDecoration: "none",
    letterSpacing: 0.3,
    display: "flex",
    flexDirection: "row",
    borderBottom: `0 solid ${Colors.lightGray}`,
    color: Colors.black,
    transitionDuration: 300,
    backgroundColor: Colors.white,
    height: 28,
    padding: `0 3px`,
    fontFamily: Fonts.Lato,
    fontWeight: "bold",

    "&:hover": {
      borderBottom: `0 solid ${Colors.lightGray}`,

      "& $tabbarTitle": {
        color: Colors.textHoverBlue,
      },
    },
  },
  tabbarActiveNav: {
    padding: `0 3px`,
    margin: `0 10px`,
    backgroundColor: Colors.white,
    borderBottom: `3px solid ${Colors.blue}`,
    color: Colors.blue,
    fontFamily: Fonts.Lato,
    fontWeight: "bold",
    fontSize: 14,

    "&:hover": {
      borderBottom: `3px solid ${Colors.blue}`,

      "& $tabbarTitle": {
        color: Colors.blue,
      },
    },
  },

  tabbarTitle: {
    margin: 0,
  },
}));

export const modalStyle = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.modalOuterBg,
  },
  paper: {
    backgroundColor: Colors.white,
    padding: 20,
    minWidth: 500,
    maxWidth: 1000,
    minHeight: 300,
    maxHeight: 500,

    overflow: "scroll",
    scrollbarWidth: "none",

    [theme.breakpoints.down("xs")]: {
      minWidth: 300,
      maxWidth: 360,
    },
  },

  floatLeft: {
    float: "left",
  },
  floatRight: {
    float: "right",
  },

  headerView: {
    margin: "10px",
    // background: "red",
  },

  rowView: {
    display: "flex",
    alignItems: "center",
    // background: "red",
  },

  rowView2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  rowView3: {
    marginBottom: 3,
    display: "flex",
    alignItems: "center",
    marginRight: 20,
  },
  headText: {
    fontSize: 12,
    paddingRight: 10,
    fontWeight: "bold",
    // fontFamily: Fonts.Ubuntu,
    textTransform: "capitalize",
  },
  textLeft: {
    fontSize: 12,
    margin: 0,
    paddingRight: 8,
    fontWeight: "bold",
    fontFamily: Fonts.Ubuntu,
    alignSelf: "flex-start",
  },

  textCenter: {
    fontSize: 16,
    margin: 0,
    paddingRight: 8,
    fontWeight: "bold",
    fontFamily: Fonts.Ubuntu,
    alignSelf: "center",
  },
  textRight: {
    fontSize: 12,
    margin: 0,
    textTransform: "capitalize",
    fontFamily: Fonts.Ubuntu,
  },

  textRight2: {
    fontSize: 12,
    margin: 0,
    fontFamily: Fonts.Ubuntu,
  },

  enqPackageDiv: {},

  eventRightDiv: {
    paddingLeft: 20,
    display: "flex",
    flexWrap: "wrap",
    width: 600,
  },

  enqRightDiv: {
    display: "flex",
    flexWrap: "wrap",
    width: 600,
    marginTop: 10,
  },

  eRightInnerDiv: {
    margin: "0 30px 15px 0",
  },
  eHeadText: {
    margin: 0,
    paddingBottom: 6,
    textTransform: "capitalize",
    fontSize: 12,
    fontFamily: Fonts.Ubuntu,
  },

  dividerLine: {
    marginTop: 5,
    marginBottom: 14,
  },
  modalBtnView: {
    marginTop: 30,
    paddingBottom: 30,
  },
  objDiv: {
    marginLeft: 10,
  },
  objText: {
    fontSize: 12,
    margin: 4,
    fontFamily: Fonts.Ubuntu,
  },
  objText2: {
    fontSize: 12,
    margin: 4,
    fontFamily: Fonts.Ubuntu,
    textTransform: "capitalize",
  },

  modalBillImg: {
    width: 300,
    height: 200,
    margin: "auto",
  },

  // form modal
  formModalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.modalOuterBg,
  },
  formModalContent: {
    backgroundColor: Colors.white,
  },

  formModalInnerContent: {
    minWidth: 620,
    maxWidth: 800,
    minHeight: 300,
    maxHeight: 500,
    overflow: "scroll",
    scrollbarWidth: "none",

    [theme.breakpoints.down("xs")]: {
      minWidth: 300,
      maxWidth: 360,
    },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: theme.spacing(3),
    //   backgroundColor: "blue",
    //   maxWidth: 240,
    // },
    // "@media (min-width: 1280px)": {
    //   marginTop: theme.spacing(5),
    //   backgroundColor: "red",
    //   maxWidth: 240,
    // },
  },

  formModalInner2: {
    minWidth: 440,
    maxWidth: 500,
    minHeight: 250,
    maxHeight: 400,

    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    // [theme.breakpoints.down("xs")]: {
    //   minWidth: 300,
    //   maxWidth: 360,
    // },
  },

  // comDiv
  commentBlock: {
    width: 500,
    marginBottom: 20,

    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  comTitle: {
    fontSize: 16,
    margin: `15px 0 10px 0`,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
  },
  comDiv: {
    backgroundColor: Colors.lightGray,
    borderRadius: 10,
    boxSizing: "border-box",
    padding: 10,
  },

  userComDiv: {
    backgroundColor: Colors.blue,
    padding: `4px 20px  8px 15px`,
    borderRadius: 10,
    boxSizing: "border-box",
    marginTop: 10,
    marginBottom: 10,
    width: "max-content",
    minWidth: 300,
    maxWidth: "90%",

    "@media (max-width: 500px)": {
      minWidth: 220,
      maxWidth: "80%",
    },
  },
  staffComDiv: {
    backgroundColor: Colors.blue,
    padding: `4px 20px  8px 15px`,
    borderRadius: 15,
    marginLeft: "auto",
    alignSelf: "flex-end",
    boxSizing: "border-box",
    marginTop: 10,
    marginBottom: 10,
    width: "max-content",
    minWidth: 300,
    maxWidth: "90%",

    "@media (max-width: 500px)": {
      minWidth: 220,
      maxWidth: "80%",
    },
  },

  comName1: {
    fontSize: 12,
    margin: `4px 0 6px 0`,
    color: Colors.white,
    fontFamily: Fonts.Ubuntu,
  },
  comName2: {
    fontSize: 12,
    margin: `4px 0 6px 0`,
    color: Colors.white,
    fontFamily: Fonts.Ubuntu,
  },

  comment1: {
    fontSize: 12,
    margin: `6px 0`,
    color: Colors.white,
    fontFamily: Fonts.Ubuntu,
  },
  comment2: {
    fontSize: 12,
    margin: `6px 0`,
    color: Colors.white,
    fontFamily: Fonts.Ubuntu,
  },

  splDiv: {
    margin: `10px  0 15px 0`,
    boxSizing: "border-box",
    width: "100%",
  },

  splTitle1: {
    fontSize: 14,
    margin: `20px 0 10px 0`,
    color: Colors.black,
  },

  text1: {
    fontSize: 12,
    margin: `2px 0`,
    boxSizing: "border-box",
    fontFamily: Fonts.Ubuntu,
  },

  textDiv: {},
}));

export const formStyle = makeStyles((theme) => ({
  // formCard
  page: {
    backgroundColor: Colors.white,
    padding: 0,
    width: "80%",
    boxSizing: "border-box",
    boxShadow: `0px 2px rgba(0,0,0,0.05), 0 1px 0 rgba(0,0,0,0.05)`,
    border: `1px solid ${Colors.lightGray}`,
    marginBottom: 25,
    margin: "auto ",
  },

  pageHead: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: `15px 20px 10px 20px`,
  },

  headTitle: {
    margin: 0,
    fontSize: 16,
    textTransform: "capitalize",
    fontWeight: "bold",
    color: Colors.darkBlue,
    fontFamily: Fonts.Ubuntu,
    alignSelf: "center",
  },

  dividerLine: {
    height: 1,
    backgroundColor: Colors.lightGray,
    marginTop: 0,
  },

  // form

  formDiv: {
    padding: "20px 18px",
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: theme.spacing(1),
    //   backgroundColor: "purple",
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: theme.spacing(3),
    //   backgroundColor: "blue",
    // },
    // "@media (min-width: 1280px)": {
    //   marginTop: theme.spacing(5),
    //   backgroundColor: "red",
    // },
  },

  formRow: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 20,
    width: "100%",

    // [theme.breakpoints.down("xs")]: {
    //   marginTop: theme.spacing(1),
    //   backgroundColor: "purple",
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: theme.spacing(3),
    //   backgroundColor: "blue",
    // },
    // "@media (min-width: 1280px)": {
    //   marginTop: theme.spacing(5),
    //   backgroundColor: "red",
    // },
  },

  formLabelRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
    width: "100%",
    alignItems: "center",
  },
  warnText: {
    fontSize: 14,
    color: Colors.error,
    fontFamily: Fonts.Ubuntu,
    alignSelf: "center",
    marginTop: 1,
  },

  leftText2: {
    fontSize: 13,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
    minWidth: 140,
    alignSelf: "flex-start",
    marginTop: 1,
  },
  leftText3: {
    fontSize: 13,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
    minWidth: 140,
    alignSelf: "flex-start",
    marginTop: 1,
    fontWeight: "bold",
  },

  leftText5: {
    fontSize: 14,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
    alignSelf: "flex-start",
    textTransform: "capitalize",
  },

  formDualRow3: {
    marginBottom: 5,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  formLabelRow4: {
    display: "flex",
    flexDirection: "row",
    width: "44%",
    alignItems: "center",
  },

  leftText4: {
    fontSize: 13,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
    alignSelf: "flex-start",
    fontWeight: "bold",
    marginRight: 8,
  },

  rightText: {
    fontSize: 14,
    color: Colors.black,
    textTransform: "capitalize",
    fontFamily: Fonts.Ubuntu,
    margin: `0 0 4px 0`,
  },

  formDualRow: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",

    // [theme.breakpoints.down("xs")]: {
    //   marginTop: theme.spacing(1),
    //   backgroundColor: "purple",
    //   flexWrap: "wrap",
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: theme.spacing(3),
    //   backgroundColor: "blue",
    // },
    // "@media (min-width: 1280px)": {
    //   marginTop: theme.spacing(5),
    //   backgroundColor: "red",
    // },
  },

  formDualRow2: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-between",

    // [theme.breakpoints.down("xs")]: {
    //   marginTop: theme.spacing(1),
    //   backgroundColor: "purple",
    //   flexWrap: "wrap",
    // },
    // [theme.breakpoints.between("sm", "md")]: {
    //   marginTop: theme.spacing(3),
    //   backgroundColor: "blue",
    // },
    // "@media (min-width: 1280px)": {
    //   marginTop: theme.spacing(5),
    //   backgroundColor: "red",
    // },
  },
  formRow2: {
    display: "flex",
    flexDirection: "column",
    width: "48%",
  },
  formRow3: {
    display: "flex",
    flexDirection: "column",
    width: "31%",
  },
  formRow4: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },

  eventFormRow: {
    display: "flex",
    flexDirection: "column",
    width: "54%",
  },

  leftText: {
    fontSize: 13,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
    marginBottom: 4,
  },

  eventOptionLeftText: {
    fontSize: 15,
    fontWeight: "bold",
    width: 80,
    fontFamily: "ubuntu",
    alignSelf: "center",
  },

  inputStyle: {
    margin: "4px 0 0 0",
    padding: 0,
    width: "100%",
    fontFamily: Fonts.Ubuntu,
    backgroundColor: "pink",

    "& $inputProps": {
      padding: "8px 10px",
      fontSize: 14,
      border: "none",

      backgroundColor: Colors.white,

      "&:hover": {
        backgroundColor: Colors.white,
        border: "none",
        outline: "none",
      },
      "&:focus": {
        backgroundColor: Colors.white,
        border: "1px solid red",
        outline: "none",
      },
    },
  },

  inputStyleProps: {
    padding: "8px 10px",
    fontSize: 14,
    border: "none",

    backgroundColor: Colors.white,

    "&:hover": {
      backgroundColor: Colors.white,
      border: "none",
      outline: "none",
    },
    "&:focus": {
      backgroundColor: Colors.white,
      border: "1px solid red",
      outline: "none",
    },
  },

  formCard: {
    minHeight: 400,
    padding: 20,
    boxSizing: "border-box",
    paddingBottom: 50,
    boxShadow: "none",
  },
  formCloseDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  leftSplText: {
    fontSize: 14,
    minWidth: 180,
    color: Colors.black,
    fontWeight: "bold",
    fontStyle: "italic",
    fontFamily: Fonts.Ubuntu,
  },

  dateStyle: {
    minWidth: 100,
    margin: 0,
    padding: "0 8px 0 10px",
    border: `1px solid ${Colors.lightGray}`,
    borderRadius: 4,
  },
  countInputStyle: {
    height: 30,
    width: 280,
    margin: 0,
    border: "1px solid #ccc",
    padding: "4px 10px",
    borderRadius: 2,
    fontSize: 16,
    // marginTop: 10,
    fontFamily: Fonts.Ubuntu,
  },
  fundInputStyle: {
    height: 28,
    width: 100,
    margin: 0,
    border: "1px solid #ccc",
    padding: "4px 10px",
    borderRadius: 2,
    fontSize: 16,
    // marginTop: 10,
    fontFamily: Fonts.Ubuntu,
  },
  countInput: {
    width: 60,
    padding: 0,
    marginRight: 10,
    height: 40,
    fontSize: 18,
    textAlign: "center",
    marginTop: 10,
    fontFamily: Fonts.Ubuntu,
  },

  copyLink: {
    marginTop: 50,
    alignSelf: "center",
    display: "flex",
    marginBottom: 50,
    flex: 1,
  },
  formControl: {
    padding: 0,
    // marginTop: 4,
  },
  formInputLabel: {
    marginTop: -4,
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
    lineHeight: 0,
  },

  selectRoot: {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  selectStyle: {
    height: 34,
    textTransform: "capitalize",
    width: "100%",
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
  },

  imgCardDiv: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: 20,
  },
  imgInput: {
    borderWidth: 2,
    borderStyle: "dashed",
    borderColor: "black",
    borderRadius: 10,
    fontSize: 18,
    padding: 15,
    width: "80%",
    marginTop: 20,
    fontFamily: Fonts.Ubuntu,
  },
  imgDiv: {
    padding: `4px 12px`,
    borderWidth: 2,
    borderColor: "black",
    margin: `0 0 25px 0`,
    backgroundColor: Colors.lightGray,
  },
  imgInputText: {
    fontSize: 15,
    color: "black",
    fontFamily: Fonts.Ubuntu,
  },
  imgBlock: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: 50,
    padding: 10,
  },
  proImgCard: {
    width: 320,
    padding: 10,
    borderRadius: 10,
    marginBottom: 30,
    marginLeft: 12,
    marginRight: 12,
    boxSizing: "border-box",
  },
  proImg: {
    height: 250,
    marginBottom: 15,
  },
  cardTextRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 15,
  },
  cardRowText: {
    fontSize: 16,
    color: Colors.black,
    fontFamily: Fonts.Ubuntu,
  },
  inputDiv: {
    display: "flex",
    flexDirection: "column",
  },

  // button div
  formBtnDiv: {
    margin: `30px 0 20px 0`,
    display: "flex",
    justifyContent: "center",
  },

  // remit form
  formRowBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  formLeftColm: {
    // backgroundColor: "pink",
    width: "42%",
    padding: `0 20px`,
  },
  lableRightText1: {
    fontSize: 14,
    color: Colors.black,
    textTransform: "capitalize",
    fontFamily: Fonts.Ubuntu,
    margin: `0 0 4px 0`,
    width: "60%",
  },
}));

export const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: Colors.tableHeadBlue,
    color: Colors.tableHeadTextBlue,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: Fonts.Lato,
    height: 20,
    padding: 0,
    margin: 0,
    boxShadow: "none",
    borderTop: `1px solid ${Colors.lightGray}`,
    borderBottom: `1px solid ${Colors.lightGray}`,
  },
  body: {
    fontSize: 12,
    textTransform: "capitalize",
    maxWidth: 120,
    padding: 0,
    margin: 0,
    height: 38,
    fontFamily: Fonts.Lato,
  },
}))(TableCell);

export const StyledTableCell2 = withStyles((theme) => ({
  head: {
    backgroundColor: Colors.tableHeadBlue,
    color: Colors.tableHeadTextBlue,
    fontSize: 12,
    fontWeight: "bold",
    fontFamily: Fonts.Lato,
    height: 20,
    padding: 0,
    margin: 0,
    boxShadow: "none",
    borderTop: `1px solid ${Colors.lightGray}`,
    borderBottom: `1px solid ${Colors.lightGray}`,
  },
  body: {
    fontSize: 12,
    maxWidth: 120,
    padding: 0,
    margin: 0,
    height: 38,
    fontFamily: Fonts.Lato,
    textTransform: "lowercase",
  },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {},
}))(TableRow);

export const assetStyle = makeStyles((theme) => ({
  formDiv: {
    backgroundColor: Colors.white,
    margin: `10px 0 30px 0`,
    boxSizing: "border-box",
  },
  table: {
    // backgroundColor: "pink",
    width: "100%",
    borderCollapse: "collapse",
  },
  bodyColumn: {
    textAlign: "center",
  },
  th: {
    border: `1px solid #ccc`,
    textAlign: "center",
    padding: 14,
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
  },
  td: {
    border: `1px solid #ccc`,
    textAlign: "center",
    padding: 2,
    width: "12%",
    fontSize: 15,
    fontFamily: Fonts.Ubuntu,
  },

  td1: {
    border: `1px solid #ccc`,
    textAlign: "center",
    padding: 12,
    fontSize: 15,
    fontFamily: Fonts.Ubuntu,
    maxWidth: 300,
    boxSizing: "border-box",
  },
  inputStyle: {
    padding: `0`,
    margin: 0,
    width: 24,
    outline: "none",
    // "&:valid": {
    //   outline: "none",
    // },
    "&:invalid": {
      border: `1px solid black`,
      padding: `6px  10px 6px 10px`,
      borderRadius: 4,
      fontFamily: Fonts.Ubuntu,
    },
  },
}));

import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_SCORE, FETCH_SCORE_DATA } from "./api";

export default function EditScore({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  // console.log(updateValues)

  const [values, setValues] = useState({
    qId: updateValues ? updateValues.id : "",
    nameScore: updateValues ? updateValues.name_score : "",
    zero: updateValues ? updateValues.zero : "",
    zeroFive: updateValues ? updateValues.zero_five : "",
    one: updateValues ? updateValues.one : "",
    oneFive: updateValues ? updateValues.one_five : "",
    two: updateValues ? updateValues.two : "",
    twoFive: updateValues ? updateValues.two_five : "",
    three: updateValues ? updateValues.three : "",
    threeFive: updateValues ? updateValues.three_five : "",
    four: updateValues ? updateValues.four : "",
    userEmail: email,
    token : token,
  });

  const [updateDutiesAndExceptions] = useMutation(UPDATE_SCORE, {
    update(_, result) {
      setOutcome(result?.data?.updateScoreTableDetails);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_SCORE_DATA,
        variables: { userEmail: email, token:token },
      },
    ],
  });

  function onSubmit() {
    setOutcome(false);
    setErrors(false);
    updateDutiesAndExceptions({
      variables: {
        qId: values.qId,
        userEmail: values.userEmail,
        token: values.token,
        nameScore: values.nameScore,
        zero: Number(values.zero),
        zeroFive: Number(values.zeroFive),
        one: Number(values.one),
        oneFive: Number(values.oneFive),
        two: Number(values.two),
        twoFive: Number(values.twoFive),
        three: Number(values.three),
        threeFive: Number(values.threeFive),
        four: Number(values.four),
      },
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Score Name
              </Typography>{" "}
              <TextInputField
                id="nameScore"
                placeholder="nameScore"
                name="nameScore"
                value={values.nameScore}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 0</Typography>{" "}
              <TextInputField
                id="zero"
                placeholder="limit for 0"
                name="zero"
                value={values.zero}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 0.5</Typography>{" "}
              <TextInputField
                id="zeroFive"
                placeholder="Limit for 0.5"
                name="zeroFive"
                value={values.zeroFive}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 1</Typography>{" "}
              <TextInputField
                id="one"
                placeholder="limit for Score 1"
                name="one"
                value={values.one}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 1.5</Typography>{" "}
              <TextInputField
                id="one five"
                placeholder="limit for Score 1.5"
                name="oneFive"
                value={values.oneFive}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 2</Typography>{" "}
              <TextInputField
                id="two"
                placeholder="two"
                name="two"
                value={values.two}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 2.5</Typography>{" "}
              <TextInputField
                id="twoFive"
                placeholder="Limit for Score 2.5"
                name="twoFive"
                value={values.twoFive}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 3</Typography>{" "}
              <TextInputField
                id="three"
                placeholder="Limit for Score 3"
                name="three"
                value={values.three}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 3.5</Typography>{" "}
              <TextInputField
                id="threeFive"
                placeholder="Limit for Score 3.5"
                name="threeFive"
                value={values.threeFive}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> 4</Typography>{" "}
              <TextInputField
                id="four"
                placeholder="Limit for Score 4"
                name="four"
                value={values.four}
                onChange={onChange}
              />
            </div>

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={"Updated Successfully"} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={"Something Went Wrong"} fColor={Colors.black} />
      )}
    </div>
  );
}

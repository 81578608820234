import gql from "graphql-tag";

export const ADD_PETROL_EQUIVALENT = gql`
  mutation inputPetrolEquivalent(
    $userEmail: String!
    $token : String!
    $petrolId: String
    $petrolName: String
    $evId: String
    $evName: String
    
    ) {
      inputPetrolEquivalent(
        userEmail : $userEmail
        token : $token
        petrolId : $petrolId
        petrolName : $petrolName
        evId : $evId
        evName : $evName
    ){
      status
    }
  }
`;


export const FETCH_PETROL_VARIANTS = gql`
  query getAllPetrolVariantDetails($userEmail: String! $token:String!) {
    getAllPetrolVariantDetails(userEmail: $userEmail token:$token) {
      status
      statusBool
      PetrolVariantDetails {
        pid
        car_name
      }
    }
  }
`;


export const FETCH_PETROL_EQUIVALENT = gql`
  query getPetrolEquivalentVehilce($userEmail: String!, $token:String!, $qId: String!) {
    getPetrolEquivalentVehilce(userEmail: $userEmail, qId : $qId, token:$token) {
      ev_id
      ev_name
      petrol_id
      petrol_name
    }
  }
`;


export const FETCH_PETROL_MENU = gql`
  query getPetrolEquivalentMenu {
    getPetrolEquivalentMenu {
      label
      value
    }
  }
`;
import jwtDecode from "jwt-decode";

export const isAuthenticated = () => {
  const token = localStorage.getItem("myTok");
  try {
    const { exp } = jwtDecode(token);

    if (Date.now() / 1000 > exp) {
      return false;
    }
  } catch (err) {
    // console.log(err)
    return false;
  }
  return true;
};

import gql from "graphql-tag";

export const FETCH_ALL_IMAGES = gql`
  query getAllImagesOnId($qId: String!) {
    getAllImagesOnId(qId: $qId) {
      id
      vid
      image_link
      image_status
    }
  }
`;

export const GET_SIGNED_URL = gql`
  mutation signedUrlForImage(
    $fileType: String!
    $fileName: String!
    $modelVariant: String!
    $modelName: String!
    $userEmail: String!
  ) {
    signedUrlForImage(
      fileType: $fileType
      fileName: $fileName
      modelVariant: $modelVariant
      modelName: $modelName
      userEmail: $userEmail
    ) {
      signedRequest
      url
      error
    }
  }
`;

export const INSERT_URL = gql`
  mutation inputImageForVehicle(
    $userEmail: String!
    $vid: String!
    $token : String!
    $imageLink: String
    $imageStatus: String
  ) {
    inputImageForVehicle(
      userEmail: $userEmail
      vid: $vid
      token : $token
      imageLink: $imageLink
      imageStatus: $imageStatus
    ) {
      status
      statusBool
      error
    }
  }
`;

export const UPDATE_IMG_STATUS = gql`
  mutation updateImageLink(
    $imageStatus: String
    $imageLink: String
    $vid: String!
    $userEmail: String!
    $token : String!
    $qId: Float!
  ) {
    updateImageLink(
      imageStatus: $imageStatus
      imageLink: $imageLink
      vid: $vid
      userEmail: $userEmail
      token : $token
      qId: $qId
    )
  }
`;

export const DELETE_IMG = gql`
  mutation deleteVehicleImageLink(
    $imgLink: String!
    $userEmail: String!
    $token : String!
    $qId: Float!
  ) {
    deleteVehicleImageLink(imgLink: $imgLink, userEmail: $userEmail, token : $token, qId: $qId)
  }
`;

import React, { createContext, useMemo, useState } from "react";

export const EmailContext = createContext();

export default function EmailContextData({ children }) {
  // const emailIs = localStorage.getItem("email");
  // const uName = localStorage.getItem("name");


  const [name, setName] = useState('');

  const [token, setToks] = useState('');

  const [email, setEmail] = useState('');

  if(email === ''){
    const emailIs = localStorage.getItem('email')
    setEmail(emailIs)
  }

  if(token === ''){
    const tokenIs = localStorage.getItem('myTok')
    setToks(tokenIs)
  }

  const value = useMemo(
    () => ({
      name,
      setName,
      email,
      setEmail,
      token, 
      setToks
    }),
    [name, setName, email, setEmail, token, setToks]
  );

  return (
    <EmailContext.Provider value={value}>{children}</EmailContext.Provider>
  );
}

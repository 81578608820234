import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { FETCH_ALL_VEHICLE_DATA, CREATE_VEHICLE_DATA } from '../VehicleDetails/api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddVariant({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState({})
  const [errors, setErrors] = useState({})

  const initialState = {
    userEmail: email,
    token : token,
    manufacturer:  updateValues ? updateValues?.manufacturer : '',
    modelName: updateValues ? updateValues?.model_name : '',
    modelVariant: "",
    countryOfManufacture: updateValues ? updateValues?.country_of_manufacture :'' ,
    availableCountry: updateValues ? updateValues?.available_country :'' ,
    availableFrom: updateValues ? updateValues?.available_from :'' ,
    availableNow: updateValues ? updateValues?.available_now :'' ,
    yearOfProduction: updateValues ? updateValues?.year_of_production :'' ,
    availableInAustralia: updateValues ? updateValues?.avail_in_australia :'' ,
  }

  const [values, setValues] = useState(initialState)

  /* <<<=========     useMutation   ===========>>>  */

  const [createVehicleData] = useMutation(CREATE_VEHICLE_DATA, {
    update(_, result) {
      setOutcome(result?.data?.createVehicleDisplayData?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
    ],
  })
  console.log(values)
  function onSubmit() {
    setOutcome({})
    setErrors({})

    createVehicleData({ variables: values })
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({})
    setErrors({})
    setValues(initialState)
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>Manufacturer </Typography>
          <TextInputField
            id="Manufacturer"
            placeholder="Enter Manufacturer"
            name="manufacturer"
            value={values.manufacturer}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>Model Name </Typography>
          <TextInputField
            id="Model Name"
            placeholder="Enter Model Name"
            name="modelName"
            value={values.modelName}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>Model Variant </Typography>
          <TextInputField
            id="Model Variant"
            placeholder="Enter Model Variant"
            name="modelVariant"
            value={values.modelVariant}
            onChange={onChange}
          />
        </div>
       
 
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>
            Country Of Manufacture{' '}
          </Typography>
          <TextInputField
            id="Country Of Manufacture"
            placeholder="Enter Country Of Manufacture"
            name="countryOfManufacture"
            value={values.countryOfManufacture}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>
            Available Country{' '}
          </Typography>
          <TextInputField
            id="Available Country"
            placeholder="Enter Available Country"
            name="availableCountry"
            value={values.availableCountry}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>
            Available From{' '}
          </Typography>
          <TextInputField
            id="Available From"
            placeholder="Enter Available From"
            name="availableFrom"
            value={values.availableFrom}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>Available Now </Typography>
          <TextInputField
            id="Available Now"
            placeholder="Enter Available Now"
            name="availableNow"
            value={values.availableNow}
            onChange={onChange}
          />
        </div>
      
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>
            Year Of Production{' '}
          </Typography>
          <TextInputField
            id="Year Of Production"
            placeholder="Enter Year Of Production"
            name="yearOfProduction"
            value={values.yearOfProduction}
            onChange={onChange}
          />
        </div>
        
        <div className={formClass.formRow}>
          <Typography className={formClass.leftText}>
            Available In Australia{' '}
          </Typography>
          <TextInputField
            id="Available In Australia"
            placeholder="Enter Available In Australia"
            name="availableInAustralia"
            value={values.availableInAustralia}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={'Something Went Wrong Cannot Be Created'}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

import gql from "graphql-tag";

export const FETCH_PERFORMANCE_DETAILS = gql`
  query  getPerformance($qId: String!){
    getPerformance(qId: $qId){
        vid
        acceleration_0_100
        efficiency
        cost_per_km
        torque_nm
        torque_lb_ft
        top_speed_kmhr
        top_speed_mphr
        power
        power_ps
        drive_type
        engine
        e_range
        e_range_num
}
  }
`;


export const CREATE_PERFORMANCE_INFO = gql`
  mutation  inputPerformanceForVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $acceleration0to100: Float
    $efficiency: Float
    $costPerKm: Float
    $torqueNm: Float
    $torqueLb: Float
    $topSpeedKmHr: Float
    $topSpeedMpHr: Float
    $power: Float
    $powerPs: Float
    $driveType: String
    $engine: String
    $eRangeNum: Float

  ) {
    inputPerformanceForVehicle(
      userEmail: $userEmail
      token : $token
      vid: $vid
      acceleration0to100: $acceleration0to100
      efficiency: $efficiency
      costPerKm: $costPerKm
      torqueNm: $torqueNm
      torqueLb: $torqueLb
      topSpeedKmHr: $topSpeedKmHr
      topSpeedMpHr: $topSpeedMpHr
      power: $power
      powerPs: $powerPs
      driveType: $driveType
      engine: $engine
      eRangeNum: $eRangeNum
    ) {
      status
      statusBool
    }
  }
`;

export const UPDATE_PERFORMANCE_INFO = gql`
  mutation updatePerformanceOfVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $acceleration0to100: Float
    $efficiency: Float
    $costPerKm: Float
    $torqueNm: Float
    $torqueLb: Float
    $topSpeedKmHr: Float
    $topSpeedMpHr: Float
    $power: Float
    $powerPs: Float
    $driveType: String
    $engine: String
    $eRangeNum: Float
    
    ) {
      updatePerformanceOfVehicle(
        userEmail: $userEmail
        token : $token
        vid: $vid
        acceleration0to100: $acceleration0to100
        efficiency: $efficiency
        costPerKm: $costPerKm
        torqueNm: $torqueNm
        torqueLb: $torqueLb
        topSpeedKmHr: $topSpeedKmHr
        topSpeedMpHr: $topSpeedMpHr
        power: $power
        powerPs: $powerPs
        driveType: $driveType
        engine: $engine
        eRangeNum: $eRangeNum
    )
  }
`;
import gql from "graphql-tag";

export const FETCH_CHARGE_COMPUTE = gql`
  query getChargingComputationData(  $limit: String
    $prevPage: String
    $userEmail: String!
    $token : String!
    $cursor: String
    $searchStatus : String
    $searchColumn : String
    $searchValue : String
    ) {
    getChargingComputationData(limit: $limit
      prevPage: $prevPage
      userEmail: $userEmail
      token : $token
      cursor: $cursor
      searchStatus : $searchStatus
      searchColumn : $searchColumn
      searchValue : $searchValue
      ) {
      status
      statusBool
      ChargingCalculatorInfo {
        id
        user_email
        brand
        model
        variant
        socket_type
        charger_type
        electricity_price
        weekday_range
        weekday_range_trip
        weekend_range
        weekend_range_trip
        recommendations
      }
      pageInfo{
        prevPageStatus
        nextPageStatus
        prevCursor
        nextCursor
      }
    }
  }
`;

import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Divider, Typography, MenuItem, Select } from "@material-ui/core";

import { CStyles, formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";

import { TextCustom } from "../../Component/InputComponents";

import { FETCH_TECH_SPEC, CREATE_TECH_SPEC, UPDATE_TECH_SPEC } from "./api";
import { FETCH_ALL_VEHICLE_DATA } from "../VehicleDetails/api";

import { EmailContext } from "../../Context/EmailContext";

export default function TechSpecDetails({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const classes = CStyles();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token :token,
    driveAssistSystem: "",
    infotainmentSystem: "",
    carplay: "",
    ota: "",
    heatPump: "",
  });

  const [callTheTechSpecAPI, { loading, data, error }] =
    useLazyQuery(FETCH_TECH_SPEC);

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callTheTechSpecAPI({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      const { getVehicleTechSpec } = data;
      setValues({
        ...values,
        driveAssistSystem: getVehicleTechSpec?.drive_assist_system,
        infotainmentSystem: getVehicleTechSpec?.infotainment_system,
        carplay: getVehicleTechSpec?.carplay,
        ota: getVehicleTechSpec?.ota,
        heatPump: getVehicleTechSpec?.heat_pump,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  /* <<<=========    useMutation   ===========>>>  */

  const [createRatingInfo] = useMutation(CREATE_TECH_SPEC, {
    update(_, result) {
      setOutcome(result?.data?.inputTechSpecForVehicle?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_TECH_SPEC,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [updateRatingInfo] = useMutation(UPDATE_TECH_SPEC, {
    update(_, result) {
      setOutcome2(result?.data?.updateTechSpecForVehicle);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_TECH_SPEC,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  async function callCreateAPI() {
    setOutcome({});
    setErrors({});

    await createRatingInfo({
      variables: values,
    });
  }

  async function callEditAPI() {
    setOutcome({});
    setErrors({});
    // updateData();

    await updateRatingInfo({
      variables: values,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>loading ...</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Drive Assist System
                    </Typography>
                    <TextCustom
                      id="en"
                      placeholder="Enter Drive Assist System"
                      name="driveAssistSystem"
                      value={values.driveAssistSystem}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Infotainment
                    </Typography>
                    <TextCustom
                      id="en"
                      placeholder="Enter infotainment System"
                      name="infotainmentSystem"
                      value={values.infotainmentSystem}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Car Play
                    </Typography>
                    <TextCustom
                      id="en"
                      placeholder="Enter Car Play"
                      name="carplay"
                      value={values.carplay}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>OTA</Typography>

                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="ota"
                      value={values.ota}
                      className={classes.selectorStyle}
                      onChange={(text) => onChange(text)}
                      label="Select Search"
                    >

                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                      
                    </Select>
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Heat Pump
                    </Typography>
                 
                     <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      name="heatPump"
                      value={values.heatPump}
                      className={classes.selectorStyle}
                      onChange={(text) => onChange(text)}
                      label="Select Search"
                    >

                      <MenuItem value={true}>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                      </Select>
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />
                  {data?.getVehicleTechSpec ? (
                    <CommonEditButton
                      title={"Update"}
                      onClick={() => callEditAPI()}
                    />
                  ) : (
                    <CommonAddButton
                      title={"Create"}
                      onClick={() => callCreateAPI()}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vid</p>
                    <p className={mStyles.textRight}>{values.vid}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Drive Assist System</p>
                    <p className={mStyles.textRight}>
                      {values.driveAssistSystem}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Infotainment System</p>
                    <p className={mStyles.textRight}>
                      {values.infotainmentSystem}
                    </p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>CarPlay</p>
                    <p className={mStyles.textRight}>{values.carplay}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>OTA</p>
                    <p className={mStyles.textRight}>{values.ota ? 'True' : 'False'}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Heat Pump</p>
                    <p className={mStyles.textRight}>{values.heatPump ? 'True' : 'False'}</p>
                  </div>

                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "./Colors/Colors";
import { Fonts } from "./Colors/Font";

export const loginStyle = makeStyles({
  page: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.grayFont,
  },
  loginDiv: {
    width: 400,
    minHeight: 400,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  contentDiv: {
    display: "flex",
    flexDirection: "column",
  },

  headDiv: {
    display: "flex",
    flexDirection: "row",
    background: Colors.lightBlack2,
    height: 70,
    marginBottom: 40,
    alignItems: "center",
    width: "100%",
    justifyContent: "center",
  },
  headText: {
    fontSize: 20,
    marginLeft: 15,
    color: Colors.dashBg,
    fontWeight: "bold",
    fontFamily: Fonts.Lato,
    marginTop: 16,
  },
  errText: {
    fontSize: 18,
    color: Colors.error,
    marginBottom: 50,
    fontFamily: Fonts.Lato,
    alignSelf: "center",
  },
  input: {
    marginBottom: 20,
    width: 300,
    color: Colors.white,
  },
  btn: {
    marginTop: 20,
    display: "flex",
    alignSelf: "center",
    width: 120,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: Fonts.Lato,
    backgroundColor: Colors.lightBlack2,
    "&:hover": {
      backgroundColor: Colors.lightOrange,
    },
  },
  img: {
    width: 60,
    height: 60,
    borderRadius: 50,
    alignSelf: "center",
  },
  footText: {
    fontSize: 10,
    color: Colors.black,
    marginTop: 40,
    textAlign: "center",
    fontFamily: Fonts.Lato,
    fontStyle: "italic",
  },
});

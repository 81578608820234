import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import {
  TextInputField,
} from '../../Component/InputComponents'

import { FETCH_CHARGE_DETAILS, CREATE_CHARGING_DETAILS } from './api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddChargingDetails(props) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState({})
  const [errors, setErrors] = useState({})

  const initialState = {
    currentFlow: '',
    phase: '',
    type: '',
    kw: '',
    userEmail: email,
    token: token
  }

  const [values, setValues] = useState(initialState)

  /* <<<=========     useMutation   ===========>>>  */

  const [createChargingDetails] = useMutation(CREATE_CHARGING_DETAILS, {
    update(_, result) {
      setOutcome(result?.data?.inputNewChargingDetails?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_CHARGE_DETAILS,
        variables: { userEmail: email, token },
      },
    ],
  })

  function onSubmit() {
    setOutcome({})
    setErrors({})

    const valueToInsert = {
      currentFlow: values.currentFlow,
      phase: values.phase,
      type: values.type,
      kw: parseFloat(values.kw),
      userEmail: email,
      token : values.token
    }
    createChargingDetails({variables: valueToInsert})
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {

 
      setValues({ ...values, [event.target.name]: event.target.value })
    
  }


  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({})
    setErrors({})
    setValues(initialState)
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Current Flow
          </Typography>{' '}
          <TextInputField
            id="Current_Flow"
            placeholder="Current Flow"
            name="currentFlow"
            value={values.currentFlow}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> Phase</Typography>{' '}
          <TextInputField
            id="Phase"
            placeholder="Phase"
            name="phase"
            value={values.phase}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> Type</Typography>{' '}
          <TextInputField
            id="type"
            placeholder="Type"
            name="type"
            value={values.type}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> KW</Typography>{' '}
          <TextInputField
            type="number"
            id="KW"
            placeholder="KW"
            name="kw"
            value={values.kw}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={'Something Went Wrong Cannot Be Created'}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

import React, { useContext, useEffect, useState, useCallback } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";
import { formStyle } from '../../Styles/CommonStyles'

import {
  CStyles,
  modalStyle,
} from "../../Styles/CommonStyles";

// import { Typography } from '@material-ui/core';
// import { TextCustom } from '../../Component/InputComponents'

import { Slide } from '@material-ui/core'

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from '../../Component/Text';

import {
 CommonFilledButton,
  UploadButton,
} from "../../Component/ButtonComponent";
// import Loading from "../../Component/Loading";

import { GET_SIGNED_URL_LOGO, FETCH_LOGO, UPDATE_MANUF_DATA, FETCH_PARTICULAR_LOGO } from "./api";

import axios from "axios";
import { useDropzone } from "react-dropzone";
import { EmailContext } from '../../Context/EmailContext';


export default function EditManu(props) {


  const { email, token } = useContext(EmailContext)


  const logoData = (props) ? props.updateValues : ''

  const [upLoading, setUpLoading] = useState(false);
  const [upLoaded, setUpLoaded] = useState(false);
  const NoPhoto = 'Photo';

  const [formModal, setFormModal] = useState({
    open: false,
    title: '',
    check: '',
  })


  const formClass = formStyle();
  const mStyles = modalStyle();
  const classes = CStyles();

  const [values, setValues] = useState({
    fileName: "",
    fileType: "",
    userEmail: email,
  });

  const initialState = {
    manufacturereName: (logoData) ? logoData.manuf_name : '',
    qId: (logoData) ? logoData.id : '',
  }

  const [imgLink, setImgLink] = useState((logoData) ? logoData.logo_link : '');

  const [inputvalues, setInputValues] = useState(initialState);


  const [fileDetail, setFileDetail] = useState(null)

  const [outcome, setOutcome] = useState('');
 
  const [errors, setErrors] = useState('');

  /* =====================      Get FUNCTION CALL     =====================*/
  const [callTheImagesApi, { loading, data, error }] = useLazyQuery(
    FETCH_PARTICULAR_LOGO
  );


  useEffect(()=>{

    if(data?.getManufacturer){
        setImgLink(data?.getManufacturer?.logo_link)
        setInputValues({
            qId: data?.getManufacturer?.id,
            manufacturereName: data?.getManufacturer?.manuf_name
        })
    }

  }, [data])


  /* <<<=========     PRODUCT IMAGE UPLOAD  useMutation   ===========>>>  */


  const [updateManuDetails] = useMutation(UPDATE_MANUF_DATA, {
    update(_, result) {
      setUpLoaded(true);
      setUpLoading(false);
      setOutcome(result?.data?.updateManufacturer);
      if(result?.data?.updateManufacturer){
        callTheImagesApi({variables:{
            qId: inputvalues.qId
        }})
      }
      setInputValues(initialState)
    },
    onError(err) {
      setErrors(err);
    },

    refetchQueries: [
        {
          query: FETCH_LOGO
        },
      ],
   
  })


  /* =====================      Update Event Image Status    =====================*/


  const uploadToS3 = async (signedRequest, url) => {
    const options = {
      headers: {
        "Content-Type": values.fileType,
      },
    };
    const returned = await axios.put(signedRequest, fileDetail.file, options);
    return returned;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      setValues({
        ...values,
        fileName: acceptedFiles[0].name,
        fileType: acceptedFiles[0].type
      });

      setFileDetail({
        file: acceptedFiles[0]
      })
    },
    [values]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [getUrl] = useMutation(GET_SIGNED_URL_LOGO, {
    update(_, result) {
      setUpLoading(result);
    },
    onError(err) {
      setErrors(err);
    }
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setUpLoaded(false);
    setOutcome('')

    if (inputvalues.manufacturereName === '') {
      setOutcome('Manufacturer Name Cannot Be Empty')
      return
    }

    const response = await getUrl({
        variables: {
            fileType : values.fileName,
            fileName : values.fileType,
            manufactuer : inputvalues.manufacturereName,
            userEmail : email
        },
    }
        );

    const { signedRequest, url } = response?.data?.signedUrlForLogo;

    const uploatStatus = await uploadToS3(signedRequest, url);


    if (uploatStatus.status === 200) {

      await updateManuDetails({
        variables: {
          token : token,
          userEmail: email,
          logoLink: url,
          manufacturereName: inputvalues.manufacturereName,
          qId: inputvalues.qId
        }
      });
    }
  };

  /* =====================      Update Event Image Status    =====================*/

//   const onChange = (event) => {
//       console.log(event.target.value)
//     setInputValues({ ...inputvalues, [event.target.name]: event.target.value })
//   }



  if(error){
      return <div> Something Went Wrong</div>
  }

  /* <<<=========    CONTENT   ===========>>>  */
  return (
    <div className={formClass.formDiv}>

        <div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={formModal?.open}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className={mStyles.formModalContainer}
            >
              <Fade in={formModal?.open}>
                <Slide
                  direction="down"
                  in={formModal?.open}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={mStyles.formModalContent}>
                    <ModalHead
                      title={formModal?.title}
                      onClick={() => {
                        setFormModal({
                          ...formModal,
                          open: false,
                        })
                      }}
                    />
                    <div className={mStyles.formModalInnerContent}>

                    </div>
                  </div>
                </Slide>
              </Fade>
            </Modal>
          </div>


          <div className={formClass.imgCardDiv}>
            <div className={formClass.imgDiv}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {fileDetail ? (
                  <p className={formClass.imgInputText}>
                    File {values.fileName} Selected
                  </p>
                ) : (
                  <p className={formClass.imgInputText}>
                    click to select files
                  </p>
                )}
              </div>

            </div>
            <div className={formClass.imgDiv}>
              {/* <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Manufacturer Name</Typography>
                <TextCustom
                  id="Color Name"
                  placeholder="Enter Manufacturer Name"
                  name="manufacturereName"
                  value={inputvalues.manufacturereName}
                  onChange={onChange}
                />
              </div> */}

     
            </div>

            <div className={classes.proImgBlock}>
                <div className={classes.proImageDiv} key={'index'}>
                        <img
                            src={imgLink}
                            alt={`${NoPhoto} is not available`}
                            className={classes.proImg}
                          /> 
                          <div className={classes.proImageTextDiv}>
                            <CommonFilledButton
                              title={inputvalues.manufacturereName}
                            />
                          </div>
                        </div>
                  </div>

            {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
        
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText
                title={"Something Went Wrong Cannot Be Created"}
                fColor={Colors.black}
              />
            )}

            {upLoading || loading ? (
              <StatusText title={"Uploading..."} fColor={Colors.black} />
            ) : null}


            <UploadButton
              title={upLoaded ? "Uploaded" : "Upload"}
              disabled={fileDetail ? false : true}
              onClick={onSubmit}
            />
          </div>
        </div> 
     
    </div>
  );
}

import gql from "graphql-tag";


export const FETCH_EMISSION_CONSTANT = gql`
  query  getEmissionDetails($userEmail: String! $token : String!){
    getEmissionDetails(userEmail: $userEmail token:$token){
        EmissionConstantDetails{
            id
            state
            emission_value
        }
          status
          statusBool
    }
  }
`;


export const CREATE_EMISSION_CONSTANT = gql`
  mutation inputEmissionDetails(
    $emissionValue: String!
    $state: String!
    $token : String!
    $userEmail: String!
  ) {
    inputEmissionDetails(
        emissionValue:$emissionValue
        state:$state
        userEmail:$userEmail
        token:$token
    ) {
      status
      statusBool
      error
    }
  }
`;


export const DELETE_EMISSION_DETAILS = gql`
  mutation deleteEmissionDetails($qId: Float!, $userEmail: String! $token : String!) {
    deleteEmissionDetails(
      qId: $qId
      userEmail: $userEmail
      token:$token
    )
  }
`;

export const UPDATE_EMISSION_CONSTANT = gql`
  mutation updateEmissionDetails(
    $emissionValue: String
    $state: String
    $token : String!
    $qId: Float!
    $userEmail: String!
    ) {
        updateEmissionDetails(
            emissionValue:$emissionValue
            state:$state
            qId:$qId
            userEmail:$userEmail
            token:$token
  
    )
  }
`;



import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { UpdateButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { EmailContext } from '../../Context/EmailContext'

import { UPDATE_DUTIES, FETCH_DUTIES_AND_OTHER } from './api'

export default function EditDuty({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [values, setValues] = useState({
    state:  updateValues ? updateValues.state: '',
    emissionValue: updateValues ? updateValues.emission_value: '',
    stampDutyPayablePetrol: updateValues ? updateValues.stamp_duty_payable_petrol: '',
    stampDutyPayableEv: updateValues ? updateValues.stamp_duty_payable_ev: '',
    carRegistration: updateValues ? updateValues.car_registration: '',
    incentiveDutyDeduction: updateValues ? updateValues.incentive_duty_deduction: '',
    incentiveRebate: updateValues ? updateValues.incentive_other: '',
    incentiveOther: updateValues ? updateValues.incentive_rebate: '',
    qId: updateValues ? updateValues.id: '',
    userEmail: email,
  })

  const [updateEmissionDetailInfo] = useMutation(UPDATE_DUTIES, {
    update(_, result) {
      setOutcome(result?.data?.updateDutiesAndOther)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_DUTIES_AND_OTHER,
        variables: { userEmail: email, token : token },
      },
    ],
  })

  function onSubmit() {
    setOutcome(false)
    setErrors(false)

    const newValues = {
      userEmail : email,
      token: token,
      qId: values.qId,
      state : values.state,
      emissionValue : isNaN(parseFloat(values.emissionValue)) ? 0 : parseFloat(values.emissionValue),
      stampDutyPayablePetrol : isNaN(parseFloat(values.stampDutyPayablePetrol)) ? 0 : parseFloat(values.stampDutyPayablePetrol),
      stampDutyPayableEv : isNaN(parseFloat(values.stampDutyPayableEv)) ? 0 : parseFloat(values.stampDutyPayableEv),
      carRegistration : isNaN(parseFloat(values.carRegistration)) ? 0 : parseFloat(values.carRegistration),
      incentiveDutyDeduction : isNaN(parseFloat(values.incentiveDutyDeduction)) ? 0 : parseFloat(values.incentiveDutyDeduction),
      incentiveRebate : isNaN(parseFloat(values.incentiveRebate)) ? 0 : parseFloat(values.incentiveRebate),
      incentiveOther : isNaN(parseFloat(values.incentiveOther)) ? 0 : parseFloat(values.incentiveOther)
    }
    updateEmissionDetailInfo({
        variables: newValues
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    if(event.target.name === 'state'){
      setValues({ ...values, [event.target.name]: event.target.value })
    }
    else{
      setValues({ ...values, [event.target.name]: Number(event.target.value) })
    }
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>

       
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>State</Typography>
              <TextInputField
                id="en"
                placeholder="Enter State"
                name="state"
                value={values.state}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Emission Value</Typography>
              <TextInputField
                id="en"
                placeholder="Enter Emission Value"
                name="emissionValue"
                value={values.emissionValue}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Stamp Duty Payable Petrol
          </Typography>{' '}
          <TextInputField
            id="stampDutyPayablePetrol"
            placeholder="Stamp Duty Payable Petrol"
            name="stampDutyPayablePetrol"
            value={values.stampDutyPayablePetrol}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Stamp Duty Payable Ev
          </Typography>{' '}
          <TextInputField
            id="Stamp Duty Payable Ev"
            placeholder="Stamp Duty Payable Ev"
            name="stampDutyPayableEv"
            value={values.stampDutyPayableEv }
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Car Registration
          </Typography>{' '}
          <TextInputField
            id="Car Registration"
            placeholder="Car Registration"
            name="carRegistration"
            value={values.carRegistration}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Duty Detection
          </Typography>{' '}
          <TextInputField
            id="Incentive Duty Detection"
            placeholder="Incentive Duty Detection"
            name="incentiveDutyDeduction"
            value={values.incentiveDutyDeduction}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Rebate
          </Typography>{' '}
          <TextInputField
            id="Incentive Rebate"
            placeholder="Incentive Rebate"
            name="incentiveRebate"
            value={values.incentiveRebate}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Other
          </Typography>{' '}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="incentiveOther"
            value={values.incentiveOther}
            onChange={onChange}
          />
        </div>

            <UpdateButton title={'Update'} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={'Updated Successfully'} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
      )}
    </div>
  )
}

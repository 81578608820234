import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import {
  TextInputField,
} from '../../Component/InputComponents'

import { FETCH_USER_INFO, CREATE_USER_INFO } from './api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddUserInfo() {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState({})
  const [errors, setErrors] = useState({})

  const initialState = {
    userRole : "",
    userPass : "",
    userName : "",
    inputEmail : "",
    userEmail: email,
    token: token
  }

  const [values, setValues] = useState(initialState)

  /* <<<=========     useMutation   ===========>>>  */

  const [createNewUser] = useMutation(CREATE_USER_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputNewUser?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_USER_INFO,
        variables: { userEmail: email, token: token },
      },
    ],
  })

  function onSubmit() {
    setOutcome({})
    setErrors({})

    createNewUser({variables: values})
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {

 
      setValues({ ...values, [event.target.name]: event.target.value })
    
  }


  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({})
    setErrors({})
    setValues(initialState)
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            User Name
          </Typography>{' '}
          <TextInputField
            id="User Name"
            placeholder="User Name"
            name="userName"
            value={values.userName}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> Email Address</Typography>{' '}
          <TextInputField
            id="Email Address"
            placeholder="Email Address"
            name="inputEmail"
            value={values.inputEmail}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> Passcode</Typography>{' '}
          <TextInputField
            id="Passcode"
            placeholder="Passcode"
            name="userPass"
            value={values.userPass}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> User Role</Typography>{' '}
          <TextInputField
    
            id="User Role"
            placeholder="User Role"
            name="userRole"
            value={values.userRole}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={'Something Went Wrong Cannot Be Created'}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

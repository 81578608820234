import { makeStyles } from "@material-ui/core/styles";
import { Colors } from "./Colors/Colors";
import { Fonts } from "./Colors/Font";

export const landingStyle = makeStyles((theme) => ({
  sideNav: {
    height: "100vh",
    backgroundColor: Colors.sideNavBg,
  },
  rightDiv: {
    width: "100%",
    // backgroundColor: Colors.white,
  },
  rightBody: {
    width: "100%",
    height: "92vh",
    boxSizing: "border-box",
    backgroundColor: Colors.themeBg,
    overflowY: "scroll",
    scrollbarWidth: "none",
    // scrollbarColor: "red",
  },
  footerStyle: {
    height: "5vh",
    backgroundColor: Colors.blue,
  },
  footText: {
    fontSize: 11,
    color: Colors.white,
    textAlign: "right",
    paddingTop: 10,
    paddingRight: 30,
    fontWeight: "bold",
    fontFamily: Fonts.Lato,
    fontStyle: "italic",
  },
}));

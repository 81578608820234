import React, {  useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";

import ListItem from "@material-ui/core/ListItem";
// RiDashboardFill, 
import {  FaUserTie, FaBiohazard, FaMoneyBill, FaGasPump, FaScrewdriver, FaReadme, FaCuttlefish } from "react-icons/fa";
import { RiBattery2ChargeFill, RiPhoneFindLine, RiPlugFill, RiBilliardsFill } from "react-icons/ri";
import { AiFillCar, AiFillDollarCircle } from "react-icons/ai";

import {SiBrandfolder} from "react-icons/si";

import MenuIcon from "@material-ui/icons/Menu";




import "../Styles/SideBarStyle.css";

import { sideNavStyle } from "../Styles/sideNavStyle";

// import logo from "../Styles/Assets/logo.jpg";


export default function SideNav() {
  const classes = sideNavStyle();

  const [ showNav, setShowNav ] = useState(false);
  const { pathname } = useLocation();

  // console.log("roleData", roleData?.vendor);

  const menu = [
    // {
    //   title: "Dashboard",
    //   path: "/home",
    //   Icon: <RiDashboardFill className={classes.sideNavIcon} />,
    //   urlArray: ["/home", "/dash-enquiry"],
    //   show: true,
    // },
    {
      title: "Vehicle Details",
      path: "/vehicledata",
      Icon: <AiFillCar className={classes.sideNavIcon} />,
      urlArray: [
        "/staff-details",
      ],
      show: true,
    },
    {
      title: "Find Ev Data",
      path: "/findmyevinfo",
      Icon: <RiPhoneFindLine className={classes.sideNavIcon} />,
      urlArray: [
        "/staff-details",
      ],
      show: true,
    },
    {
      title: "Charge Calculator",
      path: "/chargecomputationinfo",
      Icon: <RiBattery2ChargeFill className={classes.sideNavIcon} />,
      urlArray: [
        "/staff-details",
      ],
      show: true,
    },
    {
      title: "Cost Calculator",
      path: "/costcomputationinfo",
      Icon: <AiFillDollarCircle className={classes.sideNavIcon} />,
      urlArray: [
        "/staff-details",
      ],
      show: true,
    },
    {
      title: "User Info",
      path: "/userinfo",
      Icon: <FaUserTie className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },
    {
      title: "Charging Details",
      path: "/chargingdetails",
      Icon: <RiPlugFill className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },

    {
      title: "Emission Info",
      path: "/emissionconstant",
      Icon: <FaBiohazard className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },

    {
      title: "Manufacturer",
      path: "/manuf",
      Icon: <SiBrandfolder className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },
   

    {
      title: "Duties Details",
      path: "/dutiesandother",
      Icon: <FaMoneyBill className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },


    {
      title: "Duties Exceptions",
      path: "/dutiesandexceptions",
      Icon: <RiBilliardsFill className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },

    {
      title: "Petrol Variant",
      path: "/petrolvariant",
      Icon: <FaGasPump className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },


    {
      title: "General Maintenance",
      path: "/maintenance-general",
      Icon: <FaScrewdriver className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },


    {
      title: "Score Card",
      path: "/score-card",
      Icon: <FaReadme className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },

    {
      title: "Assumptions",
      path: "/assumptions",
      Icon: <FaCuttlefish className={classes.sideNavIcon} />,
      urlArray: [
        "/",
      ],
      show: true,
    },
  ];

  return (
    <div className={showNav ? "sidebar-responsive" : ""} id="sidebar">
      <div className={classes.logoDiv}>
        <div className={classes.logoBox}>
          {/* <Avatar alt="Logo" src={logo} className={classes.logo} /> */}
          {showNav ? (
            <Typography className={classes.title} variant="h6" noWrap>
              EV
            </Typography>
          ) : null}
        </div>
      </div>
      <Button
        className={classes.menuButton}
        color="inherit"
        onClick={() => setShowNav((prev) => !prev)}
      >
        <MenuIcon className={classes.navBtn} />
      </Button>
      <div className={classes.list} role="presentation">
        <nav
          style={{
            marginTop: 6,
            width: showNav ? 180 : "100%",
          }}
        >
          {menu.map((item, index) => 
          (item?.show) &&
              (
                <NavLink
                  key={index}
                  to={item.path}
                  isActive={() => item.urlArray.includes(pathname)}
                  activeClassName="active-nav"
                  className="normal-nav"
                >
                  <ListItem
                    button
                    key={item.title}
                    className={classes.sideTexRow}
                  >
                    <i
                      className={classes.icon}
                      style={{
                        fontSize: showNav ? 16 : 18,
                      }}
                    >
                      {item.Icon}
                    </i>
                    {showNav ? (
                      <p className={classes.navText}>{item.title}</p>
                    ) : null}
                  </ListItem>
                </NavLink>
              )
            
          )}
        </nav>
      </div>
    </div>
  );
}

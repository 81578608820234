import gql from "graphql-tag";

export const FETCH_CHARGE_COMPUTE = gql`
  query getCostComputationData(
    $limit: String
    $prevPage: String
    $token : String!
    $userEmail: String!
    $cursor: String
    $searchStatus : String
    $searchColumn : String
    $searchValue : String
    ) {
    getCostComputationData(
      limit: $limit
      prevPage: $prevPage
      userEmail: $userEmail
      token : $token
      cursor: $cursor
      searchStatus : $searchStatus
      searchColumn : $searchColumn
      searchValue : $searchValue
      ) {
      status
      statusBool
      CostCalculatorInfo {
        id
        user_email
        address
        address_lat
        address_lng
        vehicle_id
        brand
        model
        variant
        distance_driven_per_annum
        weekday_range
        weekday_range_trip
        weekend_range
        weekend_range_trip
        furthest_travel
        furthest_travel_location
        furthest_travel_lat
        furthest_travel_lng
        urban_driving_percentage
        socket_type
        charger_type
        ev_charger_availability
        charger_network
        work
        home
        parking_place
        recommendations
        }
        pageInfo{
          prevPageStatus
          nextPageStatus
          prevCursor
          nextCursor
        }
    }
  }
`;

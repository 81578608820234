import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,

} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { StatusText } from "../../Component/Text";
import {
  PrevButton,
  NextButton,
  RefreshButton,
  SearchBox,
  SearchButton,
} from "../../Component/ButtonComponent";


import Loading from "../../Component/Loading";

import { FETCH_FIND_EV } from "./api";

import { EmailContext } from "../../Context/EmailContext";

export default function ComputeEv() {
  const { email, token} = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  // const [outcome, setOutcome] = useState({});
  // const [errors, setErrors] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [searchColumnValue, setSearchColumnValue] = useState("");

  // const [formModal, setFormModal] = useState({
  //   open: false,
  //   title: "",
  //   check: "",
  // });

  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error, fetchMore } = useQuery(FETCH_FIND_EV, {
    variables: { userEmail: email, token },
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onChange2 = (event) => {
    setSearchColumnValue(event.target.value);
  };

  /* <<<=========      MODAL CLOSE/OPEN FUNC   ===========>>>  */

  async function formatData(obj) {
    const rec = JSON.parse(obj.recommendations);

    rec.sort(function (a, b) {
      return parseFloat(b.score) - parseFloat(a.score);
    });

    obj["scoreCard"] = rec;
    return obj;
  }

  const handleOpen = async (obj) => {
    const getformattedData = await formatData(obj);
    setModalInfo(getformattedData);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    FORM MODAL FUNC   ===========>>>  */

  // const handleOpenForm = (title, check) => {
  //   setOpen(false);
  //   setFormModal({
  //     ...formModal,
  //     open: true,
  //     title: title,
  //     check: check,
  //   });
  // };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>FInd My EV Data</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>FInd My EV Data</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.searchRowDiv}>
          <div className={classes.searchRow}>
            <SearchBox
              value={searchValue}
              onChange={(text) => onChange(text)}
            />

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                className={classes.selectorLabel}
                id="demo-simple-select-outlined-label"
              >
                Select search
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="searchColumnValue"
                value={searchColumnValue}
                className={classes.selectorStyle}
                onChange={(text) => onChange2(text)}
                label="Select Search"
              >
                <MenuItem value="">
                  <em>Select Search</em>
                </MenuItem>
                <MenuItem value={'body_style'}>Body Style</MenuItem>
                <MenuItem value={'user_email'}>User Email</MenuItem>
                <MenuItem value={'weekday_range'}>Weekend Range '&gt;'=</MenuItem>
                <MenuItem value={'weekend_range'}>Weekday Range '&gt;'=</MenuItem>
                <MenuItem value={'budget_min'}>Budget Min '&lt;'=</MenuItem>
                <MenuItem value={'budget_max'}>Budget Max '&gt;'=</MenuItem>
              </Select>
            </FormControl>

            <SearchButton  onClick={(e) => {
              fetchMore({
                variables: {
                  searchStatus: "True",
                  searchColumn: searchColumnValue,
                  searchValue: searchValue,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  return fetchMoreResult;
                },
              });
            }} />

            <RefreshButton 
             onClick={() => {
              fetchMore({
                variables: {
                  userEmail: email,
                  token
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  return fetchMoreResult;
                },
              });
            }}/>
          </div>
          {/* <CommonAddButton
            title="Add Vehicle"
            // onClick={() => handleOpenForm("Add Events")}
          /> */}
        </div>

        {/* {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText title={"Something Went Wrong!"} fColor={Colors.error} />
            )} */}

        {/* ========  ADD FORM MODAL   ======== */}

        {loading ? (
          <Loading />
        ) : (
          <>
            {data?.getFindEvData?.status === false ? (
              <StatusText
                title={data?.getFindEvData?.status}
                fColor={Colors.black}
              />
            ) : (
              <div className={classes.bodyDiv}>
                {data && data?.getFindEvData?.FindMyEvRecommendations.length ? (
                  <>
                    <TableContainer
                      elevation={0.5}
                      className={classes.table}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              User Email
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Address
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Budget Min
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Budget Max
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Weekday Range
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Weekend Range
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getFindEvData?.FindMyEvRecommendations.map(
                            (obj, index) => {
                              return (
                                <StyledTableRow
                                  key={index}
                                  onClick={() => handleOpen(obj)}
                                  hover={true}
                                  className={classes.tableRow}
                                >
                                  <StyledTableCell align="center">
                                    {obj.user_email}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.address}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {obj.budget_min}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.budget_max}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.weekday_range}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.weekend_range}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className={classes.paginationDiv}>
                      {data &&
                          data?.getFindEvData?.pageInfo?.prevPageStatus ===
                            "True" ? (
                            <PrevButton
                              onClick={() => {
                                const nextCursor =
                                  data?.getFindEvData?.pageInfo?.prevCursor;
                                fetchMore({
                                  variables: {
                                    cursor: nextCursor,
                                    userEmail: email,
                                    prevPage: "True",
                                    token
                                  },
                                  updateQuery: (
                                    prevResult,
                                    { fetchMoreResult }
                                  ) => {
                                    return fetchMoreResult;
                                  },
                                });
                              }}
                            />
                          ) : null}

                      {data &&
                          data?.getFindEvData?.pageInfo?.nextPageStatus ===
                            "True" ? (
                            <NextButton
                              onClick={() => {
                                const nextCursor =
                                  data?.getFindEvData?.pageInfo?.nextCursor;
                                fetchMore({
                                  variables: {
                                    cursor: nextCursor,
                                    userEmail: email,
                                    token
                                  },
                                  updateQuery: (
                                    prevResult,
                                    { fetchMoreResult }
                                  ) => {
                                    return fetchMoreResult;
                                  },
                                });
                              }}
                            />
                          ) : null}
                    </div>
                  </>
                ) : (
                  <StatusText title={"No Data"} fColor={Colors.black} />
                )}

                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={mStyles.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={mStyles.paper}>
                        <div className={mStyles.formModalContent}>
                          <div className={mStyles.floatLeft}>
                            Find My Ev -{modalInfo?.user_email}
                          </div>
                        </div>

                        <div className={mStyles.formModalInnerContent}>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Id </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.id}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> User Email </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.user_email}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Address </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.address}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Address Lat </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.address_lat}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Address Lng </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.address_lng}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Body Style </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.body_style}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              model Availability{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.model_availability}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Budget Min </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.budget_min}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Budget Max </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.budget_max}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Weekday Range </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.weekday_range}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Weekday Range Trip{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.weekday_range_trip}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Weekend Range </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.weekend_range}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Weekend Range Trip{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.weekend_range_trip}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Furthest Travel{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.furthest_travel}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Furthest Travel Location{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.furthest_travel_location}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Furthest Travel Lat{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.furthest_travel_lat}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Furthest Travel Lng{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.furthest_travel_lng}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Parking Availability{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {" "}
                              {modalInfo?.parking_availability}{" "}
                            </p>{" "}
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <Divider className={mStyles.dividerLine} />
                          <p className={mStyles.textCenter}>
                            RECOMMENDATIONS
                          </p>{" "}
                          {modalInfo?.scoreCard.map((obj) => (
                            <div className={mStyles.rowView}>
                              <p className={mStyles.textLeft}>Score</p>
                              <p className={mStyles.textRight}>
                                {obj.score.toFixed(2)}{" "}
                              </p>

                              <p className={mStyles.textLeft}> Model Name</p>
                              <p className={mStyles.textRight}>
                                {obj.modelName}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_ASSUMPTION_CONSTANT, FETCH_ASSUMPTION_DATA } from "./api";

export default function EdiAssumptionConstants({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  // console.log(updateValues)

  const [values, setValues] = useState({
    userEmail: email,
    token : token,
    petrolPrice: updateValues ? updateValues?.petrol_price : "",
    evMaintenance: updateValues ? updateValues?.ev_maintenance : "",
    petrolMaintenance: updateValues ? updateValues?.petrol_maintenance : "",
    evInsurance: updateValues ? updateValues?.ev_insurance : "",
    petrolInsurance: updateValues ? updateValues?.petrol_insurance : "",
    evTyres: updateValues ? updateValues?.tyres_ev : "",
    petrolTyres: updateValues ? updateValues?.tyres_petrol : "",
    depPeriodEv: updateValues ? updateValues?.depreciation_period_ev : "",
    depPeriodPetrol: updateValues
      ? updateValues?.depreciation_period_petrol
      : "",
    scrapValueEv: updateValues ? updateValues?.scrap_value_ev : "",
    scrapValuePetrol: updateValues ? updateValues?.scrap_value_petrol : "",
    emissionsEv: updateValues ? updateValues?.emissions_ev : "",
    emissionsPetrol: updateValues ? updateValues?.emissions_petrol : "",
    depYear: updateValues ? updateValues?.dep_year : "",
    qId: updateValues ? updateValues?.id : "",
  });

  const [updateAssumptionConstant] = useMutation(UPDATE_ASSUMPTION_CONSTANT, {
    update(_, result) {
      setOutcome(result?.data?.updateAssumptionConstant);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ASSUMPTION_DATA,
        variables: { userEmail: email,  token:token  },
      },
    ],
  });

  function onSubmit() {
    setOutcome(false);
    setErrors(false);
    updateAssumptionConstant({
      variables: values,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: Number(event.target.value) });
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Petrol Price
              </Typography>{" "}
              <TextInputField
                type="number"
                id="petrolPrice"
                placeholder="Petrol price"
                name="petrolPrice"
                value={values.petrolPrice}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Ev Maintenance
              </Typography>{" "}
              <TextInputField
                type="number"
                id="evMaintenance"
                placeholder="Ev maintenance"
                name="evMaintenance"
                value={values.evMaintenance}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Petrol Maintenance
              </Typography>{" "}
              <TextInputField
                type="number"
                id="petrolMaintenance"
                placeholder="Petrol Maintenance"
                name="petrolMaintenance"
                value={values.petrolMaintenance}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Ev Insurance
              </Typography>{" "}
              <TextInputField
                type="number"
                id="evInsurance"
                placeholder="Ev Insurance"
                name="evInsurance"
                value={values.evInsurance}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Petrol Insurance
              </Typography>{" "}
              <TextInputField
                type="number"
                id="petrolInsurance"
                placeholder="Petrol Insurance"
                name="petrolInsurance"
                value={values.petrolInsurance}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Tyres Ev
              </Typography>{" "}
              <TextInputField
                type="number"
                id="evTyres"
                placeholder="Tyres Ev"
                name="evTyres"
                value={values.evTyres}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Tyres Petrol
              </Typography>{" "}
              <TextInputField
                type="number"
                id="petrolTyres"
                placeholder="Tyres Petrol"
                name="petrolTyres"
                value={values.petrolTyres}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Depreciation Period Ev
              </Typography>{" "}
              <TextInputField
                type="number"
                id="depPeriodEv"
                placeholder="Depreciation Period Ev"
                name="depPeriodEv"
                value={values.depPeriodEv}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Depreciation Period Petrol{" "}
              </Typography>{" "}
              <TextInputField
                type="number"
                id="depPeriodPetrol"
                placeholder="Depreciation Period Petrol"
                name="depPeriodPetrol"
                value={values.depPeriodPetrol}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Scrap Value Ev
              </Typography>{" "}
              <TextInputField
                type="number"
                id="scrapValueEv"
                placeholder="Scrap Value Ev"
                name="scrapValueEv"
                value={values.scrapValueEv}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Scrap Value Petrol
              </Typography>{" "}
              <TextInputField
                type="number"
                id="scrapValuePetrol"
                placeholder="Scrap Value Petrol"
                name="scrapValuePetrol"
                value={values.scrapValuePetrol}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Depreciation Year
              </Typography>{" "}
              <TextInputField
                type="number"
                id="depYear"
                placeholder="Depreciation Year"
                name="depYear"
                value={values.depYear}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Emissions Ev
              </Typography>{" "}
              <TextInputField
                type="number"
                id="emissionsEv"
                placeholder="Emissions Ev"
                name="emissionsEv"
                value={values.emissionsEv}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Emissions Petrol
              </Typography>{" "}
              <TextInputField
                type="number"
                id="emissionsPetrol"
                placeholder="Emissions Petrol"
                name="emissionsPetrol"
                value={values.emissionsPetrol}
                onChange={onChange}
              />
            </div>

            {outcome && outcome === true && (
              <StatusText
                title={"Updated Successfully"}
                fColor={Colors.black}
              />
            )}

            {errors && errors === true && (
              <StatusText
                title={"Something Went Wrong"}
                fColor={Colors.black}
              />
            )}

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
    </div>
  );
}

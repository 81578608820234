import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { StatusText } from "../../Component/Text";
import {
  RefreshButton,
  SearchBox,
  SearchButton,
  PrevButton,
  NextButton
} from "../../Component/ButtonComponent";


import Loading from "../../Component/Loading";

import { FETCH_CHARGE_COMPUTE } from "./api";

import { EmailContext } from "../../Context/EmailContext";

export default function ComputeCost() {
  const { email, token } = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  // const [outcome, setOutcome] = useState({});
  // const [errors, setErrors] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [searchColumnValue, setSearchColumnValue] = useState("");

  // const [formModal, setFormModal] = useState({
  //   open: false,
  //   title: "",
  //   check: "",
  // });

  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error, fetchMore } = useQuery(FETCH_CHARGE_COMPUTE, {
    variables: { userEmail: email, token },
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onChange2 = (event) => {
    setSearchColumnValue(event.target.value);
  };

  /* <<<=========      MODAL CLOSE/OPEN FUNC   ===========>>>  */

  async function formatData(obj) {
    const rec = JSON.parse(obj.recommendations);

    obj["vehicleName"] = rec["vehicleName"];
    obj["costOfVehile"] = rec["costOfVehile"];
    obj["kmsDrivenPerAnnum"] = rec["kmsDrivenPerAnnum"];
    obj["depreciationAnnually"] = rec["runningCosts"]["depreciation"]["annually"];
    obj["depreciationFiveYear"] = rec["runningCosts"]["depreciation"]["fiveYear"];


    obj["taxesAndDutyAnnually"] = rec["runningCosts"]["rcAnnually"]["irt"];
    obj["taxesAndDutyFiveYear"] = rec["runningCosts"]["rcFiveYear"]["irt"];
    obj["maintenanceServiceTyreAnnually"] =
      rec["runningCosts"]["rcAnnually"]["mst"]
    obj["maintenanceServiceTyreFiveYear"] =
      rec["runningCosts"]["rcFiveYear"]["mst"]
    obj["fuelAnnually"] = rec["runningCosts"]["rcAnnually"]["fuel"]
    obj["fuelFiveYears"] = rec["runningCosts"]["rcFiveYear"]["fuel"];
    obj["totalAnnually"] = rec["runningCosts"]["rcAnnually"]["total"];
    obj["totalFiveYear"] = rec["runningCosts"]["rcFiveYear"]["total"];




    obj["runningCostPerKm"] = rec["runningCosts"]["runningCostPerKm"];

    obj["totalOwnerShipCostsAnnually"] =
      rec["runningCosts"]["totalOwnerShipCosts"]["annually"];
    obj["totalOwnerShipCostsFiveYear"] =
      rec["runningCosts"]["totalOwnerShipCosts"]["fiveYear"];

    obj["availability"] = rec["report"]["availability"];
    obj["ancap"] = rec["report"]["ancap"];
    obj["chargingAnalysis"] = rec["report"]["chargingAnalysis"];
    obj["emissions"] = rec["report"]["emissions"]['grid'];
    obj["solar"] = rec["report"]["emissions"]['solar'];

    obj["modelName"] = rec["report"]["vehicleSummary"]["modelName"];
    obj["picture"] = rec["report"]["vehicleSummary"]["picture"];
    obj["price"] = rec["report"]["vehicleSummary"]["price"];
    obj["range"] = rec["report"]["vehicleSummary"]["range"];
    obj["runningCostsPerWeek"] =
      rec["report"]["vehicleSummary"]["runningCostsPerWeek"];
    obj["runningCostsPerMonth"] =
      rec["report"]["vehicleSummary"]["runningCostsPerMonth"];
    obj["engine"] = rec["report"]["vehicleSummary"]["engine"];
    obj["seats"] = rec["report"]["vehicleSummary"]["seats"];
    obj["boot"] = rec["report"]["vehicleSummary"]["boot"];
    console.log(obj)
    return obj;
  }

  const handleOpen = async (obj) => {
    const getformattedData = await formatData(obj);
    setModalInfo(getformattedData);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    FORM MODAL FUNC   ===========>>>  */

  // const handleOpenForm = (title, check) => {
  //   setOpen(false);
  //   setFormModal({
  //     ...formModal,
  //     open: true,
  //     title: title,
  //     check: check,
  //   });
  // };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Charging Calculator Info</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Charging Calculator Info</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.searchRowDiv}>
          <div className={classes.searchRow}>
            <SearchBox
              value={searchValue}
              onChange={(text) => onChange(text)}
            />

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                className={classes.selectorLabel}
                id="demo-simple-select-outlined-label"
              >
                Select search
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="searchColumnValue"
                value={searchColumnValue}
                className={classes.selectorStyle}
                onChange={(text) => onChange2(text)}
                label="Select Search"
              >
                <MenuItem value="">
                  <em>Select Search</em>
                </MenuItem>
                <MenuItem value={'model'}>Model Name</MenuItem>
                <MenuItem value={'brand'}>Manufacturer</MenuItem>
                <MenuItem value={'variant'}>Variant</MenuItem>
                <MenuItem value={'user_email'}>User Email</MenuItem>\
                <MenuItem value={'electricity_price'}>Electricity Price '&gt;'=</MenuItem>
                <MenuItem value={'weekday_range'}>Weekend Range '&gt;'=</MenuItem>
                <MenuItem value={'weekend_range'}>Weekday Range '&gt;'=</MenuItem>
                <MenuItem value={'furthest_travel'}>Furthest Travel '&gt;'=</MenuItem>
                <MenuItem value={'urban_driving_percentage'}>Urban Driving Percentage '&gt;'=</MenuItem>
              </Select>
            </FormControl>

            <SearchButton onClick={(e) => {
              fetchMore({
                variables: {
                  searchStatus: "True",
                  searchColumn: searchColumnValue,
                  searchValue: searchValue,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  return fetchMoreResult;
                },
              });
            }} />

            <RefreshButton
              onClick={() => {
                fetchMore({
                  variables: {
                    userEmail: email,
                    token : token
                  },
                  updateQuery: (prevResult, { fetchMoreResult }) => {
                    return fetchMoreResult;
                  },
                });
              }}
            />
          </div>
          {/* <CommonAddButton
            title="Add Vehicle"
            // onClick={() => handleOpenForm("Add Events")}
          /> */}
        </div>

        {/* {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText title={"Something Went Wrong!"} fColor={Colors.error} />
            )} */}

        {/* ========  ADD FORM MODAL   ======== */}

        {loading ? (
          <Loading />
        ) : (
          <>
            {data?.getCostComputationData?.status === false ? (
              <StatusText
                title={data?.getCostComputationData?.status}
                fColor={Colors.black}
              />
            ) : (
              <div className={classes.bodyDiv}>
                {data &&
                  data?.getCostComputationData?.CostCalculatorInfo.length ? (
                  <>
                    <TableContainer
                      elevation={0.5}
                      className={classes.table}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              User Email
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Address
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Brand
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Model
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Variant
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getCostComputationData?.CostCalculatorInfo.map(
                            (obj, index) => {
                              return (
                                <StyledTableRow
                                  key={index}
                                  onClick={() => handleOpen(obj)}
                                  hover={true}
                                  className={classes.tableRow}
                                >
                                  <StyledTableCell align="center">
                                    {obj.user_email}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.address}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.brand}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {obj.model}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.variant}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className={classes.paginationDiv}>
                      {data &&
                        data?.getCostComputationData?.pageInfo?.prevPageStatus ===
                        "True" ? (
                        <PrevButton
                          onClick={() => {
                            const nextCursor =
                              data?.getCostComputationData?.pageInfo?.prevCursor;
                            fetchMore({
                              variables: {
                                cursor: nextCursor,
                                userEmail: email,
                                token : token,
                                prevPage: "True",
                              },
                              updateQuery: (
                                prevResult,
                                { fetchMoreResult }
                              ) => {
                                return fetchMoreResult;
                              },
                            });
                          }}
                        />
                      ) : null}

                      {data &&
                        data?.getCostComputationData?.pageInfo?.nextPageStatus ===
                        "True" ? (
                        <NextButton
                          onClick={() => {
                            const nextCursor =
                              data?.getCostComputationData?.pageInfo?.nextCursor;
                            fetchMore({
                              variables: {
                                cursor: nextCursor,
                                userEmail: email,
                                token : token
                              },
                              updateQuery: (
                                prevResult,
                                { fetchMoreResult }
                              ) => {
                                return fetchMoreResult;
                              },
                            });
                          }}
                        />
                      ) : null}
                    </div>
                  </>
                ) : (
                  <StatusText title={"No Data"} fColor={Colors.black} />
                )}

                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={mStyles.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={mStyles.paper}>
                        <div className={mStyles.formModalContent}>
                          <div className={mStyles.floatLeft}>
                            Cost Computation -{modalInfo?.brand}{" "}
                            {modalInfo?.model}
                            {modalInfo?.variant}
                          </div>
                        </div>

                        <div className={mStyles.formModalInnerContent}>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>id</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.id}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>user_email</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.user_email}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>address</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.address}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>address_lat</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.address_lat}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>address_lng</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.address_lng}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>vehicle_id</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.vehicle_id}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>brand</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.brand}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>model</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.model}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>variant</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.variant}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              distance_driven_per_annum
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.distance_driven_per_annum}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>weekday_range</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekday_range}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              weekday_range_trip
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekday_range_trip}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>weekend_range</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekend_range}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              weekend_range_trip
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekend_range_trip}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>furthest_travel</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.furthest_travel}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              furthest_travel_location
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.furthest_travel_location}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              furthest_travel_lat
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.furthest_travel_lat}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              furthest_travel_lng
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.furthest_travel_lng}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              urban_driving_percentage
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.urban_driving_percentage}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>socket_type</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.socket_type}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>charger_type</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.charger_type}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              ev_charger_availability
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ev_charger_availability}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>charger_network</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.charger_network}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>work</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.work}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>home</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.home}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>parking_place</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.parking_place}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>vehicleName</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.vehicleName}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>costOfVehile</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.costOfVehile}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              kmsDrivenPerAnnum
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.kmsDrivenPerAnnum}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              depreciationAnnually
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.depreciationAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              depreciationFiveYear
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.depreciationFiveYear}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              taxesAndDutyAnnually
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.taxesAndDutyAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              taxesAndDutyFiveYear
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.taxesAndDutyFiveYear}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              maintenanceServiceTyreAnnually
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.maintenanceServiceTyreAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              maintenanceServiceTyreFiveYear
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.maintenanceServiceTyreFiveYear}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>fuelAnnually</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.fuelAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>fuelFiveYears</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.fuelFiveYears}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>totalAnnually</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.totalAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>totalFiveYear</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.totalFiveYear}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>runningCostPerKm</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.runningCostPerKm}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              totalOwnerShipCostsAnnually
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.totalOwnerShipCostsAnnually}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              totalOwnerShipCostsFiveYear
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.totalOwnerShipCostsFiveYear}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>availability</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.availability}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>ancap</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ancap}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>chargingAnalysis - </p>{" "}
                            <p className={mStyles.textLeft}>fullChargeHrs </p>{" "}
                            <p className={mStyles.textRight}>
                              {
                                modalInfo?.chargingAnalysis['fullChargeHrs']
                              }{" "}
                            </p>
                            <p className={mStyles.textLeft}> longestTripHrs </p>{" "}
                            <p className={mStyles.textRight}>
                              {
                                modalInfo?.chargingAnalysis['longestTripHrs']
                              }{" "}
                            </p>
                            <p className={mStyles.textLeft}> weekdayTripHrs </p>{" "}
                            <p className={mStyles.textRight}>
                              {

                                modalInfo?.chargingAnalysis['weekdayTripHrs']

                              }{" "}
                            </p>
                            <p className={mStyles.textLeft}> weekendTripHr </p>{" "}
                            <p className={mStyles.textRight}>
                              {
                                modalInfo?.chargingAnalysis['weekendTripHrs']
                              }{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>emissions</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.emissions}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>modelName</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.modelName}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>picture</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.picture}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>price</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.price}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>range</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.range}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              runningCostsPerWeek
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.runningCostsPerWeek}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              runningCostsPerMonth
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.runningCostsPerMonth}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>engine</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.engine}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>seats</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.seats}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>boot</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.boot}{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

import gql from "graphql-tag";


export const FETCH_BODY_AND_CHASIS = gql`
  query  getBodyChasis($qId: String!){
    getBodyChasis(qId: $qId){
        vid
        seats
        isofix_num
        isofix
        body_style
        segment
        ev_platform
        ev_dedicated_platform
        roof_rails
        turning_circle
        boot
        boot_max
        dimension_l
        dimension_h
        dimension_w
        cargo_capacity
        cargo_capacity_max
        cargo_capacity_frunk
        roof_load
        tow_hitch_possible
        tow_weight_unbraked
        tow_weight_braked
        wheel_base
        curb_weight
        gross_weight
        max_payload
        vertical_load_max
        ground_clearance
                }
  }
`;


export const CREATE_BODY_CHASE_DATA = gql`
  mutation  inputBodyAndChaseDetail(
    $userEmail: String!
    $token : String!
    $vid: String!
    $seats: Float
    $isofixNum: Float
    $isofix: String
    $bodyStyle: String
    $segment: String
    $evPlatform: String
    $evDedicatedPlatform: String
    $roofRails: String
    $turningCircle: Float
    $boot: Float
    $bootMax: Float
    $groundClearance: Float
    $dimensionL: Float
    $dimensionH: Float
    $dimensionW: Float
    $cargoCapacity: Float
    $cargoCapacityMax: Float
    $cargoCapacityFrunk: Float
    $roofLoad: Float
    $towHitchPossible: String
    $towWeightBraked: Float
    $towWeightUnbraked: Float
    $wheelBase: Float
    $curbWeight: Float
    $grossWeight: Float
    $maxPayload: Float
    $verticalLoadMax: Float

  ) {
    inputBodyAndChaseDetail(
      userEmail: $userEmail
      vid: $vid
      token : $token
      seats: $seats
      isofixNum: $isofixNum
      isofix: $isofix
      bodyStyle: $bodyStyle
      segment: $segment
      evPlatform: $evPlatform
      evDedicatedPlatform: $evDedicatedPlatform
      roofRails: $roofRails
      turningCircle: $turningCircle
      boot: $boot
      bootMax : $bootMax
      groundClearance : $groundClearance
      dimensionL: $dimensionL
      dimensionH: $dimensionH
      dimensionW: $dimensionW
      cargoCapacity: $cargoCapacity
      cargoCapacityMax: $cargoCapacityMax
      cargoCapacityFrunk: $cargoCapacityFrunk
      roofLoad: $roofLoad
      towHitchPossible: $towHitchPossible
      towWeightBraked: $towWeightBraked
      towWeightUnbraked: $towWeightUnbraked
      wheelBase: $wheelBase
      curbWeight: $curbWeight
      grossWeight: $grossWeight
      maxPayload: $maxPayload
      verticalLoadMax: $verticalLoadMax
    ) {
      status
      statusBool
    }
  }
`;


export const UPDATE_BODY_CHASE_DATA = gql`
  mutation updateBodyAndChaseDetail(
    $userEmail: String!
    $token : String!
    $vid: String!
    $seats: Float
    $isofixNum: Float
    $isofix: String
    $bodyStyle: String
    $segment: String
    $evPlatform: String
    $evDedicatedPlatform: String
    $roofRails: String
    $turningCircle: Float
    $boot: Float
    $bootMax: Float
    $groundClearance: Float
    $dimensionL: Float
    $dimensionH: Float
    $dimensionW: Float
    $cargoCapacity: Float
    $cargoCapacityMax: Float
    $cargoCapacityFrunk: Float
    $roofLoad: Float
    $towHitchPossible: String
    $towWeightBraked: Float
    $towWeightUnbraked: Float
    $wheelBase: Float
    $curbWeight: Float
    $grossWeight: Float
    $maxPayload: Float
    $verticalLoadMax: Float
    ) {
      updateBodyAndChaseDetail(
        userEmail: $userEmail
        token : $token
        vid: $vid
        seats: $seats
        isofixNum: $isofixNum
        isofix: $isofix
        bodyStyle: $bodyStyle
        segment: $segment
        evPlatform: $evPlatform
        evDedicatedPlatform: $evDedicatedPlatform
        roofRails: $roofRails
        turningCircle: $turningCircle
        boot: $boot
        bootMax : $bootMax
        groundClearance : $groundClearance
        dimensionL: $dimensionL
        dimensionH: $dimensionH
        dimensionW: $dimensionW
        cargoCapacity: $cargoCapacity
        cargoCapacityMax: $cargoCapacityMax
        cargoCapacityFrunk: $cargoCapacityFrunk
        roofLoad: $roofLoad
        towHitchPossible: $towHitchPossible
        towWeightBraked: $towWeightBraked
        towWeightUnbraked: $towWeightUnbraked
        wheelBase: $wheelBase
        curbWeight: $curbWeight
        grossWeight: $grossWeight
        maxPayload: $maxPayload
        verticalLoadMax: $verticalLoadMax
    )
  }
`;
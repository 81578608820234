import gql from "graphql-tag";


export const FETCH_LOGO = gql`
  query  getAllManufacturer{
    getAllManufacturer{
        id
        manuf_name
        logo_link
    }
  }
`;

export const FETCH_PARTICULAR_LOGO = gql`
  query  getManufacturer($qId: Float!){
    getManufacturer(qId : $qId){
        id
        manuf_name
        logo_link
    }
  }
`;


export const GET_SIGNED_URL_LOGO = gql`
  mutation signedUrlForLogo(
    $fileType: String!
    $fileName: String!
    $manufactuer: String!
    $userEmail: String!
  ) {
    signedUrlForLogo(
        fileType: $fileType
        fileName: $fileName
        manufactuer: $manufactuer
        userEmail: $userEmail
    ) {
        signedRequest
        url
        error
    }
  }
`;


export const CREATE_MANUFACTURER_DATA = gql`
  mutation inputManufacturer(
    $logoLink: String!
    $manufacturereName: String!
    $userEmail: String!
    $token : String!
  ) {
    inputManufacturer(
      token : $token
        logoLink : $logoLink
        manufacturereName : $manufacturereName
        userEmail : $userEmail
    ) {
      status
      statusBool
      error
    }
  }
`;



export const DELETE_LOGO = gql`
  mutation deleteManufacturer(
      $qId:Float!
      $token : String!
      $userEmail: String!) {
    deleteManufacturer(
        qId: $qId
        token : $token
      userEmail: $userEmail
    )
  }
`;

export const UPDATE_MANUF_DATA = gql`
  mutation updateManufacturer(
    $logoLink: String!
    $token : String!
    $manufacturereName: String!
    $userEmail: String!
    $qId: Float!
    ) {
        updateManufacturer(
            logoLink:$logoLink
            token : $token
            manufacturereName:$manufacturereName
            userEmail:$userEmail
            qId:$qId
  
    )
  }
`;

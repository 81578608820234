import React, { useState, useEffect, useContext } from "react";
import { useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Divider, Typography } from "@material-ui/core";

import { formStyle, modalStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import {
  UpdateButton,
  CommonAddButton,
  CommonEditButton,
} from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import {
  FETCH_WARRANTY_DETAILS,
  CREATE_WARRANTY_DETAIL,
  UPDATE_WARRANTY_DETAIL,
} from "./api";
import { FETCH_ALL_VEHICLE_DATA } from "../VehicleDetails/api";

import { EmailContext } from "../../Context/EmailContext";

export default function WarrantyDetails({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();
  const mStyles = modalStyle();

  const [outcome, setOutcome] = useState("");
  const [outcome2, setOutcome2] = useState("");
  const [errors, setErrors] = useState("");

  const [edit, setEdit] = useState(false);

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : "",
    userEmail: email,
    token : token,
    carWarranty: "",
    batteryWarranty: "",
  });

  const [callThePricingApi, { loading, data, error }] = useLazyQuery(
    FETCH_WARRANTY_DETAILS
  );

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid;
      callThePricingApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.getWarrantytDetail) {
      const { getWarrantytDetail } = data;
      setValues({
        ...values,
        vid: getWarrantytDetail?.vid,
        carWarranty: getWarrantytDetail?.car_warranty,
        batteryWarranty: getWarrantytDetail?.battery_warranty,
      });
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  /* <<<=========    useMutation   ===========>>>  */

  const [createWarrantyInfo] = useMutation(CREATE_WARRANTY_DETAIL, {
    update(_, result) {
      setOutcome(result?.data?.inputWarrantyDetail?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_WARRANTY_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  const [updateWarrantyInfo] = useMutation(UPDATE_WARRANTY_DETAIL, {
    update(_, result) {
      setOutcome2(result?.data?.updateWarrantyDetail);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token },
      },
      {
        query: FETCH_WARRANTY_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  });

  async function callCreateAPI() {
    setOutcome({});
    setErrors({});

    await createWarrantyInfo({
      variables: values,
    });
  }

  async function callEditAPI() {
    setOutcome({});
    setErrors({});
    // updateData();

    await updateWarrantyInfo({
      variables: values,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
      </div>
    );
  }

  return (
    <div className={formClass.formDiv}>
      {loading ? (
        <>loading ...</>
      ) : (
        <>
          {updateValues ? (
            <>
              {edit ? (
                <div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Car Warranty
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Car Warranty"
                      name="carWarranty"
                      value={values.carWarranty}
                      onChange={onChange}
                    />
                  </div>
                  <div className={formClass.formRow}>
                    {" "}
                    <Typography className={formClass.leftText}>
                      Battery Warranty
                    </Typography>
                    <TextCustom
                      id="en"
                      type="text"
                      placeholder="Enter Battery Warranty"
                      name="batteryWarranty"
                      value={values.batteryWarranty}
                      onChange={onChange}
                    />
                  </div>

                  {outcome && Object.keys(outcome).length !== 0 && (
                    <StatusText title={outcome} fColor={Colors.black} />
                  )}
                  {outcome2 && (
                    <StatusText
                      title={"Updated Successfully"}
                      fColor={Colors.black}
                    />
                  )}
                  {errors && Object.keys(errors).length !== 0 && (
                    <StatusText
                      title={"Something Went Wrong Cannot Be Created"}
                      fColor={Colors.black}
                    />
                  )}

                  <UpdateButton
                    title={"cancel"}
                    onClick={() => setEdit(false)}
                  />
                  {data?.getWarrantytDetail ? (
                    <CommonEditButton
                      title={"Update"}
                      onClick={() => callEditAPI()}
                    />
                  ) : (
                    <CommonAddButton
                      title={"Create"}
                      onClick={() => callCreateAPI()}
                    />
                  )}
                </div>
              ) : (
                <div>
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Vid</p>
                    <p className={mStyles.textRight}>{values.vid}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Car Warranty</p>
                    <p className={mStyles.textRight}>{values.carWarranty}</p>
                  </div>
                  <Divider className={mStyles.dividerLine} />
                  <div className={mStyles.rowView}>
                    <p className={mStyles.textLeft}>Battery Warranty</p>
                    <p className={mStyles.textRight}>
                      {values.batteryWarranty}
                    </p>
                  </div>

                  <UpdateButton title={"Edit"} onClick={() => setEdit(true)} />
                </div>
              )}
            </>
          ) : (
            <StatusText
              title={"Something Went Wrong Could Not be Updated"}
              fColor={Colors.black}
            />
          )}
        </>
      )}
    </div>
  );
}

import React, { useState,useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import {  Typography } from '@material-ui/core'

import { EmailContext } from '../../Context/EmailContext'

import { formStyle, modalStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import {
  UpdateButton,
  CommonAddButton,
} from '../../Component/ButtonComponent'
import { TextCustom } from '../../Component/InputComponents'

import {
  ADD_DEMO_LINK
} from './api';
import { FETCH_ALL_VEHICLE_DATA } from '../VehicleDetails/api'

export default function BookDemo({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()
  const mStyles = modalStyle()

  const [outcome2, setOutcome2] = useState('')
  const [errors, setErrors] = useState('')

  const [edit, setEdit] = useState(false)

  const [values, setValues] = useState({
    qId: updateValues ? updateValues.vid : '',
    userEmail: email,
    token : token,
    demoLink: updateValues ? updateValues.book_demo : '',
  })

  /* <<<=========  useMutation   ===========>>>  */

  const [updateDemoLink] = useMutation(ADD_DEMO_LINK, {
    update(_, result) {
      setOutcome2(result?.data?.addDemoLink)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VEHICLE_DATA,
        variables: { userEmail: email, token }
      }
    ],
  })

  /* <<<=========      API CALL   ===========>>>  */

  async function callEditAPI() {
    setOutcome2('')
    setErrors('')
    // updateData();

    await updateDemoLink({
      variables: values,
    })
  }
  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
      setValues({ ...values, [event.target.name]: event.target.value })


  }

  /* <<<=========    CONTENT   ===========>>>  */
  if (!updateValues) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
      </div>
    )
  }
  return (
    <div className={formClass.formDiv}>
     
        {updateValues ? (
          <>
            {edit ? (
              <div>
                <div className={formClass.formRow}>
                  <Typography className={formClass.leftText}>
                   Demo Link
                  </Typography>
                  <TextCustom
                    id="en"
                    type="text"
                    placeholder="demoLink"
                    name="demoLink"
                    value={values.demoLink}
                    onChange={onChange}
                  />
                </div>
                

                {/* {outcome2 && Object.keys(outcome2).length !== 0 && (
                  <StatusText title={outcome2} fColor={Colors.black} />
                )} */}
                {outcome2 && (
                  <StatusText title={'Updated Successfully'} fColor={Colors.black} />
                )}
                {errors && Object.keys(errors).length !== 0 && (
                  <StatusText title={'Something Went Wrong Cannot Be Created'} fColor={Colors.black} />
                )}

                <UpdateButton title={'cancel'} onClick={() => setEdit(false)} />
             
                  <CommonAddButton
                    title={'Update'}
                    onClick={() => callEditAPI()}
                  />
              
              </div>
            ) : (
              <div>
                <div className={mStyles.rowView}>
                  {' '}
                  <p className={mStyles.textLeft}>Demo Link</p>
                  <p className={mStyles.textRight}>{(values.demoLink) ? values.demoLink : "Not Available"}</p>
                </div>
                <UpdateButton title={'Edit'} onClick={() => setEdit(true)} />
              </div>
            )}
          </>
        ) : (
          <StatusText
            title={'Something Went Wrong Could Not be Updated'}
            fColor={Colors.black}
          />
        )}
     
    </div>
  )
}

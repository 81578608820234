import gql from "graphql-tag";

export const FETCH_TECH_SPEC = gql`
  query getVehicleTechSpec($qId: String!) {
    getVehicleTechSpec(qId: $qId) {
      vid
      drive_assist_system
      infotainment_system
      carplay
      ota
      heat_pump
    }
  }
`;

export const CREATE_TECH_SPEC = gql`
  mutation inputTechSpecForVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $driveAssistSystem: String
    $infotainmentSystem: String
    $carplay: String
    $ota: Boolean
    $heatPump: Boolean
  ) {
    inputTechSpecForVehicle(
      userEmail: $userEmail
      token : $token
      vid: $vid
      driveAssistSystem: $driveAssistSystem
      infotainmentSystem: $infotainmentSystem
      carplay: $carplay
      ota: $ota
      heatPump: $heatPump
    ) {
      status
      statusBool
    }
  }
`;

export const UPDATE_TECH_SPEC = gql`
  mutation updateTechSpecForVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $driveAssistSystem: String
    $infotainmentSystem: String
    $carplay: String
    $ota: Boolean
    $heatPump: Boolean
  ) {
    updateTechSpecForVehicle(
      userEmail: $userEmail
      vid: $vid
      token : $token
      driveAssistSystem: $driveAssistSystem
      infotainmentSystem: $infotainmentSystem
      carplay: $carplay
      ota: $ota
      heatPump: $heatPump
    )
  }
`;

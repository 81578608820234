import gql from "graphql-tag";

export const FETCH_PETROL_VARIANTS = gql`
  query getAllPetrolVariantDetails($userEmail: String!, $token :String!) {
    getAllPetrolVariantDetails(userEmail: $userEmail, token: $token) {
      status
      statusBool
      PetrolVariantDetails {
        manuf
        pid
        car_name
        price
        lperkm
        tank
        range
        acceleration
        fc_nedc
        fc_wltp
        range_nedc
        range_wltp
      }
    }
  }
`;

export const DELETE_PETROL_VARIANT = gql`
  mutation deletePetrolVariantDetails($qId: String!, $userEmail: String!, $token : String!) {
    deletePetrolVariantDetails(qId: $qId, userEmail: $userEmail, token :$token)
  }
`;

export const UPDATE_PETROL_VARIANT = gql`
  mutation updatePetrolVariantDetails(
    $userEmail: String!
    $token: String!
    $manuf: String
    $carName: String
    $price: Float
    $lperkm: Float
    $tank: Float
    $range: Float
    $acceleration: Float
    $fcNedc: Float
    $fcWltp: Float
    $rangeNedc: Float
    $rangeWltp: Float
    $qId: String!
  ) {
    updatePetrolVariantDetails(
      userEmail: $userEmail
      token: $token
      manuf: $manuf
      carName: $carName
      price: $price
      lperkm: $lperkm
      tank: $tank
      range: $range
      acceleration: $acceleration
      fcNedc: $fcNedc
      fcWltp: $fcWltp
      rangeNedc: $rangeNedc
      rangeWltp: $rangeWltp
      qId: $qId
    )
  }
`;

export const CREATE_PETROL_VARAINT = gql`
  mutation inputPetrolVariant(
    $userEmail: String!
    $token: String!
    $manuf: String
    $carName: String
    $price: Float
    $lperkm: Float
    $tank: Float
    $range: Float
    $acceleration: Float
    $fcNedc: Float
    $fcWltp: Float
    $rangeNedc: Float
    $rangeWltp: Float
  ) {
    inputPetrolVariant(
      userEmail: $userEmail
      token: $token
      manuf: $manuf
      carName: $carName
      price: $price
      lperkm: $lperkm
      tank: $tank
      range: $range
      acceleration: $acceleration
      fcNedc: $fcNedc
      fcWltp: $fcWltp
      rangeNedc: $rangeNedc
      rangeWltp: $rangeWltp
    ) {
      status
      statusBool
      error
    }
  }
`;

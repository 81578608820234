import React from "react";
import { makeStyles, TextareaAutosize, TextField } from "@material-ui/core";

import { Colors } from "../Styles/Colors/Colors";
import { Fonts } from "../Styles/Colors/Font";

export function TextareaInputField({
  id,
  placeholder,
  name,
  value,
  onChange,
  disabled,
}) {
  const classes = formStyle();

  return (
    <>
      <TextareaAutosize
        rowsMax={4}
        style={{ height: 90, padding: 10, fontSize: 14 }}
        id={id}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        className={classes.textareaStyle}
        size="small"
      />
    </>
  );
}

export function TextInputField({
  id,
  placeholder,
  name,
  value,
  onChange,
  disabled,
  key,
  type
}) {
  const classes = formStyle();

  return (
    <>
      <TextField
        type={type ? type : "type"}
        id={id}
        key={key}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        size="small"
        className={classes.inputStyle1}
        inputProps={{
          className: classes.inputStyleProps1,
        }}
        disabled={disabled}
      />
    </>
  );
}

export function TextCustom({
  id,
  placeholder,
  name,
  type,
  value,
  onChange,
  disabled,
  key,
}) {
  const classes = formStyle();

  return (
    <>
      <TextField
        type={type ? type : "type"}
        id={id}
        key={key}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        size="small"
        className={classes.inputStyle1}
        inputProps={{
          className: classes.inputStyleProps1,
        }}
        disabled={disabled}
      />
    </>
  );
}

export function CommonTextInputField({
  id,
  placeholder,
  name,
  value,
  onChange,
  disabled,
  key,
  type,
}) {
  const classes = formStyle();

  return (
    <>
      <TextField
        type={type}
        id={id}
        key={key}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        variant="outlined"
        size="small"
        className={classes.inputStyle1}
        inputProps={{
          className: classes.inputStyleProps1,
        }}
        disabled={disabled}
      />
    </>
  );
}

export function IncreamentNumberInput({
  id,
  placeholder,
  name,
  value,
  onChange,
}) {
  const classes = formStyle();

  return (
    <TextField
      type="number"
      id={id}
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      variant="outlined"
      size="small"
      className={classes.inputStyle1}
      inputProps={{
        className: classes.inputStyleProps1,
      }}
    />
  );
}

export function CountNumberInput({ id, placeholder, name, value, onChange }) {
  const classes = formStyle();

  return (
    <input
      type="text"
      id={id}
      pattern="[0-9]*"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={classes.countInputStyle1}
    />
  );
}

export function OnlyNumberInput({ id, placeholder, name, value, onChange }) {
  const classes = formStyle();

  return (
    <input
      type="text"
      id={id}
      pattern="[0-9]*"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      required
      className={classes.mobileInputStyle1}
    />
  );
}

export function OnlyNumberHalf({
  id,
  placeholder,
  name,
  value,
  onChange,
  width,
}) {
  const classes = formStyle();

  return (
    <input
      style={{ width: width }}
      type="text"
      id={id}
      pattern="[0-9]*"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      required
      className={classes.mobileInputStyle2}
    />
  );
}

export function MobileNumberInput({ id, placeholder, name, value, onChange }) {
  const classes = formStyle();

  return (
    <input
      type="text"
      id={id}
      pattern="[0-9]*"
      placeholder={placeholder}
      name={name}
      value={value}
      onChange={onChange}
      className={classes.mobileInputStyle1}
    />
  );
}

const formStyle = makeStyles((theme) => ({
  //   inputStyle1: {
  //     margin: "4px 0 0 0",
  //     width: "100%",
  //     fontFamily: Fonts.Ubuntu,
  //     borderRadius: 2,
  //     height: 30,
  //   },

  //   inputStyleProps1: {
  //     height: 30,
  //     padding: "4px 10px",
  //     fontSize: 14,
  //     border: "none",
  //     backgroundColor: Colors.white,
  //     margin: 0,
  //   },

  inputStyle1: {
    margin: "0",
    width: "100%",
    fontFamily: Fonts.Ubuntu,
    borderRadius: 2,
    height: 30,
  },
  //   inputStyle2: {
  //     margin: "4px 0 0 0",
  //     width: "100%",
  //     fontFamily: Fonts.Ubuntu,
  //     borderRadius: 2,
  //     height: 30,
  //   },
  inputStyleProps1: {
    height: 30,
    padding: "2px 10px",
    fontSize: 14,
    border: "none",
    backgroundColor: Colors.white,
    margin: 0,
    fontFamily: Fonts.Ubuntu,
    "&:hover": {
      backgroundColor: Colors.white,
      border: "none",
      outline: "none",
      borderRadius: 2,
    },
    "&:focus": {
      backgroundColor: Colors.white,
      outline: "none",
      borderRadius: 2,
    },
  },

  // count number
  countInputStyle1: {
    height: 30,
    width: "100%",
    margin: 0,
    border: "1px solid #ccc",
    padding: "4px 10px",
    borderRadius: 2,
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
  },
  // countInputStyle1: {
  //   height: 30,
  //   width: "100%",
  //   margin: 0,
  //   border: "1px solid #ccc",
  //   padding: "4px 10px",
  //   borderRadius: 2,
  //   fontSize: 16,
  //   fontFamily: Fonts.Ubuntu,
  // },

  mobileInputStyle1: {
    height: 34,
    width: "100%",
    margin: ` 0`,
    border: "1px solid #ccc",
    padding: "10px 9px",
    borderRadius: 2,
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
    boxSizing: "border-box",
  },

  mobileInputStyle2: {
    height: 34,
    width: "60%",
    margin: ` 0`,
    border: "1px solid #ccc",
    padding: "10px 9px",
    borderRadius: 2,
    fontSize: 14,
    fontFamily: Fonts.Ubuntu,
    boxSizing: "border-box",
  },

  textareaStyle: {
    margin: "0",
    padding: 0,
    width: "100%",
    fontFamily: Fonts.Ubuntu,
    boxSizing: "border-box",
  },
}));

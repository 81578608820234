import gql from "graphql-tag";

export const FETCH_SCORE_DATA = gql`
  query getAllScoreData($userEmail: String!, $token : String!) {
    getAllScoreData(userEmail: $userEmail, token : $token) {
      status
      statusBool
      scoreTable{
        id
        name_score
        zero
        zero_five
        one
        one_five
        two
        two_five
        three
        three_five
        four
        
      }
    }
  }
`;

export const DELETE_SCORE_DATA = gql`
  mutation deleteScoreTableDetails($qId: Float!, $userEmail: String!,  $token : String!) {
    deleteScoreTableDetails(qId: $qId, userEmail: $userEmail, token: $token)
  }
`;

export const UPDATE_SCORE = gql`
  mutation updateScoreTableDetails(
    $userEmail: String!
    $token : String!
    $qId: Float!
    $nameScore : String
    $zero : Float
    $zeroFive : Float
    $one : Float
    $oneFive : Float
    $two : Float
    $twoFive : Float
    $three : Float
    $threeFive : Float
    $four : Float
  ) {
    updateScoreTableDetails(
      userEmail : $userEmail
      token : $token
      qId : $qId
      nameScore : $nameScore
      zero : $zero
      zeroFive : $zeroFive
      one : $one
      oneFive : $oneFive
      two : $two
      twoFive : $twoFive
      three : $three
      threeFive : $threeFive
      four : $four
    )
  }
`;

export const CREATE_SCORE_DATA = gql`
  mutation inputScoreTable(
    $userEmail: String!
    $token : String!
    $nameScore : String
    $zero : Float
    $zeroFive : Float
    $one : Float
    $oneFive : Float
    $two : Float
    $twoFive : Float
    $three : Float
    $threeFive : Float
    $four : Float
  ) {
    inputScoreTable(
      userEmail : $userEmail
      token : $token
      nameScore : $nameScore
      zero : $zero
      zeroFive : $zeroFive
      one : $one
      oneFive : $oneFive
      two : $two
      twoFive : $twoFive
      three : $three
      threeFive : $threeFive
      four : $four
    ) {
      status
      statusBool
      error
    }
  }
`;

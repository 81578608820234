import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_MAINTENANCE_INFO, FETCH_MAINTENANCE_DETAILS } from "./api";

export default function EditMaintenance({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  const [values, setValues] = useState({
    petrolDelivery: updateValues ? updateValues.petrol_delivery : "",
    petrolService: updateValues ? updateValues.petrol_service : "",
    evDelivery: updateValues ? updateValues.ev_delivery : "",
    evService: updateValues ? updateValues.ev_service : "",
    id: updateValues ? updateValues.id : "",
    userEmail: email,
    token : token,
    manufacturer: updateValues ? updateValues.manufacturer : "",
  });

  const [updateUserDetails] = useMutation(UPDATE_MAINTENANCE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.updateMaintenanceGeneral);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_MAINTENANCE_DETAILS,
        variables: { userEmail: email, token : token },
      },
    ],
  });

  async function validator() {
    const parsedValuesAre = {
      userEmail: email,
      token : token,
      petrolDelivery: isNaN(parseFloat(values.petrolDelivery))
        ? 0
        : parseFloat(values.petrolDelivery),
      petrolService: isNaN(parseFloat(values.petrolService))
        ? 0
        : parseFloat(values.petrolService),
      evDelivery: isNaN(parseFloat(values.evDelivery))
        ? 0
        : parseFloat(values.evDelivery),
      evService: isNaN(parseFloat(values.evService))
        ? 0
        : parseFloat(values.evService),
      qId: values.id,
    };
    return parsedValuesAre;
  }

  async function onSubmit() {
    setOutcome(false);
    setErrors(false);
    const parsedValuesAre = await validator();
    updateUserDetails({
      variables: parsedValuesAre,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Manufacturer
              </Typography>
              <Typography className={formClass.leftText}>
                {values.manufacturer}
              </Typography>
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Petrol Delivery
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Petrol Delviery"
                name="petrolDelivery"
                value={values.petrolDelivery}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Petrol Service
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Petrol Service"
                name="petrolService"
                value={values.petrolService}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Ev Delivery Fee
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Ev Delivery Fee"
                name="evDelivery"
                value={values.evDelivery}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Ev Service Fee
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Service Fee"
                name="evService"
                value={values.evService}
                onChange={onChange}
              />
            </div>

            {outcome && outcome === true && (
              <StatusText
                title={"Updated Successfully"}
                fColor={Colors.black}
              />
            )}

            {errors && errors === true && (
              <StatusText
                title={"Something Went Wrong"}
                fColor={Colors.black}
              />
            )}

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
    </div>
  );
}

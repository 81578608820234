import gql from "graphql-tag";


export const FETCH_PRICING_DETAILS = gql`
  query  getPricingDetails($qId: String!){
    getPricingDetails(qId: $qId){
        vid
        base_price
        dest_charge
        tax_credit
        effect_price
        delivery_fee
       }
  }
`;


export const CREATE_PRICING_INFO = gql`
  mutation  inputPriceForVehicle(
    $userEmail: String!
    $token : String!
    $vid: String!
    $basePrice: Float
    $destCharges: Float
    $taxCredit: Float
    $effectPrice: Float
    $deliveryFees: Float

  ) {
    inputPriceForVehicle(
      userEmail: $userEmail
      token : $token
      vid: $vid
      basePrice: $basePrice
      destCharges: $destCharges
      taxCredit: $taxCredit
      effectPrice: $effectPrice
      deliveryFees: $deliveryFees
    ) {
      status
      statusBool
    }
  }
`;


export const UPDATE_PRICING_INFO = gql`
  mutation updatePriceDetails(
    $userEmail: String!
    $token : String!
    $vid: String!
    $basePrice: Float
    $destCharges: Float
    $taxCredit: Float
    $effectPrice: Float
    $deliveryFees: Float
    ) {
      updatePriceDetails(
        userEmail: $userEmail
        token : $token
        vid: $vid
        basePrice: $basePrice
        destCharges: $destCharges
        taxCredit: $taxCredit
        effectPrice: $effectPrice
        deliveryFees: $deliveryFees
    )
  }
`;


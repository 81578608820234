import gql from "graphql-tag";


export const FETCH_MAINTENANCE_DETAILS = gql`
  query  getVehicleMaintenance($qId: String!){
    getVehicleMaintenance(qId: $qId){
        id
        vid
        brand
        model
        variant
        rego_and_insurance_monthy
        fuel
        servicing_monthly
        tyres_monthly
        rego_insurance_annualy
        servicing_annually
        tyres_annually
        fuel_annually
        for_km
    }
  }
`;


export const CREATE_MAINTENANCE_INFO = gql`
  mutation inputNewMaintenance(
    $tyresMonthly: Float
    $servicingMonthly: Float
    $fuelMonthly: Float
    $regoAndInsuranceMonthly: Float
    $variant: String!
    $model: String!
    $brand: String!
    $vid: String!
    $forKm: Float
    $userEmail: String!
    $token : String!
  ) {
    inputNewMaintenance(
      tyresMonthly:$tyresMonthly
      servicingMonthly:$servicingMonthly
      fuelMonthly:$fuelMonthly
      regoAndInsuranceMonthly:$regoAndInsuranceMonthly
      variant:$variant
      model:$model
      brand:$brand
      forKm: $forKm
      vid:$vid
      userEmail:$userEmail
      token:$token
    ) {
      status
      statusBool
      error
    }
  }
`;

export const UPDATE_MAINTENANCE_INFO = gql`
  mutation updateMaintenanceDetails(
    $tyresMonthly: Float
    $servicingMonthly: Float
    $fuelMonthly: Float
    $regoAndInsuranceMonthly: Float
    $variant: String
    $model: String
    $brand: String
    $vid: String
    $forKm: Float
    $userEmail: String!
    $qId: Float!
    $token : String!
    ) {
      updateMaintenanceDetails(
        tyresMonthly: $tyresMonthly
        servicingMonthly: $servicingMonthly
        fuelMonthly: $fuelMonthly
        regoAndInsuranceMonthly: $regoAndInsuranceMonthly
        variant: $variant
        model: $model
        brand: $brand
        vid: $vid
        forKm: $forKm
        userEmail: $userEmail
        token : $token
        qId: $qId
    )
  }
`;

export const DELETE_MAINTENANCE_INFO = gql`
  mutation deleteMaintenanceDetails( 
    $qId: Float!
    $token : String!
    $userEmail: String!) {
      deleteMaintenanceDetails(
      qId:$qId
      userEmail:$userEmail
      token : $token
    )
  }
`;
import React, { useContext, useState } from "react";
import { useQuery } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";

import {
  CStyles,
  modalStyle,
  StyledTableCell,
  StyledTableRow,
} from "../../Styles/CommonStyles";

import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import {  StatusText } from "../../Component/Text";
import {

  RefreshButton,
  SearchBox,
  SearchButton,
  PrevButton,
  NextButton
} from "../../Component/ButtonComponent";

import Loading from "../../Component/Loading";

import { FETCH_CHARGE_COMPUTE } from "./api";

import { EmailContext } from "../../Context/EmailContext";

export default function ComputeCharge() {
  const { email, token} = useContext(EmailContext);

  const classes = CStyles();
  const mStyles = modalStyle();

  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState(null);
  // const [outcome, setOutcome] = useState({});
  // const [errors, setErrors] = useState({});

  const [searchValue, setSearchValue] = useState("");
  const [searchColumnValue, setSearchColumnValue] = useState("");

  // const [formModal, setFormModal] = useState({
  //   open: false,
  //   title: "",
  //   check: "",
  // });

  /* <<<=========      GET STAFF ATTENDANCE  QUERY   ===========>>>  */

  const { loading, data, error, fetchMore } = useQuery(FETCH_CHARGE_COMPUTE, {
    variables: { userEmail: email, token},
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onChange2 = (event) => {
    setSearchColumnValue(event.target.value);
  };

  /* <<<=========      MODAL CLOSE/OPEN FUNC   ===========>>>  */

  async function formatData(obj) {
    const rec = JSON.parse(obj.recommendations);

    obj["chargingSpeed"] = rec["ChargingSpeed"];
    obj["cc_fullyCharged"] = rec["ChargingCosts"]["fullyChargedCost"];
    obj["cc_costPerKm"] = rec["ChargingCosts"]["costPerKm"];
    obj["ct_fullyCharge"] = rec["ChargingTimes"]["fullyCharged"];
    obj["ct_weekendDriving"] = rec["ChargingTimes"]["WeekendDriving"];
    obj["ct_weekdayDriving"] = rec["ChargingTimes"]["WeekdayDriving"];
    obj["ct_dailyNeed"] = rec["ChargingTimes"]["dailyNeed"];
    return obj;
  }

  const handleOpen = async (obj) => {
    const getformattedData = await formatData(obj);
    setModalInfo(getformattedData);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  /* <<<=========    FORM MODAL FUNC   ===========>>>  */

  // const handleOpenForm = (title, check) => {
  //   setOpen(false);
  //   setFormModal({
  //     ...formModal,
  //     open: true,
  //     title: title,
  //     check: check,
  //   });
  // };

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Charging Calculator Info</p>
        </div>
        <StatusText title={"Something Went wrong!"} fColor={Colors.error} />
        <Divider className={classes.dividerLine} />
      </div>
    );
  }

  return (
    <div className={classes.page}>
      <div className={classes.pageHead}>
        <p className={classes.headTitle}>Charging Calculator Info</p>
      </div>
      <Divider className={classes.dividerLine} />

      <>
        <div className={classes.searchRowDiv}>
          <div className={classes.searchRow}>
            <SearchBox
              value={searchValue}
              onChange={(text) => onChange(text)}
            />

            <FormControl variant="filled" className={classes.formControl}>
              <InputLabel
                className={classes.selectorLabel}
                id="demo-simple-select-outlined-label"
              >
                Select search
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                name="searchColumnValue"
                value={searchColumnValue}
                className={classes.selectorStyle}
                onChange={(text) => onChange2(text)}
                label="Select Search"
              >
                <MenuItem value="">
                  <em>Select Search</em>
                </MenuItem>
                <MenuItem value={'model'}>Model Name</MenuItem>
                <MenuItem value={'brand'}>Manufacturer</MenuItem>
                <MenuItem value={'variant'}>Variant</MenuItem>
                <MenuItem value={'user_email'}>User Email</MenuItem>\
                <MenuItem value={'electricity_price'}>Electricity Price '&gt;'=</MenuItem>
                <MenuItem value={'weekday_range'}>Weekend Range '&gt;'=</MenuItem>
                <MenuItem value={'weekend_range'}>Weekday Range '&gt;'=</MenuItem>
              </Select>
            </FormControl>

            <SearchButton  onClick={(e) => {
              fetchMore({
                variables: {
                  searchStatus: "True",
                  searchColumn: searchColumnValue,
                  searchValue: searchValue,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  return fetchMoreResult;
                },
              });
            }}/>

            <RefreshButton 
            onClick={() => {
              fetchMore({
                variables: {
                  userEmail: email,
                  token
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                  return fetchMoreResult;
                },
              });
            }}
            />
          </div>
          {/* <CommonAddButton
            title="Add Vehicle"
            // onClick={() => handleOpenForm("Add Events")}
          /> */}
        </div>

        {/* {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText title={"Something Went Wrong!"} fColor={Colors.error} />
            )} */}

        {/* ========  ADD FORM MODAL   ======== */}

        {loading ? (
          <Loading />
        ) : (
          <>
            {data?.getChargingComputationData?.status === false ? (
              <StatusText
                title={data?.getChargingComputationData?.status}
                fColor={Colors.black}
              />
            ) : (
              <div className={classes.bodyDiv}>
                {data?.getChargingComputationData &&
                data?.getChargingComputationData?.ChargingCalculatorInfo
                  .length ? (
                  <>
                    <TableContainer
                      elevation={0.5}
                      className={classes.table}
                      component={Paper}
                    >
                      <Table aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <StyledTableCell align="center">
                              User Email
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Brand
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              Model
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Variant
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Socket Type
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              Charge Type
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {data?.getChargingComputationData?.ChargingCalculatorInfo.map(
                            (obj, index) => {
                              return (
                                <StyledTableRow
                                  key={index}
                                  onClick={() => handleOpen(obj)}
                                  hover={true}
                                  className={classes.tableRow}
                                >
                                  <StyledTableCell align="center">
                                    {obj.user_email}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.brand}
                                  </StyledTableCell>
                                  <StyledTableCell align="center">
                                    {obj.model}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.variant}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.socket_type}
                                  </StyledTableCell>

                                  <StyledTableCell align="center">
                                    {obj.charger_type}
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <div className={classes.paginationDiv}>
                      {data &&
                          data?.getChargingComputationData?.pageInfo?.prevPageStatus ===
                            "True" ? (
                            <PrevButton
                              onClick={() => {
                                const nextCursor =
                                  data?.getChargingComputationData?.pageInfo?.prevCursor;
                                fetchMore({
                                  variables: {
                                    cursor: nextCursor,
                                    userEmail: email,
                                    prevPage: "True",
                                    token
                                  },
                                  updateQuery: (
                                    prevResult,
                                    { fetchMoreResult }
                                  ) => {
                                    return fetchMoreResult;
                                  },
                                });
                              }}
                            />
                          ) : null}

                      {data &&
                          data?.getChargingComputationData?.pageInfo?.nextPageStatus ===
                            "True" ? (
                            <NextButton
                              onClick={() => {
                                const nextCursor =
                                  data?.getChargingComputationData?.pageInfo?.nextCursor;
                                fetchMore({
                                  variables: {
                                    cursor: nextCursor,
                                    userEmail: email,
                                    token
                                  },
                                  updateQuery: (
                                    prevResult,
                                    { fetchMoreResult }
                                  ) => {
                                    return fetchMoreResult;
                                  },
                                });
                              }}
                            />
                          ) : null}
                    </div>
                  </>
                ) : (
                  <StatusText title={"No Data"} fColor={Colors.black} />
                )}

                <div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={mStyles.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={mStyles.paper}>
                        <div className={mStyles.formModalContent}>
                          <div className={mStyles.floatLeft}>
                            Charging Computation -{modalInfo?.brand}{" "}
                            {modalInfo?.model}
                            {modalInfo?.variant}
                          </div>
                        </div>

                        <div className={mStyles.formModalInnerContent}>
                        <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Id</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.id}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> User Email</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.user_email}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Brand</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.brand}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Model</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.model}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Variant</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.variant}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Socket Type</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.socket_type}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Charger Type</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.charger_type}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Electricity Price
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.electricity_price}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Weekday Range</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekday_range}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Weekday Range Trip
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekday_range_trip}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Weekend Range</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekend_range}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Weekend Range Trip
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.weekend_range_trip}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                    
                            <p className={mStyles.textCenter}>
                              RECOMMENDATIONS
                            </p>{" "}
                         
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Cost Per Km</p>{" "}
                            <p className={mStyles.textRight}>
                              $ {modalInfo?.cc_costPerKm}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Cost - Full Charge{" "}
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              $ {modalInfo?.cc_fullyCharged}{" "}
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}> Charging Speed</p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.chargingSpeed}{" "}kW
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Charging Times - Daily Need
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ct_dailyNeed}{" "}kW
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Charging Times - Full Charge
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ct_fullyCharge}{" "}kW
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Charging Times - WeekdayDriving
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ct_weekdayDriving}{" "}kW
                            </p>
                          </div>
                          <Divider className={mStyles.dividerLine} />
                          <div className={mStyles.rowView}>
                            <p className={mStyles.textLeft}>
                              {" "}
                              Charging Times - WeekendDriving
                            </p>{" "}
                            <p className={mStyles.textRight}>
                              {modalInfo?.ct_weekendDriving}{" "}kW
                            </p>
                          </div>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
}

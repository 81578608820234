import './App.css'
import { BrowserRouter, Switch } from 'react-router-dom'

import LandingPage from './Pages/LandingPage'
import { ProtectedRoute } from './ProtectedRoute'
import { LoginRoute } from './LoginRoute'

import Login from './Pages/Login/index'

import EmailContextData from './Context/EmailContext'

function AppRouter() {
  return (
    <BrowserRouter>
      <Switch>
        <LoginRoute exact path="/" component={Login} />
        {/* <ProtectedRoute exact path="/home" component={LandingPage} /> */}
        
        <ProtectedRoute exact path="/variantsdata" component={LandingPage} />
     
      
        <ProtectedRoute exact path="/chargecomputationinfo" component={LandingPage} />
        <ProtectedRoute exact path="/costcomputationinfo" component={LandingPage} />
        <ProtectedRoute exact path="/findmyevinfo" component={LandingPage} />
        <ProtectedRoute exact path="/vehicledata" component={LandingPage} />
         
        <ProtectedRoute exact path="/userinfo" component={LandingPage} />
        <ProtectedRoute exact path="/chargingdetails" component={LandingPage} />
        <ProtectedRoute exact path="/emissionconstant" component={LandingPage} />
        <ProtectedRoute exact path="/maintenanceinfo" component={LandingPage} />
        <ProtectedRoute exact path="/viewimages" component={LandingPage} />
        <ProtectedRoute exact path="/viewcolorvariant" component={LandingPage} />
        <ProtectedRoute exact path="/viewvarimages" component={LandingPage} />
        <ProtectedRoute exact path="/viewvarcolorvariant" component={LandingPage} />
        <ProtectedRoute exact path="/manuf" component={LandingPage} />
        <ProtectedRoute exact path="/dutiesandother" component={LandingPage} />
        <ProtectedRoute exact path="/dutiesandexceptions" component={LandingPage} />
        <ProtectedRoute exact path="/petrolvariant" component={LandingPage} />
        <ProtectedRoute exact path="/maintenance-general" component={LandingPage} />
        <ProtectedRoute exact path="/score-card" component={LandingPage} />
        <ProtectedRoute exact path="/assumptions" component={LandingPage} />
      </Switch>
    </BrowserRouter>
  )
}

function App() {
  return (
    <div>
      <EmailContextData>
        <AppRouter />
      </EmailContextData>
    </div>
  )
}

export default App

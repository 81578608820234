import gql from "graphql-tag";

export const FETCH_ALL_VARIANTS_DATA = gql`
  query getVariantsFromDisplayTable($qId: String!) {
    getVariantsFromDisplayTable(qId: $qId) {
      vid
      manufacturer
      model_name
      model_variant
      ancp_safety_rating
      price
      country_of_manufacture
      available_country
      available_from
      available_now
      battery_rating
      efficiency_rating
      range_rating
      acceleration_0_100
      seats
      battery
      range
      efficiency
      top_speed
      primary_img_link
      year_of_production
      charge_port
      variant_arr
      on_board_charger
      charging_efficiency
      body_style
      avail_in_australia
      reference_id
      creator
      created_on
      publish_status
    }
  }
`;

export const UPDATE_VEHICLE_DATA = gql`
  mutation updateVehicleDisplayMetaData(
    $userEmail: String!
    $token: String!
    $manufacturer: String
    $countryOfManufacture: String
    $availableCountry: String
    $availableFrom: String
    $availableNow: String
    $yearOfProduction: String
    $availableInAustralia: String
    $qId: String!
  ) {
    updateVehicleDisplayMetaData(
      userEmail: $userEmail
      token: $token
      manufacturer: $manufacturer
      countryOfManufacture: $countryOfManufacture
      availableCountry: $availableCountry
      availableFrom: $availableFrom
      availableNow: $availableNow
      yearOfProduction: $yearOfProduction
      availableInAustralia: $availableInAustralia
      qId: $qId
    )
  }
`;

export const DELETE_VARIANT_DATA = gql`
  mutation deleteVehicleDisplayMetaData(
    $qId: String!
    $userEmail: String!
    $token: String!
  ) {
    deleteVehicleDisplayMetaData(
      qId: $qId
      userEmail: $userEmail
      token: $token
    )
  }
`;

export const CHANGE_VARIANT_NAME = gql`
  mutation changeVariantName(
    $qId: String!
    $userEmail: String!
    $token: String!
    $variantName: String!
  ) {
    changeVariantName(
      token: $token
      qId: $qId
      userEmail: $userEmail
      variantName: $variantName
    )
  }
`;

export const CHANGE_PUBLISH_STATUS_VARIANT = gql`
  mutation changePublishVariant($qId: String!, $userEmail: String!, $token: String!) {
    changePublishVariant(qId: $qId, userEmail: $userEmail,  token: $token)
  }
`;

import gql from "graphql-tag";


export const FETCH_USER_INFO = gql`
  query  getAllUserData($userEmail: String!, $token : String!){
    getAllUserData(userEmail: $userEmail token : $token){
        userData{
            user_email
            user_name
            user_role
          }
          status
          statusBool
    }
  }
`;


export const CREATE_USER_INFO = gql`
  mutation inputNewUser(
    $userRole: String
    $userPass: String!
    $userName: String!
    $token : String!
    $inputEmail: String!
    $userEmail: String!
  ) {
    inputNewUser(
      userRole: $userRole
      userPass: $userPass
      userName: $userName
      inputEmail: $inputEmail
      userEmail: $userEmail
      token : $token
    ) {
      status
      statusBool
      error
    }
  }
`;


export const DELETE_USER_DATA = gql`
  mutation deleteUserData($inputEmail: String!, $userEmail: String!, $token : String!) {
    deleteUserData(
      inputEmail: $inputEmail
      userEmail: $userEmail
      token : $token
    )
  }
`;

export const UPDATE_USER_DATA = gql`
  mutation updateUserData(
    $userRole: String
    $userPass: String!
    $userName: String!
    $inputEmail: String!
    $userEmail: String!
    $token : String!
    ) {
      updateUserData(
        userRole: $userRole
        userPass: $userPass
        userName: $userName
        inputEmail: $inputEmail
        userEmail: $userEmail
        token : $token
    )
  }
`;
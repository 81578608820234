import gql from "graphql-tag";

export const FETCH_BATTERY_DETAILS = gql`
  query getBatteryAndCharging($qId: String!) {
    getBatteryAndCharging(qId: $qId) {
      vid
      battery_capacity
      battery_useable
      charging_efficiency
      max_charging_power
      max_charging_time
      max_charging_speed
      charge_port
      charge_port_location
      fast_charge_port
      fast_charge_port_location
      max_fast_charging_power
      max_fast_charging_time
      max_fast_charging_speed
      bi_directional_charging
      battery_chemistry
      bi_directional_power
    }
  }
`;

export const CREATE_BATTERY_INFO = gql`
  mutation inputBatteryAndChargingData(
    $userEmail: String!
    $token : String!
    $vid: String!
    $batteryCapacity: Float
    $batteryUseable: Float
    $chargingEfficiency: Float
    $maxChargingPower: Float
    $maxChargingTime: Float
    $maxChargingSpeed: Float
    $maxFastChargingPower: Float
    $maxFastChargingTime: Float
    $maxFastChargingSpeed: Float
    $chargePort: String
    $chargePortLocation: String
    $fastChargePort: String
    $fastChargePortLocation: String
    $bidirectionalCharging: String
    $bidirectionalPower: Float
    $batteryChemistry: String
  ) {
    inputBatteryAndChargingData(
      userEmail: $userEmail
      token : $token
      vid: $vid
      batteryCapacity: $batteryCapacity
      batteryUseable: $batteryUseable
      chargingEfficiency: $chargingEfficiency
      maxChargingPower: $maxChargingPower
      maxChargingTime: $maxChargingTime
      maxChargingSpeed: $maxChargingSpeed
      maxFastChargingPower: $maxFastChargingPower
      maxFastChargingTime: $maxFastChargingTime
      maxFastChargingSpeed: $maxFastChargingSpeed
      chargePort: $chargePort
      chargePortLocation: $chargePortLocation
      fastChargePort: $fastChargePort
      fastChargePortLocation: $fastChargePortLocation
      bidirectionalCharging: $bidirectionalCharging
      bidirectionalPower : $bidirectionalPower
      batteryChemistry : $batteryChemistry
    ) {
      status
      statusBool
    }
  }
`;

export const UPDATE_BATTERY_INFO = gql`
  mutation updateBatteryAndChargingData(
    $userEmail: String!
    $token : String!
    $vid: String!
    $batteryCapacity: Float
    $batteryUseable: Float
    $chargingEfficiency: Float
    $maxChargingPower: Float
    $maxChargingTime: Float
    $maxChargingSpeed: Float
    $maxFastChargingPower: Float
    $maxFastChargingTime: Float
    $maxFastChargingSpeed: Float
    $chargePort: String
    $chargePortLocation: String
    $fastChargePort: String
    $fastChargePortLocation: String
    $bidirectionalCharging: String
    $bidirectionalPower: Float
    $batteryChemistry: String
  ) {
    updateBatteryAndChargingData(
      userEmail: $userEmail
      token : $token
      vid: $vid
      batteryCapacity: $batteryCapacity
      batteryUseable: $batteryUseable
      chargingEfficiency: $chargingEfficiency
      maxChargingPower: $maxChargingPower
      maxChargingTime: $maxChargingTime
      maxChargingSpeed: $maxChargingSpeed
      maxFastChargingPower: $maxFastChargingPower
      maxFastChargingTime: $maxFastChargingTime
      maxFastChargingSpeed: $maxFastChargingSpeed
      chargePort: $chargePort
      chargePortLocation: $chargePortLocation
      fastChargePort: $fastChargePort
      fastChargePortLocation: $fastChargePortLocation
      bidirectionalCharging: $bidirectionalCharging
      bidirectionalPower : $bidirectionalPower
      batteryChemistry : $batteryChemistry
    )
  }
`;

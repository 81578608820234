import gql from "graphql-tag";

export const FETCH_ALL_VEHICLE_DATA = gql`
  query getAllVehicleListing(
    $limit: String
    $prevPage: String
    $userEmail: String!
    $token : String!
    $cursor: String
    $searchStatus : String
    $searchColumn : String
    $searchValue : String
  ){
    getAllVehicleListing(
      limit: $limit
      prevPage: $prevPage
      userEmail: $userEmail
      token : $token
      cursor: $cursor
      searchStatus : $searchStatus
      searchColumn : $searchColumn
      searchValue : $searchValue
    ){
      vehicleData{

      
        vid
        manufacturer
        model_name
        model_variant
        ancp_safety_rating
        price
        country_of_manufacture
        available_country
        available_from
        available_now
        battery_rating
        efficiency_rating
        range_rating
        acceleration_0_100
        seats
        battery
        range
        efficiency
        top_speed
        primary_img_link
        year_of_production
        charge_port
        variant_arr
        on_board_charger
        charging_efficiency
        body_style
        avail_in_australia
        publish_status
        creator
        created_on
        book_demo
        }
        pageInfo{
          prevPageStatus
          nextPageStatus
          prevCursor
          nextCursor
        }
      }
  }
`;


export const CREATE_VEHICLE_DATA = gql`
  mutation  createVehicleDisplayData(
    $userEmail: String!
    $token : String!
    $manufacturer: String
    $modelName: String
    $modelVariant: String
    $countryOfManufacture: String
    $availableCountry: String
    $availableFrom: String
    $availableNow: String
    $yearOfProduction: String
    $availableInAustralia: String

  ) {
    createVehicleDisplayData(
      userEmail: $userEmail
      token : $token
      manufacturer: $manufacturer
      modelName: $modelName
      modelVariant: $modelVariant
      countryOfManufacture: $countryOfManufacture
      availableCountry: $availableCountry
      availableFrom: $availableFrom
      availableNow: $availableNow
      yearOfProduction: $yearOfProduction
      availableInAustralia: $availableInAustralia
    ) {
      status
      statusBool
      vehicleName
      creationId
    }
  }
`;


export const UPDATE_VEHICLE_DATA = gql`
  mutation  updateVehicleDisplayData(
    $userEmail: String!
    $token : String!
    $manufacturer: String
    $countryOfManufacture: String
    $availableCountry: String
    $availableFrom: String
    $availableNow: String
    $yearOfProduction: String
    $availableInAustralia: String
    $qId: String!

  ) {
    updateVehicleDisplayData(
      userEmail: $userEmail
      token : $token
      manufacturer: $manufacturer
      countryOfManufacture: $countryOfManufacture
      availableCountry: $availableCountry
      availableFrom: $availableFrom
      availableNow: $availableNow
      yearOfProduction: $yearOfProduction
      availableInAustralia: $availableInAustralia
      qId: $qId
    ) 
  }
`;


export const DELETE_VEHICLE_DISPLAY_DATA = gql`
  mutation deleteVehicleDisplayData($qId: String!, $userEmail: String!, $token : String!) {
    deleteVehicleDisplayData(
      
      token : $token
      qId: $qId
      userEmail: $userEmail
    )
  }
`;


export const CHANGE_PUBLISH_STATUS = gql`
  mutation changePublishStatus($qId: String!, $userEmail: String!,  $token : String!) {
    changePublishStatus(
      token : $token
      qId: $qId
      userEmail: $userEmail
    )
  }
`;


export const CHANGE_MODEL_NAME = gql`
  mutation changeModelName(
    $qId: String!,
    $token : String!,
    $userEmail: String!
    $modelName: String!
    ) {
    changeModelName(
      token : $token
      qId: $qId
      userEmail: $userEmail
      modelName: $modelName
    )
  }
`;

export const CHANGE_VARIANT_NAME_MAIN = gql`
  mutation changeVariantNameMain(
    $qId: String!,
    $token : String!,
    $userEmail: String!
    $variantName: String!) {
      changeVariantNameMain(
        token : $token
        qId: $qId
        userEmail: $userEmail
        variantName: $variantName
    )
  }
`;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_CHARGING_DETAILS, FETCH_CHARGE_DETAILS } from "./api";

export default function EditChargingDetails({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  const [values, setValues] = useState({
    qId: updateValues ? updateValues.id : "",
    kw: updateValues ? updateValues.kw : "",
    type: updateValues ? updateValues.type : "",
    phase: updateValues ? updateValues.phase : "",
    currentFlow: updateValues ? updateValues.current_flow : "",
    userEmail: email,
    token: token,
  });

  const [updateChargeDetailsAPI] = useMutation(UPDATE_CHARGING_DETAILS, {
    update(_, result) {
      setOutcome(result?.data?.updateChargeDetails);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_CHARGE_DETAILS,
        variables: { userEmail: email, token },
      },
    ],
  });

  function onSubmit() {
    setOutcome(false);
    setErrors(false);
    const valueToInsert = {
      qId: values.qId,
      currentFlow: values.currentFlow,
      phase: values.phase,
      type: values.type,
      kw: parseFloat(values.kw),
      userEmail: email,
      token: token,
    };
    updateChargeDetailsAPI({
      variables: valueToInsert,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>
                Current Flow
              </Typography>
              <TextInputField
                id="en"
                placeholder="Enter Current Flow"
                name="currentFlow"
                value={values.currentFlow}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Phase </Typography>
              <TextInputField
                id="en"
                placeholder="Enter Phase "
                name="phase"
                value={values.phase}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>Type </Typography>
              <TextInputField
                id="en"
                placeholder="Enter Type"
                name="type"
                value={values.type}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              <Typography className={formClass.leftText}>KW</Typography>
              <TextInputField
                id="en"
                placeholder="Enter KW"
                name="kw"
                value={values.kw}
                onChange={onChange}
              />
            </div>

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}

      {outcome && outcome === true && (
        <StatusText title={"Updated Successfully"} fColor={Colors.black} />
      )}

      {errors && errors === true && (
        <StatusText title={"Something Went Wrong"} fColor={Colors.black} />
      )}
    </div>
  );
}

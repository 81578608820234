import React, { useContext, useEffect, useState, useCallback } from "react";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

import { Colors } from "../../Styles/Colors/Colors";
import { formStyle } from '../../Styles/CommonStyles'

import {
  CStyles,
  modalStyle,
} from "../../Styles/CommonStyles";

import { Typography } from '@material-ui/core';
import { TextCustom } from '../../Component/InputComponents'

import { Slide } from '@material-ui/core'

// import {
//   Divider,
//   FormControl,
//   InputLabel,
//   MenuItem,
//   Select,
//   Slide,
// } from "@material-ui/core";

// import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";

// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
// import Paper from "@material-ui/core/Paper";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

// import { DELETE_EVENT_DATA, FETCH_ALL_EVENTS } from "./API/Main";

import { ModalHead, StatusText } from '../../Component/Text';

import {
  CommonFilledButton,
  UploadButton,
} from "../../Component/ButtonComponent";
// import Loading from "../../Component/Loading";

import { FETCH_ALL_COLOR_IMAGES, GET_SIGNED_URL_COLOR, INSERT_URL_COLOR_IMAGE, DELETE_IMG_COLOR } from "./api";

import axios from "axios";
import { MdUpdate, MdDelete } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import { EmailContext } from '../../Context/EmailContext';

import EditVariantColor from './editVariantColor';

export default function ViewColorImages(props) {
  const { email } = useContext(EmailContext)

  const updateValues = props.location.aboutProps
    ? props.location.aboutProps
    : null;

  const [upLoading, setUpLoading] = useState(false);
  const [upLoaded, setUpLoaded] = useState(false);

  const [modalInfo, setModalInfo] = useState(null)


  const NoPhoto = 'Photo';

  const [formModal, setFormModal] = useState({
    open: false,
    title: '',
    check: '',
  })



  const [callTheImagesApi, { loading, data, error }] = useLazyQuery(
    FETCH_ALL_COLOR_IMAGES
  );


  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.data?.vid;
      callTheImagesApi({ variables: { qId: idIs } });
    }
  }, [updateValues]); // eslint-disable-line react-hooks/exhaustive-deps




  const formClass = formStyle();
  const classes = CStyles();
  const mStyles = modalStyle();

  const [values, setValues] = useState({
    fileName: "",
    fileType: "",
    userEmail: email,
    modelVariant: updateValues ? updateValues.data.model_variant : '',
    modelName: updateValues ? updateValues.data.model_name : '',
  });

  const initialState = {
    colorName: '',
    colorCode: ''
  }

  const [inputvalues, setInputValues] = useState(initialState);


  const [fileDetail, setFileDetail] = useState(null)

  const [outcome, setOutcome] = useState('');
  const [errors, setErrors] = useState('');

  /* =====================      Get FUNCTION CALL     =====================*/

  const handleOpenForm = (title, check) => {
    setFormModal({
      ...formModal,
      open: true,
      title: title,
      check: check,
    })
  }

  /* <<<=========     PRODUCT IMAGE UPLOAD  useMutation   ===========>>>  */

  const [insertImageDetails] = useMutation(INSERT_URL_COLOR_IMAGE, {
    update(_, result) {
      setUpLoaded(true);
      setUpLoading(false);
      setOutcome(result?.data?.inputImageColorForVehicle?.status);
      setInputValues(initialState)
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [

      {
        query: FETCH_ALL_COLOR_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
  });


  const [deleteImageStatus] = useMutation(DELETE_IMG_COLOR, {
    update(_, result) {
      setUpLoaded(true);
      setUpLoading(false);
      if (result?.data?.deleteVehicleImageLink) {
        setOutcome('Successfully Deleted');
      }

    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [

      {
        query: FETCH_ALL_COLOR_IMAGES,
        variables: { qId: updateValues?.data?.vid },
      },
    ],
  });


  /* =====================      Update Event Image Status    =====================*/


  useEffect(() => {
    setOutcome('')
    setErrors('')
    setUpLoaded(false);
    setFileDetail(null)
  }, [data]);

  const uploadToS3 = async (signedRequest, url) => {
    const options = {
      headers: {
        "Content-Type": values.fileType,
      },
    };
    const returned = await axios.put(signedRequest, fileDetail.file, options);
    return returned;
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      setValues({
        ...values,
        fileName: acceptedFiles[0].name,
        fileType: acceptedFiles[0].type
      });

      setFileDetail({
        file: acceptedFiles[0]
      })
    },
    [values]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const [getUrl] = useMutation(GET_SIGNED_URL_COLOR, {
    update(_, result) {
      setUpLoading(result);
    },
    onError(err) {
      setErrors(err);
    },
    variables: values,
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    setUpLoaded(false);
    setOutcome('')
    if (inputvalues.colorName === '') {
      setOutcome('Color Name is Empty')
      return
    }

    if (inputvalues.colorCode === '') {
      setOutcome('Color Code is Empty')
      return
    }

    const response = await getUrl();

    const { signedRequest, url } = response?.data?.signedUrlForColorImage;

    const uploatStatus = await uploadToS3(signedRequest, url);

    if (uploatStatus.status === 200) {

      await insertImageDetails({
        variables: {
          vid: updateValues?.data?.vid,
          userEmail: email,
          imageLink: url,
          colorName: inputvalues.colorName,
          colorCode: inputvalues.colorCode
        }
      });
    }
  };

  /* =====================      Update Event Image Status    =====================*/

  const callEdit = (objIs) => {
    setModalInfo(objIs)
    handleOpenForm('Edit Variant Color', 'editVariantColor')
  }



  const deleteFunc = async (id, imglink) => {

    deleteImageStatus({
      variables: {
        imgLink: imglink,
        userEmail: email,
        qId: id,
      }
    })

  };

  const onChange = (event) => {
    setInputValues({ ...inputvalues, [event.target.name]: event.target.value })
  }

  if (error) {
    return (
      <div className={classes.page}>
        <div className={classes.pageHead}>
          <p className={classes.headTitle}>Image Details</p>
        </div>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />

      </div>
    )
  }


  /* <<<=========    CONTENT   ===========>>>  */
  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <div>

          <div>
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={formModal?.open}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              className={mStyles.formModalContainer}
            >
              <Fade in={formModal?.open}>
                <Slide
                  direction="down"
                  in={formModal?.open}
                  mountOnEnter
                  unmountOnExit
                >
                  <div className={mStyles.formModalContent}>
                    <ModalHead
                      title={formModal?.title}
                      onClick={() => {
                        setFormModal({
                          ...formModal,
                          open: false,
                        })
                      }}
                    />
                    <div className={mStyles.formModalInnerContent}>

                      {formModal?.check === 'editVariantColor' ? (
                        <EditVariantColor updateValues={modalInfo} />
                      ) : null}
                    </div>
                  </div>
                </Slide>
              </Fade>
            </Modal>
          </div>


          <div className={formClass.imgCardDiv}>
            <div className={formClass.imgDiv}>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {fileDetail ? (
                  <p className={formClass.imgInputText}>
                    File {values.fileName} Selected
                  </p>
                ) : (
                  <p className={formClass.imgInputText}>
                    click to select files
                  </p>
                )}
              </div>

            </div>
            <div className={formClass.imgDiv}>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Color Name </Typography>
                <TextCustom
                  id="Color Name"
                  placeholder="Enter Color Name"
                  name="colorName"
                  value={inputvalues.colorName}
                  onChange={onChange}
                />
              </div>

              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Color Code </Typography>
                <TextCustom
                  id="Color Code"
                  placeholder="Enter Color Code"
                  name="colorCode"
                  value={inputvalues.colorCode}
                  onChange={onChange}
                />
              </div>
            </div>

            {outcome && Object.keys(outcome).length !== 0 && (
              <StatusText title={outcome} fColor={Colors.black} />
            )}
            {errors && Object.keys(errors).length !== 0 && (
              <StatusText
                title={"Something Went Wrong Cannot Be Created"}
                fColor={Colors.black}
              />
            )}

            {upLoading ? (
              <StatusText title={"Uploading..."} fColor={Colors.black} />
            ) : null}

            <UploadButton
              title={upLoaded ? "Uploaded" : "Upload"}
              disabled={fileDetail ? false : true}
              onClick={onSubmit}
            />
          </div>

          <div className={classes.bodyDiv}>
            {loading ? (
              <StatusText title={"Loading "} fColor={Colors.black} />
            ) : (
              <>
                {data && data?.getVehicleColorImages?.length ? (
                  <div className={classes.proImgBlock}>
                    {data?.getVehicleColorImages.map((obj, index) => {
                      return (
                        <div className={classes.proImageDiv} key={index}>
                          <img
                            src={obj.image_link}
                            alt={`${NoPhoto} is not available`}
                            className={classes.proImg}
                          />
                          <div className={classes.proImageTextOnlyDiv}>
                            <Typography className={formClass.leftText}>Color: {obj.color_name} </Typography>
                          </div>
                          <div className={classes.proImageTextOnlyDiv}>
                            <Typography className={formClass.leftText}>Hexcode: {obj.color_code}</Typography>
                          </div>
                          <div className={classes.proImageTextDiv}>


                            <CommonFilledButton
                              title={obj.image_status}
                              startIcon={
                                <MdUpdate
                                  style={{ marginBottom: -3, marginLeft: 6 }}
                                />
                              }
                              onClick={() =>
                                callEdit(
                                  obj
                                )
                              }
                            />
                            <CommonFilledButton
                              title={'Del'}
                              startIcon={
                                <MdDelete
                                  style={{ marginBottom: -3, marginLeft: 6 }}
                                />
                              }
                              onClick={() =>
                                deleteFunc(
                                  obj.id,
                                  obj.image_link,
                                )
                              }
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <StatusText title={"No Data Exist"} fColor={Colors.black} />
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
    </div>
  );
}

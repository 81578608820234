import React from "react";
import { Switch, useLocation } from "react-router-dom";

import "../Styles/DashboardStyle.css";

import { landingStyle } from "../Styles/landingStyle";

import { ProtectedRoute } from "../ProtectedRoute";

import Header from "../Component/Header";
import SideNav from "../Component/SideNav";

import VehicleDetails from "./VehicleDetails";

import MaintenanceDetails from "./Maintenance";
import VariantsDetails from "./Variants/index";
import ViewImages from "./Images/index";
import ViewColorImages from "./ImagesColor";

import ViewVarImages from "./VarImages/index";
import ViewVarColorImages from "./VarImagesColor";

import ComputeCharge from "./ComputeCharge";
import ComputeCost from "./ComputeCost";
import ComputeEv from "./ComputeEv";

import UserInfo from "./UserInfo/index";
import ChargingDetails from "./ChargingDetails";
import EmissionConstant from "./EmissionConstant";
import ManufactuerDetails from "./Manufacturer";

import DutiesAndOtherDetails from "./DutiesAndOther";

import DutiesAndExceptions from "./DutiesAndExceptions";

import PetrolVaraint from "./PetrolVariants";
import MaintenanceGeneral from "./MaintenanceGeneral";

import ScoreCard from "./ScoreTable";

import Assumptions from "./Assumptions";

export default function LandingPage() {
  const classes = landingStyle();

  const { pathname } = useLocation();

  return (
    <div className="dashboard">
      <div className="dashboard-body">
        <div className={classes.sideNav}>
          <SideNav />
        </div>
        <div className={classes.rightDiv}>
          <Header />
          <div
            className={classes.rightBody}
            style={{
              padding:
                pathname === "/" ||
                pathname === "/home" ||
                pathname === "/dash-enquiry"
                  ? 0
                  : 22,
            }}
          >
            <Switch>
              <ProtectedRoute
                exact
                path="/variantsdata"
                component={VariantsDetails}
              />

              <ProtectedRoute
                exact
                path="/chargecomputationinfo"
                component={ComputeCharge}
              />

              <ProtectedRoute
                exact
                path="/costcomputationinfo"
                component={ComputeCost}
              />

              <ProtectedRoute
                exact
                path="/findmyevinfo"
                component={ComputeEv}
              />

              <ProtectedRoute
                exact
                path="/vehicledata"
                component={VehicleDetails}
              />

              <ProtectedRoute exact path="/userinfo" component={UserInfo} />

              <ProtectedRoute
                exact
                path="/maintenanceinfo"
                component={MaintenanceDetails}
              />

              <ProtectedRoute
                exact
                path="/chargingdetails"
                component={ChargingDetails}
              />

              <ProtectedRoute
                exact
                path="/emissionconstant"
                component={EmissionConstant}
              />
              <ProtectedRoute exact path="/viewimages" component={ViewImages} />
              <ProtectedRoute
                exact
                path="/viewcolorvariant"
                component={ViewColorImages}
              />

              <ProtectedRoute
                exact
                path="/viewvarimages"
                component={ViewVarImages}
              />
              <ProtectedRoute
                exact
                path="/viewvarcolorvariant"
                component={ViewVarColorImages}
              />

              <ProtectedRoute
                exact
                path="/manuf"
                component={ManufactuerDetails}
              />

              <ProtectedRoute
                exact
                path="/dutiesandother"
                component={DutiesAndOtherDetails}
              />

              <ProtectedRoute
                exact
                path="/dutiesandexceptions"
                component={DutiesAndExceptions}
              />

              <ProtectedRoute
                exact
                path="/petrolVariant"
                component={PetrolVaraint}
              />

              <ProtectedRoute
                exact
                path="/maintenance-general"
                component={MaintenanceGeneral}
              />

              <ProtectedRoute exact path="/score-card" component={ScoreCard} />

              <ProtectedRoute exact path="/assumptions" component={Assumptions} />
              
              {/* <<<========    END   =========>>> */}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

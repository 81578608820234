import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { FETCH_EMISSION_CONSTANT, CREATE_EMISSION_CONSTANT } from './api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddEmission(props) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState("")
  const [errors, setErrors] = useState("")

  const initialState = {
    state: '',
    emissionValue: '',
    userEmail: email,
    token :token
  }

  const [values, setValues] = useState(initialState)

  /* <<<=========     useMutation   ===========>>>  */

  const [createEmissionConst] = useMutation(CREATE_EMISSION_CONSTANT, {
    update(_, result) {
      setOutcome(result?.data?.inputEmissionDetails?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_EMISSION_CONSTANT,
        variables: { userEmail: email, token },
      },
    ],
  })

  function onSubmit() {
    setOutcome({})
    setErrors({})

    createEmissionConst({ variables: values })
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({})
    setErrors({})
    setValues(initialState)
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> State</Typography>{' '}
          <TextInputField
            id="State"
            placeholder="State"
            name="state"
            value={values.state}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Emission Value
          </Typography>{' '}
          <TextInputField
            id="Emission Value"
            placeholder="Emission Value"
            name="emissionValue"
            value={values.emissionValue}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={'Something Went Wrong Cannot Be Created'}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

import React, { useState, useEffect, useContext } from 'react'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { Divider, Typography } from '@material-ui/core'

import { EmailContext } from '../../Context/EmailContext'

import { formStyle, modalStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import {
  UpdateButton,
  ModalCopyButton,
  CommonAddButton,
  CommonEditButton,
} from '../../Component/ButtonComponent'
import { TextCustom } from '../../Component/InputComponents'

import {
  FETCH_PERFORMANCE_DETAILS,
  CREATE_PERFORMANCE_INFO,
  UPDATE_PERFORMANCE_INFO,
} from './api'
import { FETCH_ALL_VARIANTS_DATA } from '../Variants/api';

export default function PerformanceInfo({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const lid = localStorage.getItem('vidis')
  const [updateStatus, setUpdateStatus] = useState(false)

  const formClass = formStyle()
  const mStyles = modalStyle()

  const [outcome, setOutcome] = useState('')
  const [outcome2, setOutcome2] = useState('')
  const [errors, setErrors] = useState('')

  const [edit, setEdit] = useState(false)

  const [values, setValues] = useState({
    vid: updateValues ? updateValues?.vid : '',
    userEmail: email,
    token : token,
    acceleration0to100: '',
    efficiency: '',
    costPerKm: '',
    torqueNm: '',
    torqueLb: '',
    topSpeedKmHr: '',
    topSpeedMpHr: '',
    power: '',
    powerPs: '',
    driveType: '',
    engine: '',
    eRangeNum: '',
  })

  const [callThePerformanceApi, { loading, data, error }] = useLazyQuery(
    FETCH_PERFORMANCE_DETAILS,
  )

  useEffect(() => {
    if (updateValues) {
      const idIs = updateValues?.vid
      callThePerformanceApi({ variables: { qId: idIs } })
    }
  }, [updateValues]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data?.getPerformance) {
      const { getPerformance } = data
      setValues({
        ...values,
        acceleration0to100: getPerformance?.acceleration_0_100,
        efficiency: getPerformance?.efficiency,
        costPerKm: getPerformance?.cost_per_km,
        torqueNm: getPerformance?.torque_nm,
        torqueLb: getPerformance?.torque_lb_ft,
        topSpeedKmHr: getPerformance?.top_speed_kmhr,
        topSpeedMpHr: getPerformance?.top_speed_mphr,
        power: getPerformance?.power,
        powerPs: getPerformance?.power_ps,
        driveType: getPerformance?.drive_type,
        engine: getPerformance?.engine,
        eRangeNum: getPerformance?.e_range_num,
      })

      if (lid === getPerformance?.vid) {
        setUpdateStatus(false)
      }
      if (lid !== getPerformance?.vid) {
        setUpdateStatus(true)
      }
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  /* <<<=========  useMutation   ===========>>>  */

  const [updatePerformanceInfo] = useMutation(UPDATE_PERFORMANCE_INFO, {
    update(_, result) {
      setOutcome2(result?.data?.updatePerformanceOfVehicle)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_PERFORMANCE_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  })


  const [createPerformance] = useMutation(CREATE_PERFORMANCE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputPerformanceForVehicle?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_ALL_VARIANTS_DATA,
        variables: { qId: lid },
      },
      {
        query: FETCH_PERFORMANCE_DETAILS,
        variables: { qId: updateValues?.vid },
      },
    ],
  })

  async function validator() {
    const parsedValuesAre = {

      vid: updateValues ? updateValues?.vid : '',
      userEmail: email,
      token : token,
      acceleration0to100: isNaN(parseFloat(values.acceleration0to100)) ? 0 : parseFloat(values.acceleration0to100),
      efficiency: isNaN(parseFloat(values.efficiency)) ? 0 : parseFloat(values.efficiency),
      costPerKm: isNaN(parseFloat(values.costPerKm)) ? 0 : parseFloat(values.costPerKm),
      torqueNm: isNaN(parseFloat(values.torqueNm)) ? 0 : parseFloat(values.torqueNm),
      torqueLb: isNaN(parseFloat(values.torqueLb)) ? 0 : parseFloat(values.torqueLb),
      topSpeedKmHr: isNaN(parseFloat(values.topSpeedKmHr)) ? 0 : parseFloat(values.topSpeedKmHr),
      topSpeedMpHr: isNaN(parseFloat(values.topSpeedMpHr)) ? 0 : parseFloat(values.topSpeedMpHr),
      power: isNaN(parseFloat(values.power)) ? 0 : parseFloat(values.power),
      powerPs: isNaN(parseFloat(values.powerPs)) ? 0 : parseFloat(values.powerPs),
      eRangeNum: isNaN(parseFloat(values.eRangeNum)) ? 0 : parseFloat(values.eRangeNum),
      driveType: values.driveType,
      engine: values.engine,

    }

    return parsedValuesAre;

  }

  /* <<<=========      API CALL   ===========>>>  */
  async function callCreateAPI() {
    setOutcome('')
    setErrors('')
    const parsedValuesAre = await validator()
    await createPerformance({
      variables: parsedValuesAre,
    })
  }

  async function callEditAPI() {
    setOutcome2('')
    setErrors('')
    // updateData();

    const validatedValues = await validator()

    await updatePerformanceInfo({
      variables: validatedValues,
    })
  }
  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    if (event.target.name === 'torqueNm') {
      const torqLb = parseFloat(event.target.value) * 0.738

      setValues({ ...values, [event.target.name]: event.target.value, ['torqueLb']: torqLb }) // eslint-disable-line no-useless-computed-key

    }
    else if (event.target.name === 'torqueLb') {
      const torqNm = parseFloat(event.target.value) / 0.738

      setValues({ ...values, [event.target.name]: event.target.value, ['torqueNm']: torqNm }) // eslint-disable-line no-useless-computed-key
    }
    else if (event.target.name === 'topSpeedKmHr') {
      const speedMph = parseFloat(event.target.value) * 0.621371;

      setValues({ ...values, [event.target.name]: event.target.value, ['topSpeedMpHr']: speedMph }) // eslint-disable-line no-useless-computed-key

    }
    else if (event.target.name === 'topSpeedMpHr') {

      const speedKm = parseFloat(event.target.value) / 0.621371;

      setValues({ ...values, [event.target.name]: event.target.value, ['topSpeedKmHr']: speedKm }) // eslint-disable-line no-useless-computed-key

    }
    else if (event.target.name === 'power') {

      const pps = parseFloat(event.target.value) * 1.3596216173;

      setValues({ ...values, [event.target.name]: event.target.value, ['powerPs']: pps }) // eslint-disable-line no-useless-computed-key

    }
    else if (event.target.name === 'powerPs') {

      const pwr = parseFloat(event.target.value) / 1.3596216173;

      setValues({ ...values, [event.target.name]: event.target.value, ['power']: pwr }) // eslint-disable-line no-useless-computed-key

    }
    else {
      setValues({ ...values, [event.target.name]: event.target.value })
    }


  }

  function callTheMainApi() {
    callThePerformanceApi({ variables: { qId: lid } })
  }

  /* <<<=========    CONTENT   ===========>>>  */
  if (error) {
    return (
      <div className={formClass.formDiv}>
        <StatusText title={'Something Went wrong!'} fColor={Colors.error} />
      </div>
    )
  }
  return (
    <div className={formClass.formDiv}>
      {(loading)?(<>loading ...</>):(<>
      {updateValues ? (
        <>
          {edit ? (
            <div>
              <div className={mStyles.floatRight}>

                <ModalCopyButton
                  onClick={() => callTheMainApi()}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Acceleration_0_100
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Acceleration_0_100"
                  name="acceleration0to100"
                  value={values.acceleration0to100}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Efficiency
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Efficiency"
                  name="efficiency"
                  value={values.efficiency}
                  onChange={onChange}
                />
              </div>
             
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Cost Per Km</Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Cost Per Km"
                  name="costPerKm"
                  value={values.costPerKm}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Torque Nm
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Torque"
                  name="torqueNm"
                  value={values.torqueNm}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Torque Lb/Ft
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Torque"
                  name="torqueLb"
                  value={values.torqueLb}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Top Speed - Km/hr
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Top"
                  name="topSpeedKmHr"
                  value={values.topSpeedKmHr}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>
                  Top Speed - Mp/hr
                </Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Top"
                  name="topSpeedMpHr"
                  value={values.topSpeedMpHr}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Power Kw</Typography>
                <TextCustom
                  id="en"
                  placeholder="Enter Power"
                  name="power"
                  type="number"
                  value={values.power}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Power Ps</Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter Power"
                  name="powerPs"
                  value={values.powerPs}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Drive</Typography>
                <TextCustom
                  id="en"
                  type="text"
                  placeholder="Enter Drive"
                  name="driveType"
                  value={values.driveType}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Engine</Typography>
                <TextCustom
                  id="en"
                  type="text"
                  placeholder="Enter Engine"
                  name="engine"
                  value={values.engine}
                  onChange={onChange}
                />
              </div>
              <div className={formClass.formRow}>
                <Typography className={formClass.leftText}>Range</Typography>
                <TextCustom
                  id="en"
                  type="number"
                  placeholder="Enter E-Range Num"
                  name="eRangeNum"
                  value={values.eRangeNum}
                  onChange={onChange}
                />
              </div>
             

              {outcome && Object.keys(outcome).length !== 0 && (
                <StatusText title={outcome} fColor={Colors.black} />
              )}
              {outcome2 && (
                <StatusText title={'Updated Successfully'} fColor={Colors.black} />
              )}
              {errors && Object.keys(errors).length !== 0 && (
                <StatusText title={'Something Went Wrong Cannot Be Created'} fColor={Colors.black} />
              )}

              <UpdateButton title={'cancel'} onClick={() => setEdit(false)} />
              {(lid === values.vid) ? (<>
                It Can Be Edited Only On The Main Table</>) : (
                <>
                  {updateStatus ? (
                    <CommonEditButton
                      title={'Update'}
                      onClick={() => callEditAPI()}
                    />
                  ) : (
                    <CommonAddButton
                      title={'Create'}
                      onClick={() => callCreateAPI()}
                    />
                  )}
                </>

              )}
            </div>
          ) : (
            <div>
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Vid</p>
                <p className={mStyles.textRight}>{values.vid}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Acceleration_0_100</p>
                <p className={mStyles.textRight}>{values.acceleration0to100}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Efficiency</p>
                <p className={mStyles.textRight}>{values.efficiency}</p>
              </div>
             
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Cost Per Km</p>
                <p className={mStyles.textRight}>{values.costPerKm}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Torque Nm</p>
                <p className={mStyles.textRight}>{values.torqueNm}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Torque Lb ft</p>
                <p className={mStyles.textRight}>{values.torqueLb}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Top Speed KmHr</p>
                <p className={mStyles.textRight}>{values.topSpeedKmHr}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Top Speed MpHr</p>
                <p className={mStyles.textRight}>{values.topSpeedMpHr}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Power KW</p>
                <p className={mStyles.textRight}>{values.power}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Power Ps</p>
                <p className={mStyles.textRight}>{values.powerPs}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Drive Type</p>
                <p className={mStyles.textRight}>{values.driveType}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Engine</p>
                <p className={mStyles.textRight}>{values.engine}</p>
              </div>
              <Divider className={mStyles.dividerLine} />
              <div className={mStyles.rowView}>
                {' '}
                <p className={mStyles.textLeft}>Range</p>
                <p className={mStyles.textRight}>{values.eRangeNum}</p>
              </div>
             
              <Divider className={mStyles.dividerLine} />

              <UpdateButton title={'Edit'} onClick={() => setEdit(true)} />
            </div>
          )}
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}
      </>)}
    </div>
  )
}

import gql from 'graphql-tag'

export const FETCH_RANGE_CONSUMPTION = gql`
  query getRangeConsumption($qId: String!) {
    getRangeConsumption(qId: $qId) {
      vid
      range_evdb
      range_nedc
      range_wltp_tel
      real_world_combined
      real_world_city
      real_world_highway
    }
  }
`

export const CREATE_RANGE_INFO = gql`
  mutation inputRangeConsumption(
    $userEmail: String!
    $vid: String!
    $token : String!
    $rangeEVDB: Float
    $rangeNEDC: Float
    $rangeWLTPTEL: Float
    $realWorldCity: Float
    $realWorldCombined: Float
    $realWorldHighway: Float
  ) {
    inputRangeConsumption(
      userEmail: $userEmail
      vid: $vid
      token : $token
      rangeEVDB: $rangeEVDB
      rangeNEDC: $rangeNEDC
      rangeWLTPTEL: $rangeWLTPTEL
      realWorldCity: $realWorldCity
      realWorldCombined: $realWorldCombined
      realWorldHighway: $realWorldHighway
    ) {
      status
      statusBool
    }
  }
`

export const UPDATE_RANGE_INFO = gql`
  mutation updateRangeConsumption(
    $userEmail: String!
    $vid: String!
    $token : String!
    $rangeEVDB: Float
    $rangeNEDC: Float
    $rangeWLTPTEL: Float
    $realWorldCity: Float
    $realWorldCombined: Float
    $realWorldHighway: Float
  ) {
    updateRangeConsumption(
      userEmail: $userEmail
      token : $token
      vid: $vid
      rangeEVDB: $rangeEVDB
      rangeNEDC: $rangeNEDC
      rangeWLTPTEL: $rangeWLTPTEL
      realWorldCity: $realWorldCity
      realWorldCombined: $realWorldCombined
      realWorldHighway: $realWorldHighway
    )
  }
`

import gql from "graphql-tag";

export const FETCH_ASSUMPTION_DATA = gql`
  query getAssumptionConstantData($userEmail: String!, $token: String!) {
    getAssumptionConstantData(userEmail: $userEmail, token : $token) {
      id
      petrol_price
      ev_maintenance
      petrol_maintenance
      ev_insurance
      petrol_insurance
      tyres_ev
      tyres_petrol
      depreciation_period_ev
      depreciation_period_petrol
      scrap_value_ev
      scrap_value_petrol
      emissions_ev
      emissions_petrol
      dep_year
    }
  }
`;

export const UPDATE_ASSUMPTION_CONSTANT = gql`
  mutation updateAssumptionConstant(
    $userEmail: String!
    $token : String!
    $petrolPrice: Float
    $evMaintenance: Float
    $petrolMaintenance: Float
    $evInsurance: Float
    $petrolInsurance: Float
    $evTyres: Float
    $petrolTyres: Float
    $depPeriodEv: Float
    $depPeriodPetrol: Float
    $scrapValueEv: Float
    $scrapValuePetrol: Float
    $emissionsEv: Float
    $emissionsPetrol: Float
    $depYear: Float
    $qId: Float!
  ) {
    updateAssumptionConstant(
      userEmail : $userEmail
      token : $token
      petrolPrice : $petrolPrice
      evMaintenance : $evMaintenance
      petrolMaintenance : $petrolMaintenance
      evInsurance : $evInsurance
      petrolInsurance : $petrolInsurance
      evTyres : $evTyres
      petrolTyres : $petrolTyres
      depPeriodEv : $depPeriodEv
      depPeriodPetrol : $depPeriodPetrol
      scrapValueEv : $scrapValueEv
      scrapValuePetrol : $scrapValuePetrol
      emissionsEv : $emissionsEv
      emissionsPetrol : $emissionsPetrol
      depYear : $depYear
      qId : $qId
    )
  }
`;
import gql from "graphql-tag";

export const FETCH_ALL_COLOR_IMAGES = gql`
  query getVehicleColorImages($qId: String!) {
    getVehicleColorImages(qId: $qId) {
      id
      vid
      image_link
      color_name
      color_code
    }
  }
`;


export const GET_SIGNED_URL_COLOR = gql`
 mutation signedUrlForColorImage(
  $fileType: String!
  $fileName: String!
  $modelVariant: String!
  $modelName: String!
  $userEmail: String!

) {
  signedUrlForColorImage(
    fileType: $fileType
    fileName: $fileName
    modelVariant: $modelVariant
    modelName: $modelName
    userEmail: $userEmail
  ) {
    signedRequest
    url
    error
  }
}
`;


export const INSERT_URL_COLOR_IMAGE = gql`
  mutation inputImageColorForVehicle(
    $userEmail: String!
    $vid: String!
    $imageLink: String
    $colorName: String
    $colorCode : String
 
 ) {
  inputImageColorForVehicle(
    userEmail: $userEmail
    vid: $vid
    imageLink: $imageLink
    colorName: $colorName
    colorCode: $colorCode
   ) {
    status
    statusBool
    error
    
   }
 }
 `;


 export const UPDATE_IMAGE_COLOR = gql`
  mutation updateImageColorName(
    $colorName: String
    $imageLink: String
    $vid: String
    $userEmail: String!
    $qId: Float!
    $colorCode: String
 
 ) {
  updateImageColorName(
    colorCode: $colorCode
    colorName : $colorName
    imageLink : $imageLink
    vid : $vid
    userEmail : $userEmail
    qId : $qId
   ) 
 }
 `;



 export const DELETE_IMG_COLOR = gql`
  mutation deleteCopyColorImages(
    $userEmail: String!
    $qId: Float!
 
 ) {
  deleteCopyColorImages(
    userEmail: $userEmail
    qId: $qId

   )
 }
 `;



export const COPY_IMAGES_FROM_MAIN = gql`
mutation copyColorImages(
  $userEmail: String!
  $insertId: String!
  $qId: String!

) {
  copyColorImages(
  userEmail: $userEmail
  insertId: $insertId
  qId: $qId
 )
}`;
import gql from 'graphql-tag'

export const FETCH_VEHICLE_RATINGS = gql`
  query getVehicleRatings($qId: String!) {
    getVehicleRatings(qId: $qId) {
        vid
        ancap_safety_rating
        adult_occupant
        child_occupant
        rating_year
        vulnerable_road_users
        safety_assist
        battery_rating
        efficiency_rating
        range_rating
        performance_rating
        technology_rating
        cost_per_km
    }
  }
`

export const CREATE_RATING_INFO = gql`
  mutation inputRatingsForVehicle(
    $userEmail: String!
    $vid: String!
    $token : String!
    $ancapSafetyRating: Float
    $adultOccupant: Float
    $childOccupant: Float
    $ratingYear: String
    $vulnerableRoadUsers: Float
    $safetyAssist: Float
    $batteryRating: Float
    $efficiencyRating: Float
    $rangeRating: Float
    $performanceRating: Float
    $technologyRating: Float
    $costPerKm: Float

  ) {
    inputRatingsForVehicle(
        userEmail : $userEmail
        vid : $vid
        token : $token
        ancapSafetyRating : $ancapSafetyRating
        adultOccupant : $adultOccupant
        childOccupant : $childOccupant
        ratingYear : $ratingYear
        vulnerableRoadUsers : $vulnerableRoadUsers
        safetyAssist : $safetyAssist
        batteryRating : $batteryRating
        efficiencyRating : $efficiencyRating
        rangeRating : $rangeRating
        performanceRating : $performanceRating
        technologyRating : $technologyRating
        costPerKm : $costPerKm
    
    ) {
      status
      statusBool
    }
  }
`

export const UPDATE_RANGE_INFO = gql`
  mutation updateRatingsForVehicle(
    $userEmail: String!
    $vid: String!
    $token : String!
    $ancapSafetyRating: Float
    $adultOccupant: Float
    $childOccupant: Float
    $ratingYear: String
    $vulnerableRoadUsers: Float
    $safetyAssist: Float
    $batteryRating: Float
    $efficiencyRating: Float
    $rangeRating: Float
    $performanceRating: Float
    $technologyRating: Float
    $costPerKm: Float

  ) {
    updateRatingsForVehicle(
        userEmail : $userEmail
        vid : $vid
        token : $token
        ancapSafetyRating : $ancapSafetyRating
        adultOccupant : $adultOccupant
        childOccupant : $childOccupant
        ratingYear : $ratingYear
        vulnerableRoadUsers : $vulnerableRoadUsers
        safetyAssist : $safetyAssist
        batteryRating : $batteryRating
        efficiencyRating : $efficiencyRating
        rangeRating : $rangeRating
        performanceRating : $performanceRating
        technologyRating : $technologyRating
        costPerKm : $costPerKm
    )
  }
`

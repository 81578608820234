import {  makeStyles } from "@material-ui/core/styles";
import { Colors } from "./Colors/Colors";
import { Fonts } from "./Colors/Font";

export const headStyle = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },

  menuButton: {
    borderRadius: 10,
    boxSizing: "border-box",
  },
  navBtn: {
    color: Colors.lightBlack,
    textAlign: "center",
    fontFamily: Fonts.Lato,
    fontSize: 24,
  },
}));

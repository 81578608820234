import React, { useContext, useState } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { CreateButton, ResetButton } from '../../Component/ButtonComponent'
import { TextInputField } from '../../Component/InputComponents'

import { FETCH_DUTIES_AND_OTHER, CREATE_DUTIES_OTHER } from './api'

import { EmailContext } from '../../Context/EmailContext'

export default function AddDuty() {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState("")
  const [errors, setErrors] = useState("")

  const initialState = {
    state: '',
    emissionValue: '',
    stampDutyPayablePetrol: '',
    stampDutyPayableEv: '',
    carRegistration: '',
    incentiveDutyDeduction: '',
    incentiveRebate: '',
    incentiveOther: '',
    userEmail: email,
    token : token
  }

  const [values, setValues] = useState(initialState)

  /* <<<=========     useMutation   ===========>>>  */

  const [createEmissionConst] = useMutation(CREATE_DUTIES_OTHER, {
    update(_, result) {
      setOutcome(result?.data?.inputDutiesAndOther?.status)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_DUTIES_AND_OTHER,
        variables: { userEmail: email, token:token },
      },
    ],
  })

  function onSubmit() {
    setOutcome({})
    setErrors({})

    const newValues = {
      userEmail : email,
      token: token,
      state : values.state,
      emissionValue : isNaN(parseFloat(values.emissionValue)) ? 0 : parseFloat(values.emissionValue),
      stampDutyPayablePetrol : isNaN(parseFloat(values.stampDutyPayablePetrol)) ? 0 : parseFloat(values.stampDutyPayablePetrol),
      stampDutyPayableEv : isNaN(parseFloat(values.stampDutyPayableEv)) ? 0 : parseFloat(values.stampDutyPayableEv),
      carRegistration : isNaN(parseFloat(values.carRegistration)) ? 0 : parseFloat(values.carRegistration),
      incentiveDutyDeduction : isNaN(parseFloat(values.incentiveDutyDeduction)) ? 0 : parseFloat(values.incentiveDutyDeduction),
      incentiveRebate : isNaN(parseFloat(values.incentiveRebate)) ? 0 : parseFloat(values.incentiveRebate),
      incentiveOther : isNaN(parseFloat(values.incentiveOther)) ? 0 : parseFloat(values.incentiveOther)
    }

    createEmissionConst({ variables: newValues })
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    if(event.target.name === 'state'){
      setValues({ ...values, [event.target.name]: event.target.value })
    }
    else{
      setValues({ ...values, [event.target.name]: Number(event.target.value) })
    }
   
  }

  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome({})
    setErrors({})
    setValues(initialState)
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      <div>
        
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}> State</Typography>{' '}
          <TextInputField
            id="State"
            placeholder="State"
            name="state"
            value={values.state}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Emission Value
          </Typography>{' '}
          <TextInputField
            id="Emission Value"
            placeholder="Emission Value"
            name="emissionValue"
            value={values.emissionValue}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Stamp Duty Payable Petrol
          </Typography>{' '}
          <TextInputField
            id="stampDutyPayablePetrol"
            placeholder="Stamp Duty Payable Petrol"
            name="stampDutyPayablePetrol"
            value={values.stampDutyPayablePetrol}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Stamp Duty Payable Ev
          </Typography>{' '}
          <TextInputField
            id="Stamp Duty Payable Ev"
            placeholder="Stamp Duty Payable Ev"
            name="stampDutyPayableEv"
            value={values.stampDutyPayableEv }
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Car Registration
          </Typography>{' '}
          <TextInputField
            id="Car Registration"
            placeholder="Car Registration"
            name="carRegistration"
            value={values.carRegistration}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Duty Detection
          </Typography>{' '}
          <TextInputField
            id="Incentive Duty Detection"
            placeholder="Incentive Duty Detection"
            name="incentiveDutyDeduction"
            value={values.incentiveDutyDeduction}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Rebate
          </Typography>{' '}
          <TextInputField
            id="Incentive Rebate"
            placeholder="Incentive Rebate"
            name="incentiveRebate"
            value={values.incentiveRebate}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {' '}
          <Typography className={formClass.leftText}>
            {' '}
            Incentive Other
          </Typography>{' '}
          <TextInputField
            id="Incentive Other"
            placeholder="Incentive Other"
            name="incentiveOther"
            value={values.incentiveOther}
            onChange={onChange}
          />
        </div>
       

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={'Something Went Wrong Cannot Be Created'}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  )
}

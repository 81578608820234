import gql from "graphql-tag";

export const ADD_DEMO_LINK = gql`
  mutation addDemoLink(
    $userEmail: String!
    $token : String!
    $qId: String!
    $demoLink: String!
    
    ) {
      addDemoLink(
        userEmail: $userEmail
        token : $token
        qId: $qId
        demoLink: $demoLink
    )
  }
`;
import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { UpdateButton } from "../../Component/ButtonComponent";
import { TextInputField } from "../../Component/InputComponents";

import { EmailContext } from "../../Context/EmailContext";

import { UPDATE_PETROL_VARIANT, FETCH_PETROL_VARIANTS } from "./api";

export default function EditPetrolVariant({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState(false);
  const [errors, setErrors] = useState(false);

  // console.log(updateValues)

  const [values, setValues] = useState({
    manuf: updateValues ? updateValues.manuf : "",
    carName: updateValues ? updateValues.car_name : "",
    price: updateValues ? updateValues.price : "",
    lperkm: updateValues ? updateValues.lperkm : "",
    tank: updateValues ? updateValues.tank : "",
    range: updateValues ? updateValues.range : "",
    acceleration: updateValues ? updateValues.acceleration : "",
    fcNedc: updateValues ? updateValues.fc_nedc : "",
    fcWltp: updateValues ? updateValues.fc_wltp : "",
    rangeNedc: updateValues ? updateValues.range_nedc : "",
    rangeWltp: updateValues ? updateValues.range_wltp : "",
    qId: updateValues ? updateValues.pid : "",
    userEmail: email,
    token : token,
  });

  const [updateDutiesAndExceptions] = useMutation(UPDATE_PETROL_VARIANT, {
    update(_, result) {
      setOutcome(result?.data?.updatePetrolVariantDetails);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_PETROL_VARIANTS,
        variables: { userEmail: email, token : token },
      },
    ],
  });

  function onSubmit() {
    setOutcome(false);
    setErrors(false);
    const vTu = {
      userEmail: email,
      token : token,
      qId : values?.qId
    }

    if(values.manuf !== ''){
      vTu['manuf'] = values?.manuf
    }
    if(values.carName){
      vTu['carName'] = values?.carName
    }
    if(values.price){
      vTu['price'] = values?.price
    }
    if(values.lperkm){
      vTu['lperkm'] = values?.lperkm
    }
    if(values.tank){
      vTu['tank'] = values?.tank
    }
    if(values.range){
      vTu['range'] = values?.range
    }
    if(values.acceleration){
      vTu['acceleration'] = values?.acceleration
    }
    if(values.fcNedc){
      vTu['fcNedc'] = values?.fcNedc 
    }
    if(values.fcWltp){
      vTu['fcWltp'] = values?.fcWltp 
    }
    if(values.rangeNedc){
      vTu['rangeNedc'] = values?.rangeNedc
    }
    if(values.rangeWltp){
      vTu['rangeWltp'] = values?.rangeWltp 
    }
    updateDutiesAndExceptions({
      variables: vTu,
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    if (event.target.name === "carName" || event.target.name === "manuf") {
      setValues({ ...values, [event.target.name]: event.target.value });
    } else if (event.target.name === "fcNedc") {
      const fcWltpValue = Number(Number(event.target.value) * 1.2).toFixed(1);
      setValues({
        ...values,
        [event.target.name]: Number(event.target.value),
        fcWltp: Number(fcWltpValue),
      });
    } else {
      setValues({ ...values, [event.target.name]: Number(event.target.value) });
    }
  };

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Manufacturer
              </Typography>{" "}
              <TextInputField
                id="Manufacturer"
                placeholder="Manufacturer Name"
                name="manuf"
                value={values.manuf}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Car Name
              </Typography>{" "}
              <TextInputField
                id="CarName"
                placeholder="Car Name"
                name="carName"
                value={values.carName}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Price
              </Typography>{" "}
              <TextInputField
                type="number"
                id="price"
                placeholder="price"
                name="price"
                value={values.price}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                L/100 Km
              </Typography>{" "}
              <TextInputField
                type="number"
                id="Lperkm"
                placeholder="L/100Km"
                name="lperkm"
                value={values.lperkm}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> Tank</Typography>{" "}
              <TextInputField
                type="number"
                id="tank"
                placeholder="tank"
                name="tank"
                value={values.tank}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Range
              </Typography>{" "}
              <TextInputField
                type="number"
                id="range"
                placeholder="range"
                name="range"
                value={values.range}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Acceleration
              </Typography>{" "}
              <TextInputField
                type="number"
                id="acceleration"
                placeholder="acceleration"
                name="acceleration"
                value={values.acceleration}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> NEDC</Typography>{" "}
              <TextInputField
                type="number"
                id="nedc"
                placeholder="Nedc"
                name="fcNedc"
                value={values.fcNedc}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}> WLTP</Typography>{" "}
              <TextInputField
                type="number"
                id="fcWltp"
                placeholder="Wltp"
                name="fcWltp"
                value={values.fcWltp}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Range Nedc
              </Typography>{" "}
              <TextInputField
                type="number"
                id="rangeNedc"
                placeholder="Range Nedc"
                name="rangeNedc"
                value={values.rangeNedc}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                {" "}
                Range WLTP
              </Typography>{" "}
              <TextInputField
                type="number"
                id="rangeWltp"
                placeholder="Range Wltp"
                name="rangeWltp"
                value={values.rangeWltp}
                onChange={onChange}
              />
            </div>

            {outcome && outcome === true && (
              <StatusText
                title={"Updated Successfully"}
                fColor={Colors.black}
              />
            )}

            {errors && errors === true && (
              <StatusText
                title={"Something Went Wrong"}
                fColor={Colors.black}
              />
            )}

            <UpdateButton title={"Update"} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={"Something Went Wrong Could Not be Updated"}
          fColor={Colors.black}
        />
      )}
    </div>
  );
}

import React, { useContext, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Typography } from "@material-ui/core";

import { formStyle } from "../../Styles/CommonStyles";
import { Colors } from "../../Styles/Colors/Colors";

import { StatusText } from "../../Component/Text";

import { CreateButton, ResetButton } from "../../Component/ButtonComponent";
import { TextCustom } from "../../Component/InputComponents";

import { FETCH_MAINTENANCE_DETAILS, CREATE_MAINTENANCE_INFO } from "./api";
import { FETCH_LOGO } from "../Manufacturer/api";

import { EmailContext } from "../../Context/EmailContext";

import { MenuItem, Select } from "@material-ui/core";

export default function AddMaintenance({ updateValues }) {
  const { email, token } = useContext(EmailContext);

  const formClass = formStyle();

  const [outcome, setOutcome] = useState("");
  const [errors, setErrors] = useState("");

  const initialState = {
    petrolDelivery: "",
    petrolService: "",
    evDelivery: "",
    evService: "",
    userEmail: email,
    token : token,
  };

  const [values, setValues] = useState(initialState);

  const [selectedOption, setSelectedOption] = useState("");

  const { loading, data, error } = useQuery(FETCH_LOGO);

  // console.log(data);

  /* <<<=========     useMutation   ===========>>>  */

  const [createMaintenanceInfo] = useMutation(CREATE_MAINTENANCE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.inputNewMaintenanceGeneral?.status);
    },
    onError(err) {
      setErrors(err);
    },
    refetchQueries: [
      {
        query: FETCH_MAINTENANCE_DETAILS,
        variables: { userEmail: email, token : token },
      },
    ],
  });

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  async function validator() {
    const parsedValuesAre = {
      userEmail: email,
      token : token,
      petrolDelivery: isNaN(parseFloat(values.petrolDelivery))
        ? 0
        : parseFloat(values.petrolDelivery),
      petrolService: isNaN(parseFloat(values.petrolService))
        ? 0
        : parseFloat(values.petrolService),
      evDelivery: isNaN(parseFloat(values.evDelivery))
        ? 0
        : parseFloat(values.evDelivery),
      evService: isNaN(parseFloat(values.evService))
        ? 0
        : parseFloat(values.evService),
      manufacturer: selectedOption,
    };
    return parsedValuesAre;
  }

  async function onSubmit() {
    setOutcome({});
    setErrors({});
    const parsedValuesAre = await validator();
    console.log(parsedValuesAre);
    createMaintenanceInfo({ variables: parsedValuesAre });
  }

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  /* <<<=========      RESET   ===========>>>  */

  function resetFunc() {
    setOutcome("");
    setErrors("");
    setValues(initialState);
  }

  /* <<<=========    CONTENT   ===========>>>  */

  if (error) {
    return <div>Something Went Wrong</div>;
  }

  if(loading){
      return <>Loading ..</>
  }

  return (
    <div className={formClass.formDiv}>
      <div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>Manufacturer</Typography>
          {data?.getAllManufacturer && (
            <Select
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value)}
            >
              {data?.getAllManufacturer.map((o) => (
                <MenuItem key={o.manuf_name} value={o.manuf_name}>
                  {o.manuf_name}
                </MenuItem>
              ))}
            </Select>
          )}
        </div>

        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            Petrol Delivery
          </Typography>
          <TextCustom
            id="en"
            type="number"
            placeholder="Enter Petrol Delviery"
            name="petrolDelivery"
            value={values.petrolDelivery}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>Petrol Service</Typography>
          <TextCustom
            id="en"
            type="number"
            placeholder="Enter Petrol Service"
            name="petrolService"
            value={values.petrolService}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>
            Ev Delivery Fee
          </Typography>
          <TextCustom
            id="en"
            type="number"
            placeholder="Ev Delivery Fee"
            name="evDelivery"
            value={values.evDelivery}
            onChange={onChange}
          />
        </div>
        <div className={formClass.formRow}>
          {" "}
          <Typography className={formClass.leftText}>Ev Service Fee</Typography>
          <TextCustom
            id="en"
            type="number"
            placeholder="Enter Service Fee"
            name="evService"
            value={values.evService}
            onChange={onChange}
          />
        </div>

        {outcome && Object.keys(outcome).length !== 0 && (
          <StatusText title={outcome} fColor={Colors.black} />
        )}
        {errors && Object.keys(errors).length !== 0 && (
          <StatusText
            title={"Something Went Wrong Cannot Be Created"}
            fColor={Colors.black}
          />
        )}
        <div className={formClass.formBtnDiv}>
          <ResetButton onClick={resetFunc} />
          <CreateButton onClick={onSubmit} />
        </div>
      </div>
    </div>
  );
}

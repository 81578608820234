import React, { useState, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { LoggedInContext } from "../../Context/LoggedInContext";
// import { SchemaContext } from "../../Context/SchemaContext";

// import logo from "../Styles/Assets/logo.jpg";
import { loginStyle } from "../../Styles/loginStyle";

import { LOGIN_USER } from "./api";

import { EmailContext } from "../../Context/EmailContext";

const Login = (props) => {
  const classes = loginStyle();

  const { setEmail, setName, setToks } = useContext(EmailContext);

  const [outcome, setOutcome] = useState("");
  const [errors, setErrors] = useState("");

  // const { setStaffId } = useContext(LoggedInContext);
  // const { schema, setName, setSRole, setUserData } = useContext(SchemaContext);

  const [values, setValues] = React.useState({
    UserEmail: "",
    PassCode: "",
    showPassword: false,
  });

  const [loginUser] = useMutation(LOGIN_USER, {
    update(_, result) {
      console.log(result);
      if (result.data.tryLogin.emailError) {
        setOutcome(result.data.tryLogin.emailError);
        setErrors(result.data.tryLogin.emailError);
      }
      if (result.data.tryLogin.passError) {
        setOutcome(result.data.tryLogin.passError);
        setErrors(result.data.tryLogin.passError);
      }
      if (result.data.tryLogin.genError) {
        setOutcome(result.data.tryLogin.genError);
        setErrors(result.data.tryLogin.genError);
      }
      if (result.data.tryLogin.error) {
        setOutcome(result.data.tryLogin.error);
        setErrors(result.data.tryLogin.error);
      }
      if (result.data.tryLogin.token) {

        setEmail(result.data.tryLogin.email);
        setToks(result.data.tryLogin.token);
        setName(result.data.tryLogin.name);
        localStorage.setItem("email", result.data.tryLogin.email);
        localStorage.setItem("myTok", result.data.tryLogin.token);
        localStorage.setItem("name", result.data.tryLogin.name);
        props.history.push("/vehicledata");
      }
    },
    onError(err) {
      setErrors(err);
      setOutcome("");
    },
    variables: values,
  });

  const handleChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function loginUserCallback(e) {
    e.preventDefault();
    loginUser();
  }

  return (
    <div className={classes.page}>
      <Card className={classes.loginDiv}>
        <div className={classes.headDiv}>
          {/* <img src={logo} className={classes.img} /> */}
          <Typography className={classes.headText} color="primary" variant="h4">
            zecar
          </Typography>
        </div>
        <CardContent className={classes.contentDiv}>
          {outcome ? (
            <Typography
              className={classes.errText}
              color="primary"
              variant="h4"
            >
              {outcome}
            </Typography>
          ) : (
            <div></div>
          )}

          {errors ? (
            <Typography
              className={classes.errText}
              color="primary"
              variant="h4"
            >
              Something went wrong!
            </Typography>
          ) : (
            <div></div>
          )}

          <TextField
            name="UserEmail"
            value={values.UserEmail}
            onChange={(text) => handleChange(text)}
            id="outlined-basic"
            label="UserEmail"
            variant="outlined"
            className={classes.input}
          />

          <FormControl variant="outlined" className={classes.input}>
            <InputLabel htmlFor="outlined-adornment-password">
              Password
            </InputLabel>
            <OutlinedInput
              name="PassCode"
              id="outlined-adornment-password"
              type={values.showPassword ? "text" : "password"}
              value={values.PassCode}
              onChange={(text) => handleChange(text)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={70}
            />
          </FormControl>
          <br />

          <Button
            className={classes.btn}
            onClick={loginUserCallback}
            variant="contained"
            color="primary"
          >
            Submit
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Login;

import React, { useState, useContext } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Typography } from '@material-ui/core'

import { formStyle } from '../../Styles/CommonStyles'
import { Colors } from '../../Styles/Colors/Colors'

import { StatusText } from '../../Component/Text'

import { UpdateButton } from '../../Component/ButtonComponent'
import { TextCustom } from '../../Component/InputComponents'

import { EmailContext } from '../../Context/EmailContext'

import { UPDATE_MAINTENANCE_INFO, FETCH_MAINTENANCE_DETAILS } from './api'


export default function EditMaintenance({ updateValues }) {
  const { email, token } = useContext(EmailContext)

  const formClass = formStyle()

  const [outcome, setOutcome] = useState(false)
  const [errors, setErrors] = useState(false)

  const [values, setValues] = useState({
    id: updateValues ? updateValues.id : '',
    vid: updateValues ? updateValues.vid : '',
    brand: updateValues ? updateValues.brand : '',
    model: updateValues ? updateValues.model : '',
    variant: updateValues ? updateValues.variant : '',
    regoAndInsuranceMonthly: updateValues ? updateValues.rego_and_insurance_monthy : '',
    fuelMonthly: updateValues ? updateValues.fuel : '',
    servicingMonthly: updateValues ? updateValues.servicing_monthly : '',
    tyresMonthly: updateValues ? updateValues.tyres_monthly : '',
    forKm: updateValues ? updateValues.for_km : '',
  })

  const [updateUserDetails] = useMutation(UPDATE_MAINTENANCE_INFO, {
    update(_, result) {
      setOutcome(result?.data?.updateMaintenanceDetails)
    },
    onError(err) {
      setErrors(err)
    },
    refetchQueries: [
      {
        query: FETCH_MAINTENANCE_DETAILS,
        variables: { qId: values.vid },
      },
    ],
  })


  async function validator() {
    const parsedValuesAre = {

      vid: values.vid,
      userEmail: email,
      token : token,
      regoAndInsuranceMonthly: isNaN(parseFloat(values.regoAndInsuranceMonthly)) ? 0 : parseFloat(values.regoAndInsuranceMonthly),
      fuelMonthly: isNaN(parseFloat(values.fuelMonthly)) ? 0 : parseFloat(values.fuelMonthly),
      servicingMonthly: isNaN(parseFloat(values.servicingMonthly)) ? 0 : parseFloat(values.servicingMonthly),
      tyresMonthly: isNaN(parseFloat(values.tyresMonthly)) ? 0 : parseFloat(values.tyresMonthly),
      forKm: isNaN(parseFloat(values.forKm)) ? 0 : parseFloat(values.forKm),
      brand: values.brand,
      model: values.model,
      variant: values.variant,
      qId: values.id

    }

    return parsedValuesAre;

  }


  async function onSubmit() {
    setOutcome(false)
    setErrors(false)
    const parsedValuesAre = await validator()
    updateUserDetails({
      variables: parsedValuesAre
    });
  }

  /* <<<=========      ONCHANGE FUNC   ===========>>>  */

  const onChange = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value })
  }

  /* <<<=========    CONTENT   ===========>>>  */

  return (
    <div className={formClass.formDiv}>
      {updateValues ? (
        <>
          <div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>Vid</Typography>
              <Typography className={formClass.leftText}>{values.vid}</Typography>

            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>Brand</Typography>
              <Typography className={formClass.leftText}>{values.brand}</Typography>
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>Model</Typography>
              <Typography className={formClass.leftText}>{values.model}</Typography>

            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>Variant</Typography>
              <Typography className={formClass.leftText}>{values.variant}</Typography>

            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Rego and Insurance Monthy
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Rego and Insurance Monthy"
                name="regoAndInsuranceMonthly"
                value={values.regoAndInsuranceMonthly}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>fuelMonthly</Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter fuel Monthly"
                name="fuelMonthly"
                value={values.fuelMonthly}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Servicing Monthly
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Servicing Monthly"
                name="servicingMonthly"
                value={values.servicingMonthly}
                onChange={onChange}
              />
            </div>
            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                Tyres Monthly
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter Tyres Monthly"
                name="tyresMonthly"
                value={values.tyresMonthly}
                onChange={onChange}
              />
            </div>

            <div className={formClass.formRow}>
              {" "}
              <Typography className={formClass.leftText}>
                For Kilometers
              </Typography>
              <TextCustom
                id="en"
                type="number"
                placeholder="Enter km details"
                name="forKm"
                value={values.forKm}
                onChange={onChange}
              />
            </div>

            {outcome && outcome === true && (
              <StatusText title={'Updated Successfully'} fColor={Colors.black} />
            )}

            {errors && errors === true && (
              <StatusText title={'Something Went Wrong'} fColor={Colors.black} />
            )}

            <UpdateButton title={'Update'} onClick={() => onSubmit()} />
          </div>
        </>
      ) : (
        <StatusText
          title={'Something Went Wrong Could Not be Updated'}
          fColor={Colors.black}
        />
      )}


    </div>
  )
}

import gql from "graphql-tag";

export const FETCH_DUTIES_AND_OTHER = gql`
  query getDutiesAndOtherData($userEmail: String!, $token: String!) {
    getDutiesAndOtherData(userEmail: $userEmail, token: $token) {
      state
      emission_value
      stamp_duty_payable_ev
      stamp_duty_payable_petrol
      car_registration
      incentive_duty_deduction
      incentive_other
      incentive_rebate
      id
    }
  }
`;

export const DELETE_DUTIES_AND_OTHER = gql`
  mutation deleteDutiesAndOther(
    $qId: Float!
    $token: String!
    $userEmail: String!
  ) {
    deleteDutiesAndOther(qId: $qId, token: $token, userEmail: $userEmail)
  }
`;

export const CREATE_DUTIES_OTHER = gql`
  mutation inputDutiesAndOther(
    $userEmail: String!
    $token: String!
    $state: String!
    $emissionValue: Float
    $stampDutyPayablePetrol: Float
    $stampDutyPayableEv: Float
    $carRegistration: Float
    $incentiveDutyDeduction: Float
    $incentiveRebate: Float
    $incentiveOther: Float
  ) {
    inputDutiesAndOther(
      userEmail: $userEmail
      token: $token
      state: $state
      emissionValue: $emissionValue
      stampDutyPayablePetrol: $stampDutyPayablePetrol
      stampDutyPayableEv: $stampDutyPayableEv
      carRegistration: $carRegistration
      incentiveDutyDeduction: $incentiveDutyDeduction
      incentiveRebate: $incentiveRebate
      incentiveOther: $incentiveOther
    ) {
      status
      statusBool
      error
    }
  }
`;

export const UPDATE_DUTIES = gql`
  mutation updateDutiesAndOther(
    $userEmail: String!
    $token: String!
    $state: String!
    $emissionValue: Float
    $stampDutyPayablePetrol: Float
    $stampDutyPayableEv: Float
    $carRegistration: Float
    $incentiveDutyDeduction: Float
    $incentiveRebate: Float
    $incentiveOther: Float
    $qId: Float!
  ) {
    updateDutiesAndOther(
      userEmail: $userEmail
      token: $token
      state: $state
      emissionValue: $emissionValue
      stampDutyPayablePetrol: $stampDutyPayablePetrol
      stampDutyPayableEv: $stampDutyPayableEv
      carRegistration: $carRegistration
      incentiveDutyDeduction: $incentiveDutyDeduction
      incentiveRebate: $incentiveRebate
      incentiveOther: $incentiveOther
      qId: $qId
    )
  }
`;
